import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MainContext from '../../../context/MainContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import '../../../style/auth/register.css'

function Register() {
    const { referralcode, plan } = useParams();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);
    const [registrationStep, setRegistrationStep] = useState(1);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [surnameError, setSurnameError] = useState(false);
    const [genderError, setGenderError] = useState(false);
    const [birthdayError, setBirthdayError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');

    return (
        <section className='register-wrapper'>
            <div className='register-description-wrapper'>
                <div className='register-description-main-title'>
                    <a href={`/${language}/`}><h1>Match the Look</h1></a>
                </div>
            </div>
            <div className='register-form-wrapper'>
                <div className='register-form-container'>
                    {registrationStep < 5 ? (
                        <div className='register-form-title'>
                            <h2>{translations.register_title}</h2>
                            <h3>{translations.register_signUp}</h3>
                        </div>
                    ) : (
                        <div className='register-form-title-final'>
                            <h2>{translations.register_congratsAccountActive}</h2>
                            <h3>{translations.register_youCanSignInNow}</h3>
                        </div>
                    )}


                    {registrationStep === 1 && (
                        <Step1
                            name={name}
                            setName={setName}
                            surname={surname}
                            setSurname={setSurname}
                            gender={gender}
                            setGender={setGender}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            country={country}
                            setCountry={setCountry}
                            nameError={nameError}
                            setNameError={setNameError}
                            surnameError={surnameError}
                            setSurnameError={setSurnameError}
                            genderError={genderError}
                            setGenderError={setGenderError}
                            birthdayError={birthdayError}
                            setBirthdayError={setBirthdayError}
                            countryError={countryError}
                            setCountryError={setCountryError}
                            referralcode={referralcode}
                            setRegistrationStep={setRegistrationStep}
                            language={language}
                            loading={loading}
                            MainContext={MainContext}
                            translations={translations}
                        />
                    )}

                    {registrationStep === 2 && (
                        <Step2
                            name={name}
                            surname={surname}
                            gender={gender}
                            birthday={birthday}
                            country={country}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            repeatPassword={repeatPassword}
                            setRepeatPassword={setRepeatPassword}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            passwordError={passwordError}
                            setPasswordError={setPasswordError}
                            repeatPasswordError={repeatPasswordError}
                            setRepeatPasswordError={setRepeatPasswordError}
                            loading={loading}
                            setLoading={setLoading}
                            setRegistrationStep={setRegistrationStep}
                            language={language}
                            MainContext={MainContext}
                            translations={translations}
                        />
                    )}

                    {registrationStep === 3 && (
                        <Step3
                            email={email}
                            plan={plan}
                            language={language}
                            loading={loading}
                            setLoading={setLoading}
                            MainContext={MainContext}
                            setRegistrationStep={setRegistrationStep}
                            translations={translations}
                        />
                    )}

                    {registrationStep === 4 && (
                        <Step4
                            email={email}
                            plan={plan}
                            language={language}
                            loading={loading}
                            setLoading={setLoading}
                            MainContext={MainContext}
                            setRegistrationStep={setRegistrationStep}
                            translations={translations}
                        />
                    )}

                    {registrationStep === 5 && (
                        <div className='register-form-options-components-button-final'>
                            <div className='register-form-options-components-button'>
                                <button
                                    disabled={loading}
                                    onClick={() => {
                                        if (!loading) {
                                            window.location.href = `/${language}/login`;
                                        }
                                    }}
                                >
                                    {loading ? (
                                        <span className="loading-dots">
                                            {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                                        </span>
                                    ) : (
                                        translations.register_signIn
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    {(registrationStep === 1 || registrationStep === 2) && (
                        <div className='register-form-register-cta'>
                            <p>{translations.register_alreadyHaveAccount} <a href={`/${language}/login`}>{translations.register_signInNow}</a></p>
                        </div>
                    )}
                </div>
            </div>
        </section >
    );
}

export default Register;