import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import MainContext from '../../../context/MainContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/MenuTop.css';

function MENUTOP() {
  const location = useLocation();
  const { language, toggleLanguage } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredName, localStoredSurname, localStoredUsername, localStoredProfilePicture } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;
  const menuRef = useRef(null);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Toggle no-scroll class based on menu state
    if (mobileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Event listener to detect clicks outside the menu
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false); // Close menu if clicked outside
      }
    };

    // Add event listener if menu is open
    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up on component unmount or menu close
    return () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuOpen]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="landingPage-menutop-wrapper">
      <div className='landingPage-menutop-logo' translate="no">
        <a href={`/${language}/`}><h1>Match the Look</h1></a>
      </div>
      <div className='landingPage-menutop-options'>
        <Link to="/#home" className="menu-link">{translations.landingPage_menu_homePage}</Link>
        <ScrollLink to="how-it-works" smooth={true} duration={500}>
          <Link to="/#how-it-works" className="menu-link">{translations.landingPage_menu_howItWorks}</Link>
        </ScrollLink>
        <ScrollLink to="ai" smooth={true} duration={500}>
          <Link to="/#ai" className="menu-link">{translations.landingPage_menu_artificalInteligence}</Link>
        </ScrollLink>
        <ScrollLink to="stylish-program" smooth={true} duration={500}>
          <Link to="/#stylish-program" className="menu-link">{translations.landingPage_menu_stylishProgram}</Link>
        </ScrollLink>
        <ScrollLink to="pricing" smooth={true} duration={500}>
          <Link to="/#pricing" className="menu-link">{translations.landingPage_menu_pricing}</Link>
        </ScrollLink>
        <ScrollLink to="contacts" smooth={true} duration={500}>
          <Link to="/#contacts" className="menu-link">{translations.landingPage_menu_contactUs}</Link>
        </ScrollLink>
      </div>
      <div className='landingPage-menutop-cta'>
        {language === 'en' ? (
          <a onClick={() => toggleLanguage('pt')}><img src='/language/flag-pt.png' alt='Portuguese Flag' /></a>
        ) : (
          <a onClick={() => toggleLanguage('en')}><img src='/language/flag-us.png' alt='English Flag' /></a>
        )}
        {!isAuthenticated ? (
          <>
            <a href={`/${language}/login`}>{translations.landingPage_menu_login}</a>
            <a href={`/${language}/public/mural`}><button>{translations.landingPage_menu_tryItNowItsFree}</button></a>
          </>
        ) : (
          <>
            <a href={`/${language}/account/${localStoredUsername}/mural`}>
              <button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                  <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                {translations.landingPage_menu_openDashboard}
              </button>
            </a>
          </>
        )}
      </div>


      <div className='landingPage-mobile-menu'>
        {language === 'en' ? (
          <a onClick={() => toggleLanguage('pt')}><img src='/language/flag-pt.png' alt='Portuguese Flag' /></a>
        ) : (
          <a onClick={() => toggleLanguage('en')}><img src='/language/flag-us.png' alt='English Flag' /></a>
        )}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => setMobileMenuOpen(true)}>
          <path d="M4 5L20 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 12L20 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 19L20 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      {mobileMenuOpen && (
        <div className='landingPage-mobile-menu-background'>
          <div className='landingPage-mobile-menu-wrapper' ref={menuRef}>

            <div className='landingPage-mobile-menu-close'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => setMobileMenuOpen(false)}>
                <path d="M15 9L9 14.9996M15 15L9 9.00039" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
              </svg>
            </div>

            {!isAuthenticated ? (
              <div className='landingPage-mobile-menu-cta'>
                <a href={`/${language}/public/mural`}><button>{translations.landingPage_menu_tryItNowItsFree}</button></a>
                <a href={`/${language}/login`}>{translations.landingPage_menu_login}</a>
              </div>
            ) : (
              <div className='landingPage-mobile-menu-profile'>
                {localStoredProfilePicture ? (
                  <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt={`User ${localStoredUsername} profile ${localStoredProfilePicture}`} />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                    <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                )}
                <span>{localStoredName} {localStoredSurname}</span>
                <div className='landingPage-mobile-menu-box'>
                  <a href={`/${language}/account/${localStoredUsername}/mural`}>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M2 6C2 4.11438 2 3.17157 2.58579 2.58579C3.17157 2 4.11438 2 6 2C7.88562 2 8.82843 2 9.41421 2.58579C10 3.17157 10 4.11438 10 6V8C10 9.88562 10 10.8284 9.41421 11.4142C8.82843 12 7.88562 12 6 12C4.11438 12 3.17157 12 2.58579 11.4142C2 10.8284 2 9.88562 2 8V6Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M2 19C2 18.0681 2 17.6022 2.15224 17.2346C2.35523 16.7446 2.74458 16.3552 3.23463 16.1522C3.60218 16 4.06812 16 5 16H7C7.93188 16 8.39782 16 8.76537 16.1522C9.25542 16.3552 9.64477 16.7446 9.84776 17.2346C10 17.6022 10 18.0681 10 19C10 19.9319 10 20.3978 9.84776 20.7654C9.64477 21.2554 9.25542 21.6448 8.76537 21.8478C8.39782 22 7.93188 22 7 22H5C4.06812 22 3.60218 22 3.23463 21.8478C2.74458 21.6448 2.35523 21.2554 2.15224 20.7654C2 20.3978 2 19.9319 2 19Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M14 16C14 14.1144 14 13.1716 14.5858 12.5858C15.1716 12 16.1144 12 18 12C19.8856 12 20.8284 12 21.4142 12.5858C22 13.1716 22 14.1144 22 16V18C22 19.8856 22 20.8284 21.4142 21.4142C20.8284 22 19.8856 22 18 22C16.1144 22 15.1716 22 14.5858 21.4142C14 20.8284 14 19.8856 14 18V16Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M14 5C14 4.06812 14 3.60218 14.1522 3.23463C14.3552 2.74458 14.7446 2.35523 15.2346 2.15224C15.6022 2 16.0681 2 17 2H19C19.9319 2 20.3978 2 20.7654 2.15224C21.2554 2.35523 21.6448 2.74458 21.8478 3.23463C22 3.60218 22 4.06812 22 5C22 5.93188 22 6.39782 21.8478 6.76537C21.6448 7.25542 21.2554 7.64477 20.7654 7.84776C20.3978 8 19.9319 8 19 8H17C16.0681 8 15.6022 8 15.2346 7.84776C14.7446 7.64477 14.3552 7.25542 14.1522 6.76537C14 6.39782 14 5.93188 14 5Z" stroke="currentColor" stroke-width="1.5" />
                      </svg>
                      {translations.landingPage_menu_openDashboard}
                    </button>
                  </a>
                  <a href={`/${language}/account/${localStoredUsername}/ai/chat`}>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19 16V14C19 11.1716 19 9.75736 18.1213 8.87868C17.2426 8 15.8284 8 13 8H11C8.17157 8 6.75736 8 5.87868 8.87868C5 9.75736 5 11.1716 5 14V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M19 18C20.4142 18 21.1213 18 21.5607 17.5607C22 17.1213 22 16.4142 22 15C22 13.5858 22 12.8787 21.5607 12.4393C21.1213 12 20.4142 12 19 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M5 18C3.58579 18 2.87868 18 2.43934 17.5607C2 17.1213 2 16.4142 2 15C2 13.5858 2 12.8787 2.43934 12.4393C2.87868 12 3.58579 12 5 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M13.5 3.5C13.5 4.32843 12.8284 5 12 5C11.1716 5 10.5 4.32843 10.5 3.5C10.5 2.67157 11.1716 2 12 2C12.8284 2 13.5 2.67157 13.5 3.5Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M12 5V8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10 17.5C10 17.5 10.6667 18 12 18C13.3333 18 14 17.5 14 17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                      {translations.landingPage_menu_artificalInteligence}
                    </button>
                  </a>
                </div>
                <div className='landingPage-mobile-menu-box'>
                <a href={`/${language}/account/${localStoredUsername}/wardrobe`}>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                        <path d="M2.38086 2.46562C4.21503 0.490411 18.0114 5.32901 18 7.09558C17.9871 9.09884 12.6121 9.71513 11.1223 10.1331C10.2265 10.3844 9.98651 10.6421 9.77993 11.5815C8.84436 15.8362 8.37464 17.9524 7.3041 17.9997C5.59766 18.0751 0.590865 4.39327 2.38086 2.46562Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M16.5673 13.0425C16.9912 12.9858 17.4213 12.9858 17.8453 13.0425M20.1107 13.9793C20.4482 14.237 20.7628 14.5516 21.0205 14.8891M21.9576 17.1559C22.0141 17.5791 22.0141 18.0083 21.9576 18.4315M14.4367 14.1863C12.5005 16.0205 12.5727 18.8841 14.3437 20.6546C16.1343 22.4449 19.0474 22.5032 20.875 20.4993" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      {translations.landingPage_menu_stylishProgram}
                    </button>
                  </a>
                  <a href={`/${language}/account/${localStoredUsername}`}>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                        <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="currentColor" stroke-width="1.5" />
                      </svg>
                      {translations.landingPage_menu_myProfile}
                    </button>
                  </a>
                </div>
              </div>
            )}

            <div className='landingPage-mobile-menutop-options'>
              <Link to="/" className="menu-link">{translations.landingPage_menu_homePage}</Link>
              <ScrollLink to="how-it-works" smooth={true} duration={500}>
                <Link to="/#how-it-works" className="menu-link">{translations.landingPage_menu_howItWorks}</Link>
              </ScrollLink>
              <ScrollLink to="ai" smooth={true} duration={500}>
                <Link to="/#ai" className="menu-link">{translations.landingPage_menu_artificalInteligence}</Link>
              </ScrollLink>
              <ScrollLink to="stylish-program" smooth={true} duration={500}>
                <Link to="/#stylish-program" className="menu-link">{translations.landingPage_menu_stylishProgram}</Link>
              </ScrollLink>
              <ScrollLink to="pricing" smooth={true} duration={500}>
                <Link to="/#pricing" className="menu-link">{translations.landingPage_menu_pricing}</Link>
              </ScrollLink>
              <ScrollLink to="contacts" smooth={true} duration={500}>
                <Link to="/#contacts" className="menu-link">{translations.landingPage_menu_contactUs}</Link>
              </ScrollLink>
            </div>

          </div>
        </div>
      )}
    </div>
  );
}

export default MENUTOP;
