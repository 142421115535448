import { useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_CTA.css';

function SECTIONCTA() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <div className="landingPage-cta-wrapper">

      <h3>{translations.landingPage_cta_subtitle}</h3>
      <h2>{translations.landingPage_cta_title}</h2>
      {!isAuthenticated ? (
        <a href={`/${language}/public/mural`}><button>{translations.landingPage_cta_button}</button></a>
      ) : (
        <a href={`/${language}/account/${localStoredUsername}/mural`}><button>{translations.landingPage_cta_button}</button></a>
      )}


    </div>
  );
}

export default SECTIONCTA;
