import React, { useState, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/mural_filters.css'

function Mural_Filters({ gender, style, changeFilters, setFiltersOpen }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [targetGender, setTargetGender] = useState(gender);
    const [targetStyle, setTargetStyle] = useState(style);

    function filterOutfits() {
        changeFilters(targetGender, targetStyle);
    }

    return (
        <section className='muralfilters-wrapper'>
            <div className='muralfilters-wrapper-background'>
                <div className='muralfilters-wrapper-background-container'>
                    <div className='muralfilters-title'>
                        <div className='muralfilters-title-title'>
                            <h2>{translations.public_mural_filters_title}</h2>
                        </div>
                        <div className='muralfilters-title-close'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => setFiltersOpen(false)}>
                                <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                            </svg>
                        </div>
                    </div>
                    <div className='muralfilters-options'>
                        <span>{translations.public_mural_filters_gender}</span>
                        <select
                            value={targetGender}
                            onChange={(e) => setTargetGender(e.target.value)}>
                            <option value='' disabled selected>{translations.public_mural_filters_gender_placeholder}</option>
                            <option value='all'>{translations.public_mural_filters_gender_all}</option>
                            <option value='man'>{translations.public_mural_filters_gender_male}</option>
                            <option value='woman'>{translations.public_mural_filters_gender_female}</option>
                        </select>
                    </div>
                    <div className='muralfilters-options'>
                        <span>{translations.public_mural_filters_style}</span>
                        <select
                            value={targetStyle}
                            onChange={(e) => setTargetStyle(e.target.value)}>
                            <option value='' disabled selected>{translations.public_mural_filters_style_placeholder}</option>
                            <option value='all'>{translations.public_mural_filters_style_all}</option>
                            <option value='casual'>{translations.public_mural_filters_style_casual}</option>
                            <option value='formal'>{translations.public_mural_filters_style_formal}</option>
                            <option value='sporty'>{translations.public_mural_filters_style_sporty}</option>
                            <option value='elegant'>{translations.public_mural_filters_style_elegant}</option>
                            <option value='vintage'>{translations.public_mural_filters_style_vintage}</option>
                        </select>
                    </div>
                    <div className='muralfilters-options-save'>
                            <button onClick={filterOutfits}>{translations.public_mural_filters_filter}</button>
                        </div>
                </div>
            </div>
        </section>
    );
}

export default Mural_Filters;
