import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import MainContext from '../../../context/MainContext';
import GalleryLoader from '../../public/GalleryLoader';
import PREFERENCESFORM_USERPREFERENCES from './PreferencesForm_UserPreferences';
import PREFERENCESFORM_STYLE from './PreferencesForm_UserStyle';
import PREFERENCESFORM_INSPIRATION from './PreferencesForm_UserInspiration';
import PREFERENCESFORM_OBJECTIVES from './PreferencesForm_UserObjectives';
import PREFERENCESFORM_BUDGET from './PreferencesForm_UserBudget';
import '../../../style/private/wardrobe/PreferencesForm.css';

function WardrobePreferencesForm({ setPageOpen, wardrobe, language, translations }) {
    const { getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const [loadingSaving, setLoadingSave] = useState(false);
    const [lastUpdate, setLastUpdate] = useState('');
    
    // eslint-disable-next-line
    const [loadingData, setLoadingData] = useState(false);

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOptionsOpen, setMenuOptionsOpen] = useState('');

    const [preferencesCompleted, setPreferencesCompleted] = useState(false);
    const [preferencesSaveJSON, setPreferencesSaveJSON] = useState(wardrobe ? wardrobe.userPreferences : []);

    const [styleOcasionsCompleted, setStyleOcasionsCompleted] = useState(false);
    const [styleSaveJSON, setStyleSaveJSON] = useState(wardrobe ? wardrobe.stylePreferences : []);

    const [inspirationsCompleted, setInspirationsCompleted] = useState(false);
    const [inspirationSaveJSON, setInspirationSaveJSON] = useState(wardrobe ? wardrobe.inspirationPreferences : []);

    const [objectivesCompleted, setObjectivesCompleted] = useState(false);
    const [objectivesSaveJSON, setObjectivesSaveJSON] = useState(wardrobe ? wardrobe.objectivesPreferences : []);

    const [budgetCompleted, setBudgetCompleted] = useState(false);
    const [budgetSaveJSON, setBudgetSaveJSON] = useState(wardrobe ? wardrobe.budgetPreferences : []);

    const [descriptionMessage, setDescriptionMessage] = useState(wardrobe ? wardrobe.additionalComments : '');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [menuOpen]);
    /* eslint-disable react-hooks/exhaustive-deps */

    function prepareToOpenOrCloseMenuOptions(option) {
        setMenuOptionsOpen(option);
        setMenuOpen(true);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        if (wardrobe) {

            const preferencesHasValues = Object.values(wardrobe.userPreferences).some(
                (arr) => Array.isArray(arr) && arr.length > 0
            );

            if (preferencesHasValues) {
                setPreferencesCompleted(true);
            }

            const styleHasValues = Object.values(wardrobe.stylePreferences).some(
                (arr) => Array.isArray(arr) && arr.length > 0
            );

            if (styleHasValues) {
                setStyleOcasionsCompleted(true);
            }

            const inspirationHasValues = Object.values(wardrobe.inspirationPreferences).some(
                (arr) => Array.isArray(arr) && arr.length > 0
            );

            if (inspirationHasValues) {
                setInspirationsCompleted(true);
            }

            const objectivesHasValues = Object.values(wardrobe.objectivesPreferences).some(
                (arr) => Array.isArray(arr) && arr.length > 0
            );

            if (objectivesHasValues) {
                setObjectivesCompleted(true);
            }

            const budgetHasValues = Object.values(wardrobe.budgetPreferences).some(
                (arr) => Array.isArray(arr) && arr.length > 0
            );

            if (budgetHasValues) {
                setBudgetCompleted(true);
            }

        }
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const onSubmit = () => {
        const preferences = {
            user: preferencesSaveJSON,
            style: styleSaveJSON,
            inspiration: inspirationSaveJSON,
            objectives: objectivesSaveJSON,
            budget: budgetSaveJSON,
        };

        setLoadingSave(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/save/wardrobe`;

        const formData = new URLSearchParams({
            preferences: JSON.stringify(preferences),
            comments: descriptionMessage
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoadingSave(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLastUpdate(data.time);

                const wardrobePreferences = {
                    userPreferences: preferencesSaveJSON,
                    stylePreferences: styleSaveJSON,
                    inspirationPreferences: inspirationSaveJSON,
                    objectivesPreferences: objectivesSaveJSON,
                    budgetPreferences: budgetSaveJSON,
                    additionalComments: descriptionMessage,
                    updated: data.time
                }

                localStorage.setItem('wardrobe', JSON.stringify(wardrobePreferences));
                window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`;

            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    };

    return (
        <section className='wardrobe-preferences-content'>
            {loadingData ? (
                <GalleryLoader />
            ) : (
                <>
                    <div className='wardrobe-preferences-main'>
                        <div className='wardrobe-return'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => setPageOpen('wardrobe')}>
                                <path d="M3.99982 11.9998L19.9998 11.9998" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.99963 17C8.99963 17 3.99968 13.3176 3.99966 12C3.99965 10.6824 8.99966 7 8.99966 7" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <h1>{translations.wardrobe_preferences_title}</h1>
                        <p>{translations.wardrobe_preferences_description1}</p>
                        <p>{translations.wardrobe_preferences_description2}</p>
                    </div>

                    <div className='wardrobe-preferences-container'>
                        <div className={preferencesCompleted ? ('wardrobe-preferences-container-square-done') : ('wardrobe-preferences-container-square')} onClick={() => prepareToOpenOrCloseMenuOptions('preferences')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M13.1977 8H10.8023C7.35836 8 5.03641 11.5806 6.39304 14.7994C6.58202 15.2477 7.0156 15.5385 7.49535 15.5385H8.33892C8.62326 15.5385 8.87111 15.7352 8.94007 16.0157L10.0261 20.4328C10.2525 21.3539 11.0663 22 12 22C12.9337 22 13.7475 21.3539 13.9739 20.4328L15.0599 16.0157C15.1289 15.7352 15.3767 15.5385 15.6611 15.5385H16.5047C16.9844 15.5385 17.418 15.2477 17.607 14.7994C18.9636 11.5806 16.6416 8 13.1977 8Z" stroke="currentColor" stroke-width="1.5" />
                                <circle cx="12" cy="5" r="3" stroke="currentColor" stroke-width="1.5" />
                            </svg>
                            <h2>{translations.wardrobe_preferences_squarePreferences}</h2>
                        </div>

                        <div className={styleOcasionsCompleted ? ('wardrobe-preferences-container-square-done') : ('wardrobe-preferences-container-square')} onClick={() => prepareToOpenOrCloseMenuOptions('style')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                <path d="M9 9L13.0001 12.9996M16 8L11 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h2>{translations.wardrobe_preferences_squareStyle}</h2>
                        </div>

                        <div className={inspirationsCompleted ? ('wardrobe-preferences-container-square-done') : ('wardrobe-preferences-container-square')} onClick={() => prepareToOpenOrCloseMenuOptions('inspiration')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                <path d="M17.0235 3.03358L16.0689 2.77924C13.369 2.05986 12.019 1.70018 10.9555 2.31074C9.89196 2.9213 9.53023 4.26367 8.80678 6.94841L7.78366 10.7452C7.0602 13.4299 6.69848 14.7723 7.3125 15.8298C7.92652 16.8874 9.27651 17.247 11.9765 17.9664L12.9311 18.2208C15.631 18.9401 16.981 19.2998 18.0445 18.6893C19.108 18.0787 19.4698 16.7363 20.1932 14.0516L21.2163 10.2548C21.9398 7.57005 22.3015 6.22768 21.6875 5.17016C21.0735 4.11264 19.7235 3.75295 17.0235 3.03358Z" stroke="currentColor" stroke-width="1.5" />
                                <path d="M16.8538 7.43306C16.8538 8.24714 16.1901 8.90709 15.3714 8.90709C14.5527 8.90709 13.889 8.24714 13.889 7.43306C13.889 6.61898 14.5527 5.95904 15.3714 5.95904C16.1901 5.95904 16.8538 6.61898 16.8538 7.43306Z" stroke="currentColor" stroke-width="1.5" />
                                <path d="M12 20.9463L11.0477 21.2056C8.35403 21.9391 7.00722 22.3059 5.94619 21.6833C4.88517 21.0608 4.52429 19.6921 3.80253 16.9547L2.78182 13.0834C2.06006 10.346 1.69918 8.97731 2.31177 7.89904C2.84167 6.96631 4 7.00027 5.5 7.00015" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            <h2>{translations.wardrobe_preferences_inspiration}</h2>
                        </div>

                        <div className={objectivesCompleted ? ('wardrobe-preferences-container-square-done') : ('wardrobe-preferences-container-square')} onClick={() => prepareToOpenOrCloseMenuOptions('objectives')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M11 6L21 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M11 12L21 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M11 18L21 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M3 7.39286C3 7.39286 4 8.04466 4.5 9C4.5 9 6 5.25 8 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3 18.3929C3 18.3929 4 19.0447 4.5 20C4.5 20 6 16.25 8 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h2>{translations.wardrobe_preferences_objectives}</h2>
                        </div>

                        <div className={budgetCompleted ? ('wardrobe-preferences-container-square-done') : ('wardrobe-preferences-container-square')} onClick={() => prepareToOpenOrCloseMenuOptions('budget')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M20.9427 16.8354C20.2864 12.8866 18.2432 9.94613 16.467 8.219C15.9501 7.71642 15.6917 7.46513 15.1208 7.23257C14.5499 7 14.0592 7 13.0778 7H10.9222C9.94081 7 9.4501 7 8.87922 7.23257C8.30834 7.46513 8.04991 7.71642 7.53304 8.219C5.75682 9.94613 3.71361 12.8866 3.05727 16.8354C2.56893 19.7734 5.27927 22 8.30832 22H15.6917C18.7207 22 21.4311 19.7734 20.9427 16.8354Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.25662 4.44287C7.05031 4.14258 6.75128 3.73499 7.36899 3.64205C8.00392 3.54651 8.66321 3.98114 9.30855 3.97221C9.89237 3.96413 10.1898 3.70519 10.5089 3.33548C10.8449 2.94617 11.3652 2 12 2C12.6348 2 13.1551 2.94617 13.4911 3.33548C13.8102 3.70519 14.1076 3.96413 14.6914 3.97221C15.3368 3.98114 15.9961 3.54651 16.631 3.64205C17.2487 3.73499 16.9497 4.14258 16.7434 4.44287L15.8105 5.80064C15.4115 6.38146 15.212 6.67187 14.7944 6.83594C14.3769 7 13.8373 7 12.7582 7H11.2418C10.1627 7 9.6231 7 9.20556 6.83594C8.78802 6.67187 8.5885 6.38146 8.18945 5.80064L7.25662 4.44287Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                <path d="M13.6267 12.9186C13.4105 12.1205 12.3101 11.4003 10.9892 11.9391C9.66829 12.4778 9.45847 14.2113 11.4565 14.3955C12.3595 14.4787 12.9483 14.2989 13.4873 14.8076C14.0264 15.3162 14.1265 16.7308 12.7485 17.112C11.3705 17.4932 10.006 16.8976 9.85742 16.0517M11.8417 10.9927V11.7531M11.8417 17.2293V17.9927" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h2>{translations.wardrobe_preferences_budget}</h2>
                        </div>
                    </div>

                    <div className='wardrobe-preferences-message-container'>
                        <label>{translations.wardrobe_preferences_additionalDetails}</label>
                        <textarea placeholder={translations.wardrobe_preferences_additionalDetailsPlaceholder} value={descriptionMessage} onChange={(e) => setDescriptionMessage(e.target.value)} />
                    </div>

                    <div className='wardrobe-preferences-save'>
                        {loadingSaving ? (
                            <button disabled>{translations.wardrobe_preferences_holdOn}</button>
                        ) : (
                            <button onClick={onSubmit}>{translations.wardrobe_preferences_submit}</button>
                        )}
                        {lastUpdate !== '' && (
                            <span>{translations.wardrobe_preferences_lastUpdateDate} {lastUpdate}</span>
                        )}
                    </div>

                    {menuOpen && (
                        <div className='wardrobe-preferences-options-wrapper'>
                            <div className='wardrobe-preferences-options-menu-container'>
                                {menuOptionsOpen === 'preferences' && (
                                    <PREFERENCESFORM_USERPREFERENCES
                                        preferencesSavedJSON={preferencesSaveJSON}
                                        setPreferencesSavedJSON={setPreferencesSaveJSON}
                                        setPreferencesCompleted={setPreferencesCompleted}
                                        setMenuOptionsOpen={setMenuOptionsOpen}
                                        setMenuOpen={setMenuOpen}
                                        translations={translations}
                                    />
                                )}
                                {menuOptionsOpen === 'style' && (
                                    <PREFERENCESFORM_STYLE
                                        preferencesSavedJSON={styleSaveJSON}
                                        setPreferencesSavedJSON={setStyleSaveJSON}
                                        setPreferencesCompleted={setStyleOcasionsCompleted}
                                        setMenuOptionsOpen={setMenuOptionsOpen}
                                        setMenuOpen={setMenuOpen}
                                        translations={translations}
                                    />
                                )}
                                {menuOptionsOpen === 'inspiration' && (
                                    <PREFERENCESFORM_INSPIRATION
                                        preferencesSavedJSON={inspirationSaveJSON}
                                        setPreferencesSavedJSON={setInspirationSaveJSON}
                                        setPreferencesCompleted={setInspirationsCompleted}
                                        setMenuOptionsOpen={setMenuOptionsOpen}
                                        setMenuOpen={setMenuOpen}
                                        translations={translations}
                                    />
                                )}
                                {menuOptionsOpen === 'objectives' && (
                                    <PREFERENCESFORM_OBJECTIVES
                                        preferencesSavedJSON={objectivesSaveJSON}
                                        setPreferencesSavedJSON={setObjectivesSaveJSON}
                                        setPreferencesCompleted={setObjectivesCompleted}
                                        setMenuOptionsOpen={setMenuOptionsOpen}
                                        setMenuOpen={setMenuOpen}
                                        translations={translations}
                                    />
                                )}
                                {menuOptionsOpen === 'budget' && (
                                    <PREFERENCESFORM_BUDGET
                                        preferencesSavedJSON={budgetSaveJSON}
                                        setPreferencesSavedJSON={setBudgetSaveJSON}
                                        setPreferencesCompleted={setBudgetCompleted}
                                        setMenuOptionsOpen={setMenuOptionsOpen}
                                        setMenuOpen={setMenuOpen}
                                        translations={translations}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </>

            )}

        </section>
    );
}

export default WardrobePreferencesForm;
