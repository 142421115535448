import React, { useState, useRef, useContext } from 'react';
import MainContext from '../../context/MainContext'
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import IMAGE from './newShare/Image'
import PROPERTIES from './newShare/Properties'
import FULLWIDTHLOADER from '../public/FullWidthLoader';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/newshare.css'

function Dashboard() {
    const { getSessionInfo, logout } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    // eslint-disable-next-line
    const [image, setImage] = useState(null);
    const imageRef = useRef(null);

    const [gender, setGender] = useState('');
    const [style, setStyle] = useState('');
    const [otherOptions, setOtherOptions] = useState('');

    const [clothing, setClothing] = useState({
        count: 0,
        items: {
            jacket: '',
            blazer: '',
            vest: '',
            sweater: '',
            blouse: '',
            dress: '',
            polo: '',
            shirt: '',
            tshirt: '',
            pants: '',
            jeans: '',
            shorts: '',
            skirt: '',
            pijama: '',
            swimwear: '',
            boxers: '',
            bra: '',
            panties: '',
            socks: '',
            other: ''
        }
    });

    const [accessories, setAccessories] = useState({
        count: 0,
        items: {
            cap: '',
            hat: '',
            gloves: '',
            scarf: '',
            watch: '',
            belt: '',
            handbag: '',
            necklace: '',
            ring: '',
            earrings: '',
            bag: '',
            bagpack: '',
            tie: '',
            bowtie: '',
            bracelet: '',
            wallet: '',
            glasses: '',
            other: ''
        }
    });

    const [footwear, setFootwear] = useState({
        count: 0,
        items: {
            shoes: '',
            sneakers: '',
            sandals: '',
            mocassins: '',
            boots: '',
            ankleboots: '',
            highheels: '',
            elegantshoes: '',
            flipflops: '',
            slippers: '',
            other: ''
        }
    });

    const isDisabledSaveButton = imageRef && gender && style && (clothing.count > 0 || accessories.count > 0 || footwear.count > 0);
    const [uploading, setUploading] = useState(false);

    // Receives the Image from the Image React Fragment
    const handleImageSelection = (selectedImage) => {
        setImage(selectedImage);
    };

    const uploadPictureToAzure = async () => {
        setUploading(true);

        let response;

        if (imageRef.current) {
            response = await imageRef.current.submitCroppedImage();
        }

        if (response.ok) {
            const blob = await response.blob();

            const baseURL = `${MainContext.apiURL}/api/v1/private/shares/create/share`;

            const formData = new FormData();
            formData.append('image', blob);
            formData.append('username', localStoredUsername);
            formData.append('gender', gender);
            formData.append('style', style);
            formData.append('clothes', JSON.stringify(clothing));
            formData.append('accessories', JSON.stringify(accessories));
            formData.append('footwear', JSON.stringify(footwear));
            formData.append('otherOptions', JSON.stringify(otherOptions));

            fetch(baseURL, {
                method: 'POST',
                headers: {
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession
                },
                credentials: 'include',
                body: formData,
            })
                .then(response => {
                    if (response.status === 403) {
                        setUploading(false);
                        logout();
                    } else if (!response.ok) {
                        setUploading(false);
                        return Promise.reject({ status: response.status, statusText: response.statusText });
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    const newShare = { picture: data.picture, extension: '.jpg' };

                    const savedShares = localStorage.getItem('profile-shares');
                    let sharesArray = [];

                    // Ensure the parsed data is an array, or initialize it as an empty array
                    if (savedShares) {
                        try {
                            const parsedShares = JSON.parse(savedShares);
                            sharesArray = Array.isArray(parsedShares) ? parsedShares : [];
                        } catch (error) {
                            console.error('Error parsing savedShares:', error);
                            sharesArray = []; // Initialize as an empty array if parsing fails
                        }
                    } else {
                        localStorage.setItem('profile-shares-temp', true);
                    }

                    sharesArray.unshift(newShare);

                    localStorage.setItem('profile-shares', JSON.stringify(sharesArray));

                    setUploading(false);

                    window.location.href = `/${language}/${localStoredUsername}/share/${data.picture}`;

                })
                .catch(error => {
                    console.error('Error creating account:', error);
                });
        }
    };

    return (
        <section className='newshare-wrapper'>
            <div className='newshare-images-wrapper'>
                <IMAGE
                    onImageSelect={handleImageSelection}
                    ref={imageRef}
                    translations={translations}
                />
            </div>
            <div className='newshare-references-wrapper'>
                <PROPERTIES
                    gender={gender}
                    setGender={setGender}
                    style={style}
                    setStyle={setStyle}
                    otherOptions={otherOptions}
                    setOtherOptions={setOtherOptions}
                    clothing={clothing}
                    setClothing={setClothing}
                    accessories={accessories}
                    setAccessories={setAccessories}
                    footwear={footwear}
                    setFootwear={setFootwear}
                    language={language}
                    translations={translations}
                    loading={uploading}
                    isDisabledSaveButton={isDisabledSaveButton}
                    submitShare={uploadPictureToAzure}
                />
            </div>
            {uploading && (
                <FULLWIDTHLOADER loading={true} />
            )}
        </section>
    );
}

export default Dashboard;