import { useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_AI.css';

function SECTIONAI() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <div className="landingPage-ai-wrapper">

      <div className='landingPage-ai-demos'>
        <img src='/landingPage/ai_demo.png' alt='' />
      </div>

      <div className='landingPage-ai-title'>
        <h2>{translations.landingPage_ai_title}</h2>
      </div>
      <div className='landingPage-ai-subtitle'>
        <p>{translations.landingPage_ai_subtitle01} <b>{translations.landingPage_ai_subtitle02}</b> {translations.landingPage_ai_subtitle03}</p>
      </div>

      <div className='landingPage-ai-grid'>

        <div className='landingPage-ai-grid-box'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M22 11.5667C22 16.8499 17.5222 21.1334 12 21.1334C11.3507 21.1343 10.7032 21.0742 10.0654 20.9545C9.60633 20.8682 9.37678 20.8251 9.21653 20.8496C9.05627 20.8741 8.82918 20.9948 8.37499 21.2364C7.09014 21.9197 5.59195 22.161 4.15111 21.893C4.69874 21.2194 5.07275 20.4112 5.23778 19.5448C5.33778 19.0148 5.09 18.5 4.71889 18.1231C3.03333 16.4115 2 14.1051 2 11.5667C2 6.28357 6.47778 2 12 2C17.5222 2 22 6.28357 22 11.5667Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M11.9955 12H12.0045M15.991 12H16M8 12H8.00897" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h3>{translations.landingPage_ai_talk}</h3>
          <span>{translations.landingPage_ai_talkDescription}</span>
        </div>

        <div className='landingPage-ai-grid-box'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 11.5C12.4955 11.5 12.9562 11.3015 13.8775 10.9045L14.5423 10.618C16.1808 9.91202 17 9.55902 17 9C17 8.44098 16.1808 8.08798 14.5423 7.38197L13.8775 7.09549C12.9562 6.6985 12.4955 6.5 12 6.5C11.5045 6.5 11.0438 6.6985 10.1225 7.09549L9.45768 7.38197C7.81923 8.08798 7 8.44098 7 9C7 9.55902 7.81923 9.91202 9.45768 10.618L10.1225 10.9045C11.0438 11.3015 11.5045 11.5 12 11.5ZM12 11.5V17.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M17 9V15C17 15.559 16.1808 15.912 14.5423 16.618L13.8775 16.9045C12.9562 17.3015 12.4955 17.5 12 17.5C11.5045 17.5 11.0438 17.3015 10.1225 16.9045L9.45768 16.618C7.81923 15.912 7 15.559 7 15V9" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M9.14426 2.5C6.48724 2.56075 4.93529 2.81456 3.87493 3.87493C2.81456 4.93529 2.56075 6.48724 2.5 9.14426M14.8557 2.5C17.5128 2.56075 19.0647 2.81456 20.1251 3.87493C21.1854 4.93529 21.4392 6.48724 21.5 9.14426M14.8557 21.5C17.5128 21.4392 19.0647 21.1854 20.1251 20.1251C21.1854 19.0647 21.4392 17.5128 21.5 14.8557M9.14426 21.5C6.48724 21.4392 4.93529 21.1854 3.87493 20.1251C2.81456 19.0647 2.56075 17.5128 2.5 14.8557" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h3>{translations.landingPage_ai_see}</h3>
          <span>{translations.landingPage_ai_seeDescription}</span>
        </div>

        <div className='landingPage-ai-grid-box'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13 3.00231C12.5299 3 12.0307 3 11.5 3C7.02166 3 4.78249 3 3.39124 4.39124C2 5.78249 2 8.02166 2 12.5C2 16.9783 2 19.2175 3.39124 20.6088C4.78249 22 7.02166 22 11.5 22C15.9783 22 18.2175 22 19.6088 20.6088C20.9472 19.2703 20.998 17.147 20.9999 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M2 14.1354C2.61902 14.0455 3.24484 14.0011 3.87171 14.0027C6.52365 13.9466 9.11064 14.7729 11.1711 16.3342C13.082 17.7821 14.4247 19.7749 15 22" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M21 16.8962C19.8246 16.3009 18.6088 15.9988 17.3862 16.0001C15.5345 15.9928 13.7015 16.6733 12 18" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M17 4.5C17.4915 3.9943 18.7998 2 19.5 2M22 4.5C21.5085 3.9943 20.2002 2 19.5 2M19.5 2V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h3>{translations.landingPage_ai_discover}</h3>
          <span>{translations.landingPage_ai_discoverDescription}</span>


          <div className='landingPage-ai-grid-box-soon'>
            <p>{translations.landingPage_ai_comingSoon}</p>
          </div>
        </div>
      </div>

      <div className='landingPage-ai-cta'>
        <p>{translations.landingPage_ai_ctaDescription}</p>
        {!isAuthenticated ? (
          <a href={`/${language}/register/plus`}><button>{translations.landingPage_ai_ctaButton}</button></a>
        ) : (
          <a href={`/${language}/account/${localStoredUsername}/ai/chat`}><button>{translations.landingPage_ai_ctaButton}</button></a>
        )}
      </div>
    </div>
  );
}

export default SECTIONAI;
