import { useState, useContext, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useLocation } from 'react-router-dom';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/MenuTop.css';

function MENUTOP() {
  const { getSessionInfo, logout } = useAuth();
  const { language } = useContext(LanguageContext);
  const { localStoredName, localStoredSurname, localStoredUsername, localStoredProfilePicture } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [menuActive, setMenuActive] = useState('');
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const pathSegment = location.pathname.split('/')[4];
    setMenuActive(pathSegment);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the menu is open and if the click happened outside of it
      if (profileMenuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setProfileMenuOpen(false); // Close the menu
      }
    };

    // Add event listener when the menu is open
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount or when the menu closes
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [profileMenuOpen]);

  return (
    <div className="private-menutop-wrapper">
      <div className='private-topMenu-title' translate='no'>
      <a href={`/${language}/account/${localStoredUsername}/mural`}><h1>Match the Look</h1></a>
      </div>
      <div className='private-topMenu-title-mobile' translate='no'>
        <h1>MtL</h1>
      </div>
      <div className='private-topMenu-options'>

        <a href={`/${language}/account/${localStoredUsername}/mural`}>
          <div className={menuActive === 'mural' ? ('private-topMenu-options-option-active') : ('private-topMenu-options-option')} onClick={() => setMenuActive('mural')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M7.08848 4.76364L6.08847 5.54453C4.57182 6.72887 3.81348 7.32105 3.40674 8.15601C3 8.99097 3 9.95552 3 11.8846V13.9767C3 17.763 3 19.6562 4.17157 20.8325C5.11466 21.7793 6.52043 21.964 9 22V18.0057C9 17.0738 9 16.6078 9.15224 16.2403C9.35523 15.7502 9.74458 15.3609 10.2346 15.1579C10.6022 15.0057 11.0681 15.0057 12 15.0057C12.9319 15.0057 13.3978 15.0057 13.7654 15.1579C14.2554 15.3609 14.6448 15.7502 14.8478 16.2403C15 16.6078 15 17.0738 15 18.0057V22C17.4796 21.964 18.8853 21.7793 19.8284 20.8325C21 19.6562 21 17.763 21 13.9767V11.8846C21 9.95552 21 8.99097 20.5933 8.15601C20.1865 7.32105 19.4282 6.72887 17.9115 5.54453L16.9115 4.76364C14.5521 2.92121 13.3724 2 12 2C10.6276 2 9.44787 2.92121 7.08848 4.76364Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
            <span>{translations.private_menuTop_dashboard}</span>
          </div>
        </a>

        <a href={`/${language}/account/${localStoredUsername}/new-share`}>
          <div className={menuActive === 'new-share' ? ('private-topMenu-options-option-active') : ('private-topMenu-options-option')} onClick={() => setMenuActive('new-share')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
            </svg>
            <span>{translations.private_menuTop_create}</span>
          </div>
        </a>

        <a href={`/${language}/account/${localStoredUsername}/ai/chat`}>
          <div className={menuActive === 'ai' ? ('private-topMenu-options-option-active') : ('private-topMenu-options-option')} onClick={() => setMenuActive('ai')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 16V14C19 11.1716 19 9.75736 18.1213 8.87868C17.2426 8 15.8284 8 13 8H11C8.17157 8 6.75736 8 5.87868 8.87868C5 9.75736 5 11.1716 5 14V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M19 18C20.4142 18 21.1213 18 21.5607 17.5607C22 17.1213 22 16.4142 22 15C22 13.5858 22 12.8787 21.5607 12.4393C21.1213 12 20.4142 12 19 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 18C3.58579 18 2.87868 18 2.43934 17.5607C2 17.1213 2 16.4142 2 15C2 13.5858 2 12.8787 2.43934 12.4393C2.87868 12 3.58579 12 5 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M13.5 3.5C13.5 4.32843 12.8284 5 12 5C11.1716 5 10.5 4.32843 10.5 3.5C10.5 2.67157 11.1716 2 12 2C12.8284 2 13.5 2.67157 13.5 3.5Z" stroke="currentColor" stroke-width="1.5" />
              <path d="M12 5V8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 17.5C10 17.5 10.6667 18 12 18C13.3333 18 14 17.5 14 17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            <span>{translations.private_menuTop_AI}</span>
          </div>
        </a>

        <a href={`/${language}/account/${localStoredUsername}/wardrobe`}>
          <div className={menuActive === 'wardrobe' ? ('private-topMenu-options-option-active') : ('private-topMenu-options-option')} onClick={() => setMenuActive('wardrobe')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M6 20V22M18 20V22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 14V8C3 5.17157 3 3.75736 3.93037 2.87868C4.86073 2 6.35814 2 9.35294 2H14.6471C17.6419 2 19.1393 2 20.0696 2.87868C21 3.75736 21 5.17157 21 8V14C21 16.8284 21 18.2426 20.0696 19.1213C19.1393 20 17.6419 20 14.6471 20H9.35294C6.35814 20 4.86073 20 3.93037 19.1213C3 18.2426 3 16.8284 3 14Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 20V2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 10V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 10V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>{translations.private_menuTop_wardrobe}</span>
          </div>
        </a>

      </div>
      <div className='private-topMenu-profile'>
        <div className='private-topMenu-profile-option' onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
          {localStoredProfilePicture ? (
            <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
              <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          )}
          <div className='private-topMenu-profile-option-secondary'>
            <span>{translations.private_menuTop_profile}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>

          {profileMenuOpen && (
            <div className='private-topMenu-profile-menu-open-wrapper' ref={menuRef}>
              <div className='private-topMenu-profile-menu-open-background'>

                <div className='private-topMenu-profile-menu-open-profile'>
                  {localStoredProfilePicture ? (
                    <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                      <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                  )}
                  <div className='private-topMenu-profile-menu-open-profile-userInfo'>
                    <p>{localStoredName} {localStoredSurname}</p>
                    <p>@{localStoredUsername}</p>
                  </div>
                </div>
                <div className='private-topMenu-profile-menu-open-profile-button'>
                  <a href={`/${language}/${localStoredUsername}`}><button>{translations.private_menuTop_viewProfile}</button></a>
                </div>
              </div>

              <div className='private-topMenu-profile-menu-open-options'>
                <div className='private-topMenu-profile-menu-open-options-wrapper'>
                  <h2>{translations.private_menuTop_subscription}</h2>
                  <a href={`/${language}/account/${localStoredUsername}/subscription`}>{translations.private_menuTop_manageSubscription}</a>
                </div>

                <div className='private-topMenu-profile-menu-open-options-wrapper'>
                  <h2>{translations.private_menuTop_account}</h2>
                  <a href={`/${language}/account/${localStoredUsername}/settings`}>{translations.private_menuTop_mySettings}</a>
                </div>

                <div className='private-topMenu-profile-menu-open-options-wrapper'>
                  <a className='private-topMenu-profile-menu-open-options-wrapper-logout' onClick={() => logout(language)}>{translations.private_menuTop_logout}</a>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MENUTOP;
