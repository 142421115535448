import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import { Helmet } from 'react-helmet';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/payments/payments.css';


const HOOKERPAYMENTCONFIRMATION = () => {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredUsername, localStoredEmail, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const paymentIntent = (localStorage.getItem('subscription-payment-intent') || null);
    const paymentIntentPlan = (localStorage.getItem('subscription-payment-intent-plan') || null);

    const [seconds, setSeconds] = useState(30);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 1) {
                    localStorage.removeItem('subscription-payment-intent');
                    localStorage.removeItem('subscription-payment-intent-plan');
                    clearInterval(interval);
                    if (paymentIntentPlan !== null && paymentIntentPlan === 'plus') {
                        window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`;
                    } else if (paymentIntent !== null && paymentIntentPlan === 'premium') {
                        window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`;
                    } else {
                        window.location.href = `/${language}/account/${localStoredUsername}`;
                    }
                    return 0;
                }
                if (prevSeconds === 0) {
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        if (paymentIntent) {
            const paymentTimestamp = new Date(JSON.parse(paymentIntent).timestamp).getTime();
            const currentTime = Date.now();
            const thirtyMinutesInMillis = 30 * 60 * 1000;

            if (currentTime - paymentTimestamp <= thirtyMinutesInMillis) {
                if (paymentIntentPlan === null) {
                    localStorage.removeItem('subscription-payment-intent');
                    localStorage.removeItem('subscription-payment-intent-plan');
                    clearInterval(interval);
                    window.location.href = `/${language}/account/${localStoredUsername}`;
                }
                activateSubscription();
            } else {
                localStorage.removeItem('subscription-payment-intent');
                localStorage.removeItem('subscription-payment-intent-plan');
                clearInterval(interval);
                if (paymentIntentPlan === 'plus') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`;
                } else if (paymentIntentPlan === 'premium') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`;
                } else {
                    return window.location.href = `/${language}/account/${localStoredUsername}`;
                }
            }
        } else {
            localStorage.removeItem('subscription-payment-intent');
            localStorage.removeItem('subscription-payment-intent-plan');
            clearInterval(interval);
            window.location.href = `/${language}/`;
        }

        return () => clearInterval(interval);
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const activateSubscription = () => {

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/activate/subscription`;

        const formData = new URLSearchParams({
            email: localStoredEmail,
            plan: paymentIntentPlan,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                localStorage.removeItem('subscription-payment-intent');
                localStorage.removeItem('subscription-payment-intent-plan');

                if (response.status === 200) {
                    return response.json();
                } else if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {
                localStorage.setItem('subscription', data.plan);

                if (data.plan === 'plus') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`;
                } else if (data.plan === 'premium') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`;
                } else {
                    return window.location.href = `/${language}/account/${localStoredUsername}`;
                }
            })
            .catch(error => {
                console.error('Error fetching payment authorization:', error);
                return window.location.href = `/${language}/account/${localStoredUsername}`;
            });
    };

    return (
        <section className='payment-wrapper'>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <h1>{translations.paymentConfirmation_title}</h1>
            <p>{translations.paymentConfirmation_description01} {seconds} {translations.paymentConfirmation_description02}</p>
            <div className='payment-loader-container'>
                <span class="payment-loader"></span>
            </div>
        </section>
    );
};

export default HOOKERPAYMENTCONFIRMATION;
