import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/deleteShareConfirmation.css';

function GalleryLoader({ deleteShare, confirmDeleteShare, setConfirmDeleteShare }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    useEffect(() => {
        if (confirmDeleteShare) {
            // Block scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none'; // Prevent scrolling on mobile
            // Scroll to the top
            window.scrollTo(0, 0);
        } else {
            // Release scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = ''; // Reset for mobile
        }
        
        // Clean up when component unmounts or confirmDeleteShare changes
        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [confirmDeleteShare]);

    return (
        <section className='deleteShare-confirmation-wrapper'>
            <div className='deleteShare-confirmation-background'>
                <div className='deleteShare-confirmation-container'>
                    <div className='deleteShare-confirmation-title'>
                        <h1>{translations.public_share_deleteShare_title}</h1>
                        <h2>{translations.public_share_deleteShare_subtitle}</h2>
                    </div>
                    <div className='deleteShare-confirmation-buttons'>
                        <button className='deleteShare-confirmation-button-delete' onClick={deleteShare}>{translations.public_share_deleteShare_delete}</button>
                        <button className='deleteShare-confirmation-button-cancel' onClick={() => setConfirmDeleteShare(false)}>{translations.public_share_deleteShare_cancel}</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GalleryLoader;
