import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import MainContext from '../../../context/MainContext';
import Step3 from '../register/Step3';
import '../../../style/auth/login.css'

function Login() {
    const { username, share } = useParams();
    const { login } = useAuth();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [activateAccount, setActivateAccount] = useState(false);
    const [registrationStep, setRegistrationStep] = useState(3);

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
    };

    const handleChange = (e) => {
        const inputValue = e.target.value.trim();
        setErrorMessage('');
    
        setEmail(inputValue);
    
        if (validateEmail(inputValue)) {
            //setError(''); // Clear error if valid
        } else {
            //setError('Invalid email format'); // Show error if invalid
        }
    };    

    const loginUsers = (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateEmail(email) || password === '') {
            setLoading(false);
            return;
        }

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/login`;

        const formData = new URLSearchParams({
            email: email.toLowerCase(),
            password: password,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
            credentials: 'include',
        })
            .then(response => {
                if (response.status === 201) {
                    return setActivateAccount(true);
                }

                if (!response.ok) {
                    setLoading(false);
                    setErrorMessage(translations.auth_login_emailOrPasswordWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                localStorage.removeItem('public-mural-shares');
                localStorage.removeItem('public-mural-shares-end');
                localStorage.removeItem('public-mural-shares-filter-gender');
                localStorage.removeItem('public-mural-shares-filter-style');

                login(data.name, data.surname, data.email, data.username, data.session, data.picture, data.gender, data.birthday, data.country, data.socials, data.referralcode, data.subscription, data.wardrobe);

                setLoading(false);
                if (username !== undefined && share !== undefined) {
                    navigate(`/${language}/${username}/share/${share}`);
                } else {
                    navigate(`/${language}/account/${data.username}/mural`);
                }
            })
            .catch(error => {
                console.error('Error logging in:', error);
                setLoading(false);
                setErrorMessage(translations.auth_login_emailOrPasswordWrong);
            });
    };

    return (
        <section className='login-wrapper'>
            <div className='login-description-wrapper'>
                <div className='login-description-main-title'>
                    <a href={`/${language}/`}><h1>Match the Look</h1></a>
                </div>
            </div>
            <div className='login-form-wrapper'>
                <div className='login-form-container'>
                    {registrationStep < 4 ? (
                        <div className='login-form-title'>
                            <h2>{translations.auth_login_title}</h2>
                            <h3>{translations.auth_login_subtitle}</h3>
                        </div>
                    ) : (
                        <div className='register-form-title-final'>
                            <h2>{translations.auth_login_accountActivationCongrats}</h2>
                            <h3>{translations.auth_login_youCanSignIn}</h3>
                        </div>
                    )}

                    {!activateAccount ? (
                        <>
                            <form className='login-form-options'>
                                <div className='login-form-options-components'>
                                    <label>{translations.auth_login_email}</label>
                                    <input
                                        type='text'
                                        placeholder={translations.auth_login_email}
                                        required
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='login-form-options-components'>
                                    <label>{translations.auth_login_password}</label>
                                    <input type='password' placeholder={translations.auth_login_password} value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                                <div className='login-form-options-components-options'>
                                    <a href={`/${language}/forgot-password`}>{translations.auth_login_forgotPassword}</a>
                                </div>
                                {errorMessage !== '' && (
                                    <div className='login-form-error'>
                                        {errorMessage}
                                    </div>
                                )}
                                <div className='login-form-options-components-button'>
                                    <button disabled={loading} onClick={loginUsers}>
                                        {loading ? (
                                            <span className="loading-dots">
                                                {translations.auth_login_holdOn}<span>.</span><span>.</span><span>.</span>
                                            </span>
                                        ) : (
                                            translations.auth_login_signIn
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className='login-form-login-cta'>
                                <p>{translations.auth_login_stillDontHaveAccount} <a href={`/${language}/register`}>{translations.auth_login_joinUs}</a></p>
                            </div>
                        </>
                    ) : (
                        <>
                            {registrationStep === 3 && (
                                <Step3
                                    email={email}
                                    plan={'free'}
                                    language={language}
                                    loading={loading}
                                    setLoading={setLoading}
                                    MainContext={MainContext}
                                    setRegistrationStep={setRegistrationStep}
                                />
                            )}
                            {registrationStep === 4 && (
                                <div className='register-form-options-components-button-final'>
                                    <div className='register-form-options-components-button'>
                                        <button
                                            disabled={loading}
                                            onClick={() => {
                                                if (!loading) {
                                                    window.location.href = `/${language}/login`;
                                                }
                                            }}
                                        >
                                            {loading ? (
                                                <span className="loading-dots">
                                                    {translations.auth_login_holdOn}<span>.</span><span>.</span><span>.</span>
                                                </span>
                                            ) : (
                                                translations.auth_login_signIn
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section >
    );
}

export default Login;