import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/galleryLoader.css';

function GalleryLoader() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='gallery-loading-wrapper'>
            <div className='gallery-loading-wrapper-ref'>
                <span class="gallery-loading-wrapper-ref-loader"></span>
                <h1>{translations.loader_galleryLoader_loadingShares}</h1>
            </div>
        </section>
    );
}

export default GalleryLoader;
