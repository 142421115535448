import React, { useState, useEffect, useRef, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/fullWidthLoader.css';

function FullWidthLoader({ loading, toggleMenu }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const loadingRef = useRef(null);

    const messages = [
        translations.loader_fullWidth_message1,
        translations.loader_fullWidth_message2,
        translations.loader_fullWidth_message3,
        translations.loader_fullWidth_message4,
        translations.loader_fullWidth_message5,
    ];

    const [message, setMessage] = useState(messages[0]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (loadingRef.current && !loadingRef.current.contains(event.target)) {
                toggleMenu && toggleMenu();
            }
        };

        if (loading) {
            document.body.classList.add('no-scroll');
            document.addEventListener('mousedown', handleClickOutside);

            // Cycle through messages
            const messageInterval = setInterval(() => {
                setMessage((prev) => {
                    const currentIndex = messages.indexOf(prev);
                    const nextIndex = (currentIndex + 1) % messages.length;
                    return messages[nextIndex];
                });
            }, 5000);

            return () => {
                clearInterval(messageInterval);
            };
        } else {
            document.body.classList.remove('no-scroll');
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.body.classList.remove('no-scroll');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [loading, toggleMenu]);
    /* eslint-disable react-hooks/exhaustive-deps */

    return (
        loading && (
            <section className='loader-wrapper' ref={loadingRef}>
                <span className="loader">
                    <p className="loader-message">{message}</p>
                </span>
            </section>
        )
    );
}

export default FullWidthLoader;
