import React, { useState, useEffect } from 'react';
import '../../../style/private/wardrobe/PreferencesForm.css';

function WardrobePreferencesForm({ 
    preferencesSavedJSON,
    setPreferencesCompleted, 
    setPreferencesSavedJSON, 
    setMenuOptionsOpen, 
    setMenuOpen,
    translations
}) {
    const [option1, setOption1] = useState([]);
    const [option2, setOption2] = useState([]);
    const [option3, setOption3] = useState([]);
    const [option4, setOption4] = useState([]);

    const option1OptionsList = [
        { value: 'update', label: translations.wardrobe_preferences_objectivesPreferences_update },
        { value: 'remake', label: translations.wardrobe_preferences_objectivesPreferences_remake },
        { value: 'mix', label: translations.wardrobe_preferences_objectivesPreferences_mix },
    ];
    
    const option2OptionsList = [
        { value: 'capsule_wardrobe', label: translations.wardrobe_preferences_objectivesPreferences_capsuleWardrobe },
        { value: 'discover_trends', label: translations.wardrobe_preferences_objectivesPreferences_discoverTrends },
        { value: 'versatile_pieces', label: translations.wardrobe_preferences_objectivesPreferences_versatilePieces },
        { value: 'mix_and_match', label: translations.wardrobe_preferences_objectivesPreferences_learnToMix },
        { value: 'update_professional_attire', label: translations.wardrobe_preferences_objectivesPreferences_updateProfessionaClothes },
    ];
    
    const option3OptionsList = [
        { value: 'a_lot', label: translations.wardrobe_preferences_objectivesPreferences_aLot },
        { value: 'moderate', label: translations.wardrobe_preferences_objectivesPreferences_moderate },
        { value: 'very_little', label: translations.wardrobe_preferences_objectivesPreferences_veryLittle },
        { value: 'dont_enjoy', label: translations.wardrobe_preferences_objectivesPreferences_dontEnjoy },
    ];
    
    const option4OptionsList = [
        { value: 'yes', label: translations.wardrobe_preferences_objectivesPreferences_yes },
        { value: 'no', label: translations.wardrobe_preferences_objectivesPreferences_no },
        { value: 'only_subtle', label: translations.wardrobe_preferences_objectivesPreferences_onlySubtle },
    ];

    useEffect(() => {
        if (preferencesSavedJSON) {
            setOption1(preferencesSavedJSON.updateOrRemakeWardrobe || []);
            setOption2(preferencesSavedJSON.objectiveNow || []);
            setOption3(preferencesSavedJSON.timeSpentOnClothesShopping || []);
            setOption4(preferencesSavedJSON.otherRecommendations || []);
        }
    }, [preferencesSavedJSON]);

    function savePreferencesIntoJSON() {
        const newUserOptions = {
            updateOrRemakeWardrobe: option1,
            objectiveNow: option2,
            timeSpentOnClothesShopping: option3,
            otherRecommendations: option4,
        };

        const hasPreferences =
            option1.length > 0 ||
            option2.length > 0 ||
            option3.length > 0 ||
            option4.length > 0;

        setPreferencesCompleted(hasPreferences);
        setPreferencesSavedJSON(newUserOptions);
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    const option1Options = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setOption1((prevChoices) => [...prevChoices, value]);
        } else {
            setOption1((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const option2Options = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setOption2((prevChoices) => [...prevChoices, value]);
        } else {
            setOption2((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const option3Options = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setOption3((prevChoices) => [...prevChoices, value]);
        } else {
            setOption3((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const option4Options = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setOption4((prevChoices) => [...prevChoices, value]);
        } else {
            setOption4((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    function closeMenuWithoutSave(){
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    return (
        <div className='wardrobe-preferences-options-menu'>
            <div className='wardrobe-preferences-options-menu-title'>
                <div className='wardrobe-preferences-options-menu-title-content'>
                    <h3>{translations.wardrobe_preferences_objectivesPreferences_title}</h3>
                </div>
                <div className='wardrobe-preferences-options-menu-title-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeMenuWithoutSave}>
                        <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                </div>
            </div>
            <div className='wardrobe-preferences-options-menu-options'>
            <p>{translations.wardrobe_preferences_objectivesPreferences_question1}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option1OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-style'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={option1Options}
                                checked={option1.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
            <p>{translations.wardrobe_preferences_objectivesPreferences_question2}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option2OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-clothes'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={option2Options}
                                checked={option2.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
            <p>{translations.wardrobe_preferences_objectivesPreferences_question3}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option3OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-colors'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={option3Options}
                                checked={option3.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
            <p>{translations.wardrobe_preferences_objectivesPreferences_question4}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option4OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-footwear'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={option4Options}
                                checked={option4.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-save'>
                <button onClick={savePreferencesIntoJSON} className='wardrobe-preferences-options-menu-save-button'>
                    {translations.wardrobe_preferences_objectivesPreferences_save}
                </button>
            </div>
        </div>
    );
}

export default WardrobePreferencesForm;
