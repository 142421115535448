import React from 'react';
import { Helmet } from 'react-helmet';
import GalleryLoader from './public/GalleryLoader';
import GalleryImages from '../components/gallery-image'
import LoginNowPrompt from './auth/login/LoginNowPrompt'
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/mural_gallery.css'

function Mural_Gallery({ language, galleryData, loadOlderShares, loadingAllShares, loadMoreShares, noShares, end, loginNowPrompt, setLoginNowPrompt }) {
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const gender = (localStorage.getItem('public-mural-shares-filter-gender') || 'all');
    const style = (localStorage.getItem('public-mural-shares-filter-style') || 'all');

    const dynamicKeywords = [
        gender,
        style,
        ...new Set(galleryData.map((shareData) => `${shareData.author}'s ${translations.seo_mural_keyworkds}`))
    ].filter(Boolean).join(', ');

    const keywords = `fashion, style, outfit inspiration, AI fashion advisor, personal stylist, fashion SaaS, wardrobe inspiration, clothing recommendations, moda, estilo, inspiração de looks, consultoria de moda com IA, estilista pessoal, inspiração de guarda-roupa, recomendações de roupas, buy clothes, outfit ideas, fashion tips, style improvement, clothing brands, where to buy outfits, inspiration for dressing well, shopping fashion online, affordable fashion, trendy clothing, shop the look, fashion advice, best fashion trends, seasonal outfits, outfit matching, AI style suggestions, personal shopper online, latest fashion trends, fashion influencers, fashion community, style boards, personalized styling, shop outfits online, unique fashion finds, dress better tips, comprar roupa, ideias de looks, dicas de moda, melhorar o estilo, marcas de roupa, onde comprar roupa, inspiração para vestir bem, compras de moda online, moda acessível, roupas da moda, inspiração de looks, melhores tendências de moda, looks sazonais, combinação de outfits, sugestões de estilo IA, personal shopper online, últimas tendências de moda, influenciadores de moda, comunidade de moda, painéis de estilo, estilo personalizado, comprar outfits online, moda única, dicas para vestir melhor, ${dynamicKeywords}`;

    return (
        <section className='muralgallery-wrapper'>
            {!noShares && (
                <Helmet>
                    <title>{translations.seo_mural_title}</title>
                    <meta name="description" content={translations.seo_mural_description} />
                    <meta name="keywords" content={keywords} />

                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "ItemList",
                            "itemListElement": galleryData.map((shareData, index) => ({
                                "@type": "ListItem",
                                "position": index + 1,
                                "url": `https://photos.matchthelook.com/clothingfashion/${shareData.share}.${shareData.extension}`,
                                "author": {
                                    "@type": "Person",
                                    "name": shareData.author,
                                    "image": `https://photos.matchthelook.com/profilepictures/${shareData.profilePicture}`
                                },
                                "image": `https://photos.matchthelook.com/clothingfashion/${shareData.share}.${shareData.extension}`,
                                "name": `${gender} ${style} - ${translations.seo_mural_name_sharedBy} ${shareData.author}`,
                                "description": `Explore this ${gender} style in ${style} shared by ${shareData.author}. ${translations.seo_mural_description_getInspired}`
                            }))
                        })}
                    </script>
                </Helmet>
            )}
            {noShares ? (
                <div className='dashboardgallery-gallery'>
                    <div className='dashboardgallery-noshares'>
                        <span>{translations.public_mural_gallery_noShares}</span>
                    </div>
                </div>
            ) : (
                <div className='muralgallery-gallery'>
                    <div className='muralgallery-gallery-grid'>
                        {galleryData.map((item) => (
                            <GalleryImages
                                key={item.shareImage}
                                language={language}
                                shareImage={item.share}
                                shareExtension={item.extension}
                                authorUsername={item.author}
                                authorPicture={item.profilePicture}
                            />
                        ))}
                    </div>
                </div>
            )}

            {loginNowPrompt && (
                <LoginNowPrompt
                    language={language}
                    setLoginNowPrompt={setLoginNowPrompt}
                />
            )}


            {!end && !loadingAllShares && (
                <div className='muralgallery-loadMore'>
                    {loadMoreShares ? (
                        <button disabled>{translations.public_mural_gallery_holdOn}</button>
                    ) : (
                        <button onClick={loadOlderShares}>{translations.public_mural_gallery_loadMore}</button>
                    )}
                </div>
            )}

            {loadingAllShares && (
                <GalleryLoader />
            )}
        </section>
    );
}

export default Mural_Gallery;
