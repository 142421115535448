import React, { useState } from 'react';
import '../../../style/private/settings/profile.css';

function PROFILE({ language, localStoredUsername, translations, localStoredProfilePicture, localStoredReferralCode, localStoredSocials, localStoredEmail, localStoredSession, MainContext }) {
    const [username, setUsername] = useState(localStoredUsername);

    const [loadingPicture, setLoadingPicture] = useState(false);
    const [loadingUsername, setLoadingUsername] = useState(false);

    const fileInputRef = React.createRef();

    const [loading, setLoading] = useState(false);
    const [successSocials, setSuccessSocials] = useState('');
    const [successUsername, setSuccessUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [errorSocials, setErrorSocials] = useState('');

    const savedSocials = JSON.parse(localStoredSocials);

    const [facebook, setFacebook] = useState(savedSocials?.facebook || '');
    const [instagram, setInstagram] = useState(savedSocials?.instagram || '');
    const [linkedin, setLinkedin] = useState(savedSocials?.linkedin || '');
    const [tiktok, setTikTok] = useState(savedSocials?.tiktok || '');
    const [youtube, setYoutube] = useState(savedSocials?.youtube || '');
    const [other, setOther] = useState(savedSocials?.other || '');

    const [facebookValidationMessage, setFacebookValidationMessage] = useState('');
    const [instagramValidationMessage, setInstagramValidationMessage] = useState('');
    const [linkedinValidationMessage, setLinkedinValidationMessage] = useState('');
    const [tiktokValidationMessage, setTikTokValidationMessage] = useState('');
    const [youtubeValidationMessage, setYoutubeValidationMessage] = useState('');
    const [otherValidationMessage, setOtherValidationMessage] = useState('');

    const changesUsername = () => {
        if (username === localStoredUsername) {
            setUsernameError('');
            setSuccessUsername('');
            return;
        }

        setLoadingUsername(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/username`;

        const formData = new URLSearchParams({
            newUsername: username.toLocaleLowerCase(),
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (response.status === 409) {
                    setUsernameError(translations.private_profile_account_usernameCannotBeUsed);
                    return setLoadingUsername(false);
                } else if (!response.ok) {
                    setLoadingUsername(false);
                    setUsernameError(translations.private_profile_account_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    localStorage.setItem('username', username.toLocaleLowerCase());
                    window.location.href = '';
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    function blobURLtoBlob(blobURL) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Failed to convert Blob URL to Blob object. Status: ${xhr.status}`));
                }
            };

            xhr.onerror = () => {
                reject(new Error('Failed to convert Blob URL to Blob object. Network error.'));
            };

            xhr.open('GET', blobURL);
            xhr.send();
        });
    }

    const changeProfilePicture = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

            if (!validImageTypes.includes(fileType)) {
                alert(translations.private_profile_account_somethingWentWrong);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setLoadingPicture(true);

            const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/picture`;

            // Convert the Blob URL to a Blob
            blobURLtoBlob(imageUrl)
                .then(blob => {
                    // Create FormData and append the Blob
                    const formData = new FormData();
                    formData.append('image', blob, file.name);

                    console.log(blob);

                    // Send the FormData in a POST request
                    fetch(baseURL, {
                        method: 'POST',
                        headers: {
                            'email': localStoredEmail,
                            'username': localStoredUsername,
                            'session': localStoredSession
                        },
                        credentials: 'include',
                        body: formData,
                    })
                        .then(response => {
                            if (!response.ok) {
                                return Promise.reject({ status: response.status, statusText: response.statusText });
                            }
                            return response.json();
                        })
                        .then(data => {
                            localStorage.setItem('picture', data.picture);
                            window.location.href = '';
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const updateUserSocials = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/socials`;

        const formData = new URLSearchParams({
            facebook: facebook,
            instagram: instagram,
            linkedin: linkedin,
            tiktok: tiktok,
            youtube: youtube,
            other: other,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setErrorSocials(translations.private_settingsProfile_updateSocialMedia_somethingWentWrong);
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const socials = { facebook, instagram, linkedin, tiktok, youtube, other };
                localStorage.setItem('socials', JSON.stringify(socials));
                setLoading(false);
                setSuccessSocials(translations.private_settingsProfile_updateSocialMedia_successfullyUpdated);
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    const isValidURL = (string) => {
        const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9._-]*)*\/?$/;
        return regex.test(string);
    };

    const handleFacebookChange = (e) => {
        let value = e.target.value;

        setFacebook(value);

        if (!isValidURL(value)) {
            setFacebookValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setFacebookValidationMessage('');
        }
    };

    const handleInstagramChange = (e) => {
        let value = e.target.value;

        setInstagram(value);

        if (value && !isValidURL(value)) {
            setInstagramValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setInstagramValidationMessage('');
        }
    };

    const handleLinkedinChange = (e) => {
        let value = e.target.value;

        setLinkedin(value);

        if (value && !isValidURL(value)) {
            setLinkedinValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setLinkedinValidationMessage('');
        }
    };

    const handleTikTokChange = (e) => {
        let value = e.target.value;

        setTikTok(value);

        if (value && !isValidURL(value)) {
            setTikTokValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setTikTokValidationMessage('');
        }
    };

    const handleYoutubeChange = (e) => {
        let value = e.target.value;

        setYoutube(value);

        if (value && !isValidURL(value)) {
            setYoutubeValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setYoutubeValidationMessage('');
        }
    };

    const handleOtherChange = (e) => {
        let value = e.target.value;

        setOther(value);

        if (value && !isValidURL(value)) {
            setOtherValidationMessage(translations.private_profile_socialMedia_URLWillNotBeChanged);
        } else {
            setOtherValidationMessage('');
        }
    };

    return (
        <section className='myAccount-settings-profile-wrapper'>
            <div className='myAccount-settings-profile-wrapper-content'>
                <div className='myAccount-settings-profile-container'>
                    <div className='myAccount-settings-profile-container-title'>
                        <h3>{translations.private_settingsProfile_mainTitle}</h3>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <span>{translations.private_settingsProfile_profilePicture}</span>

                        <div className='myAccount-settings-profile-container-option-container-input-container'>

                            {localStoredProfilePicture ? (
                                <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            )}
                            {!loadingPicture ? (
                                <input type='file' ref={fileInputRef} className='myAccount-settings-profile-container-option-container-input' onChange={changeProfilePicture} accept='.jpeg, .jpg, .png' />
                            ) : (
                                <span className="loading-dots">
                                    {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <span>{translations.private_settingsProfile_username}</span>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input type='text' value={username} onChange={(e) => setUsername(e.target.value)} placeholder={translations.private_settingsProfile_placeholder_username} />
                            <button disabled={loadingUsername} onClick={changesUsername}>
                                {loadingUsername ? (
                                    <span className="loading-dots">
                                        {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                    </span>
                                ) : (
                                    translations.private_profile_socialMedia_change
                                )}
                            </button>
                        </div>

                        {successUsername !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-success'>
                                <p>{successUsername}</p>
                            </div>
                        )}

                        {usernameError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{usernameError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.private_settingsProfile_yourPublicProfile}</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_publicProfile_tooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <a href={`https://www.matchthelook.com/${language}/${localStoredUsername}`}>https://www.matchthelook.com/{language}/{localStoredUsername}</a>
                        </div>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.private_settingsProfile_referralCode}</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_referralCodeTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <a href={`https://www.matchthelook.com/${language}/${localStoredUsername}/${localStoredReferralCode}`} target="_blank" rel="noopener noreferrer">https://www.matchthelook.com/{language}/{localStoredUsername}/{localStoredReferralCode}</a>
                        </div>
                    </div>

                </div>

                <div className='myAccount-settings-profile-container'>
                    <div className='myAccount-settings-profile-container-title'>
                        <h3>{translations.private_settingsProfile_socialMedia}</h3>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Facebook</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='Facebook URL'
                                value={facebook}
                                onChange={handleFacebookChange}
                            />
                        </div>

                        {facebookValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{facebookValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Instagram</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='Instagram URL'
                                value={instagram}
                                onChange={handleInstagramChange}
                            />
                        </div>
                        {instagramValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{instagramValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Linkedin</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='Linkedin URL'
                                value={linkedin}
                                onChange={handleLinkedinChange}
                            />
                        </div>
                        {linkedinValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{linkedinValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Tiktok</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='TikTok URL'
                                value={tiktok}
                                onChange={handleTikTokChange}
                            />
                        </div>
                        {tiktokValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{tiktokValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Youtube</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='Youtube URL'
                                value={youtube}
                                onChange={handleYoutubeChange}
                            />
                        </div>
                        {youtubeValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{youtubeValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>Other</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsProfile_socialMediaTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='Other Website URL'
                                value={other}
                                onChange={handleOtherChange}
                            />
                        </div>
                        {otherValidationMessage !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{otherValidationMessage}</p>
                            </div>
                        )}
                    </div>

                    <button disabled={loading} onClick={updateUserSocials}>
                        {loading ? (
                            <span className="loading-dots">
                                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            translations.private_profile_socialMedia_change
                        )}
                    </button>

                    {successSocials !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-success'>
                            <p>{successSocials}</p>
                        </div>
                    )}

                    {errorSocials !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-error'>
                            <p>{errorSocials}</p>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
}

export default PROFILE;
