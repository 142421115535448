import { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/subscription.css';

function Subscription() {
    const { getSessionInfo, isAuthenticated } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession, localStoredSubscription } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);

    async function prepareToCreateSession(plan) {
        await createCheckoutSession(plan);
    }

    const createCheckoutSession = async (plan) => {
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/pay/subscription`;

        const formData = new URLSearchParams({
            email: localStoredEmail,
            username: localStoredUsername,
            page: 'none',
            language: language,
            plan: plan
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (response.status === 400 && isAuthenticated) {
                    setLoading(false);
                    if (plan === 'plus') {
                        window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`
                    } else if (plan === 'premium') {
                        window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`
                    } else {
                        window.location.href = `/${language}/account/${localStoredUsername}/subscription`
                    }
                } else if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const currentTimestampUTC = new Date().toISOString();

                const paymentIntentObject = {
                    timestamp: currentTimestampUTC
                };

                localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
                localStorage.setItem('subscription-payment-intent-plan', plan);

                setLoading(false);

                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error activating account:', error);
            });
    };

    function createSubscriptionPortal(e) {
        e.preventDefault();

        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/manage/subscription`;

        const formData = new URLSearchParams({
            username: localStoredUsername,
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    }

    return (
        <section className='myAccount-subscription-wrapper'>
            <div className='myAccount-subscription-mainTitle'>
                <h1>{translations.private_subscription_title}</h1>
            </div>
            <div className='myAccount-subscription-container'>
                <div className='myAccount-subscription-card-container'>
                    <div className='myAccount-subscription-card-title'>
                        <h2>{translations.landingPage_pricing_freeTitle}</h2>
                        <p>-€/{translations.landingPage_pricing_month}</p>
                    </div>
                    <div className='myAccount-subscription-card-advantages'>
                        <p>{translations.landingPage_pricing_imageSharing}</p>
                        <p>{translations.landingPage_pricing_limitedRewardPoints}</p>
                        <p>{translations.landingPage_pricing_accessToAI}</p>
                    </div>
                    <div className='myAccount-subscription-card-cta'>
                        {localStoredSubscription === 'free' ? (
                            <button disabled>{translations.private_subscription_currentPlan}</button>
                        ) : (
                            <button disabled={loading} onClick={createSubscriptionPortal}>
                                {loading ? (
                                    <span className="loading-dots">
                                        {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                    </span>
                                ) : (
                                    translations.private_subscription_manageSubscription
                                )}
                            </button>
                        )}
                        <p>{translations.landingPage_pricing_noLoyalty}</p>
                    </div>
                </div>

                <div className='myAccount-subscription-card-container'>
                    <div className='myAccount-subscription-card-title'>
                        <h2>{translations.landingPage_pricing_plusTitle}</h2>
                        <p>9,99€/{translations.landingPage_pricing_month}</p>
                    </div>
                    <div className='myAccount-subscription-card-advantages'>
                        <p>{translations.landingPage_pricing_imageSharing}</p>
                        <p>{translations.landingPage_pricing_exclusiveRewardPoints}</p>
                        <p>{translations.landingPage_pricing_accessToAI}</p>
                        <p>{translations.landingPage_pricing_accessToGenerateAI}</p>
                    </div>
                    <div className='myAccount-subscription-card-cta'>

                        {localStoredSubscription === 'plus' ? (
                            <button disabled>{translations.private_subscription_currentPlan}</button>
                        ) : (
                            <>
                                {localStoredSubscription === 'free' ? (

                                    <button disabled={loading} onClick={() => prepareToCreateSession('plus')}>
                                        {loading ? (
                                            <span className="loading-dots">
                                                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                            </span>
                                        ) : (
                                            translations.landingPage_pricing_upgradePlusButton
                                        )}
                                    </button>
                                ) : (
                                    <button disabled={loading} onClick={createSubscriptionPortal}>
                                        {loading ? (
                                            <span className="loading-dots">
                                                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                            </span>
                                        ) : (
                                            translations.private_subscription_manageSubscription
                                        )}
                                    </button>
                                )}
                            </>
                        )}



                        <p>{translations.landingPage_pricing_noLoyalty}</p>
                    </div>
                </div>

                <div className='myAccount-subscription-card-container'>
                    <div className='myAccount-subscription-card-title'>
                        <h2>{translations.landingPage_pricing_premiumTitle}</h2>
                        <p>25,99€/{translations.landingPage_pricing_month}</p>
                    </div>
                    <div className='myAccount-subscription-card-advantages'>
                        <p>{translations.landingPage_pricing_imageSharing}</p>
                        <p>{translations.landingPage_pricing_exclusiveRewardPoints}</p>
                        <p>{translations.landingPage_pricing_accessToAI}</p>
                        <p>{translations.landingPage_pricing_accessToGenerateAI}</p>
                        <p>{translations.landingPage_pricing_exclusivePromoCodes}</p>
                        <p>{translations.landingPage_pricing_personalStylish}</p>
                    </div>
                    <div className='myAccount-subscription-card-cta'>
                        {localStoredSubscription === 'premium' ? (
                            <button disabled>{translations.private_subscription_currentPlan}</button>
                        ) : (
                            <>
                                <button disabled={loading} onClick={() => prepareToCreateSession('premium')}>
                                    {loading ? (
                                        <span className="loading-dots">
                                            {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                                        </span>
                                    ) : (
                                        translations.landingPage_pricing_upgradePremiumButton
                                    )}
                                </button>
                            </>
                        )}
                        <p>{translations.landingPage_pricing_noLoyalty}</p>
                    </div>
                </div>

            </div>
        </section >
    );
}

export default Subscription;
