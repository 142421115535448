import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { useShares } from '../../context/SharesContext';
import MainContext from '../../context/MainContext';
import MURAL_FILTERS from '../Mural_Filters';
import MURAL_GALLERY from '../Mural_Gallery';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/dashboard.css'

function Dashboard() {
    const { getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [bannerActive, setBannerActive] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(false);

    const gender = (localStorage.getItem('private-mural-shares-filter-gender') || 'all');
    const style = (localStorage.getItem('private-mural-shares-filter-style') || 'all')

    const [loadingAllShares, setLoadingAllShares] = useState(false);
    const [loadMoreShares, setLoadMoreShares] = useState(true);
    const [noShares, setNoShares] = useState(false);

    const { sharesData, setSharesData, loadedSharesCache, setLoadedSharesCache, end, setEnd } = useShares();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setBannerActive(false);

        localStorage.setItem('private-mural-shares-filter-gender', gender);
        localStorage.setItem('private-mural-shares-filter-style', style);

        const cachedShares = localStorage.getItem('private-mural-shares');
        const cachedEnd = localStorage.getItem('private-mural-shares-end');

        setLoadMoreShares(!cachedEnd);

        const isFirstTime = !cachedShares || !cachedEnd;

        if (!isFirstTime) {
            const parsedShares = JSON.parse(cachedShares);

            if (parsedShares.length > 0) {
                setSharesData(parsedShares);
                setLoadedSharesCache(parsedShares);
                setEnd(JSON.parse(cachedEnd));

                const cachedSharesString = JSON.parse(cachedShares);
                const firstShareValue = cachedSharesString[0].share;

                checkForUpdateShares(firstShareValue);
            } else {
                loadFirstShares();
            }
        } else {
            loadFirstShares();
        }
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (filtersOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [filtersOpen]);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function changeFilters(gender, style) {
        setFiltersOpen(false);
        setSharesData([]);
        setLoadedSharesCache([]);
        setLoadingAllShares(false);
        setLoadMoreShares(true);
        setNoShares(false);
        setEnd(true);

        localStorage.removeItem('private-mural-shares');
        localStorage.removeItem('private-mural-shares-end');

        localStorage.setItem('private-mural-shares-filter-gender', gender);
        localStorage.setItem('private-mural-shares-filter-style', style);

        window.location.href = '';
    }

    async function loadFirstShares() {
        setLoadingAllShares(true);
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/shares`;
        const queryParams = new URLSearchParams({
            gender: gender,
            style: style,
        });

        try {
            const response = await fetch(`${baseURL}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.end && !data.sharesAvailable) {
                setNoShares(true);
                localStorage.setItem('private-mural-shares', JSON.stringify([]));
            } else {
                const newShares = [...sharesData, ...data.shares];
                setSharesData(newShares);
                setLoadedSharesCache(prev => [...prev, ...data.shares]);
                setEnd(data.end);

                localStorage.setItem('private-mural-shares', JSON.stringify(newShares));
                localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
            }
        } catch (error) {
            console.error('Failed to load shares:', error);
        } finally {
            setLoadMoreShares(false);
            setLoadingAllShares(false);
        }
    }

    async function checkForUpdateShares(firstShare) {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/shares`;

        let queryParams = new URLSearchParams({
            gender: gender,
            style: style,
            lastShare: firstShare
        });

        try {
            const response = await fetch(`${baseURL}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.sharesAvailable) {
                const cachedShares = localStorage.getItem('private-mural-shares');
                const parsedCachedShares = cachedShares ? JSON.parse(cachedShares) : [];

                const updatedShares = [...data.shares, ...parsedCachedShares];

                setSharesData(updatedShares);
                setLoadedSharesCache(updatedShares);

                setEnd(data.end);

                localStorage.setItem('private-mural-shares', JSON.stringify(updatedShares));
                localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
            }
        } catch (error) {
            console.error('Failed to load shares:', error);
        } finally {
            setLoadMoreShares(false);
        }
    }

    async function loadOlderShares() {
        setLoadMoreShares(true);

        const cachedShares = localStorage.getItem('private-mural-shares');
        let lastShare;

        if (cachedShares) {
            const sharesArray = JSON.parse(cachedShares);

            if (Array.isArray(sharesArray) && sharesArray.length > 0) {
                lastShare = sharesArray[sharesArray.length - 1].share;
            } else {
                lastShare = '';
            }
        } else {
            lastShare = '';
        }

        if (lastShare === '') {
            return;
        }

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/older-shares`;

        const queryParams = new URLSearchParams({
            gender: gender,
            style: style,
            lastShare: lastShare
        });

        try {
            const response = await fetch(`${baseURL}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.sharesAvailable) {
                const cachedShares = localStorage.getItem('private-mural-shares');
                const parsedCachedShares = cachedShares ? JSON.parse(cachedShares) : [];

                const updatedShares = [...parsedCachedShares, ...data.shares];

                setSharesData(updatedShares);
                setLoadedSharesCache(updatedShares);

                setEnd(data.end);

                localStorage.setItem('private-mural-shares', JSON.stringify(updatedShares));
                localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
            } else {
                setEnd(true);
                localStorage.setItem('private-mural-shares-end', JSON.stringify(true));
            }

        } catch (error) {
            console.error('Failed to load shares:', error);
        } finally {
            setLoadMoreShares(false);
            setLoadingAllShares(false);
        }
    }

    return (
        <section className='dashboard-wrapper'>
            {bannerActive && (
                <div className='dashboard-banner'>
                    Banner here
                </div>
            )}
            <div className='dashboard-topSide'>
                <div className='dashboard-topSide-title'>
                    <h2>{translations.private_mural_mainTitle}</h2>
                </div>
                <div className='dashboard-topSide-others'>
                    <div className='dashboard-topSide-others-filter'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none" className='dashboard-topSide-others-icon' onClick={() => setFiltersOpen(true)}>
                            <path d="M2 11.5C2 7.02166 2 4.78249 3.39124 3.39124C4.78249 2 7.02166 2 11.5 2C15.9783 2 18.2175 2 19.6088 3.39124C21 4.78249 21 7.02166 21 11.5C21 15.9783 21 18.2175 19.6088 19.6088C18.2175 21 15.9783 21 11.5 21C7.02166 21 4.78249 21 3.39124 19.6088C2 18.2175 2 15.9783 2 11.5Z" stroke="currentColor" stroke-width="1.5" />
                            <path d="M8.5 11.5L14.5001 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.5 15H13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 8H15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h3 onClick={() => setFiltersOpen(true)}>{translations.private_mural_filter}</h3>

                        {filtersOpen && (
                            <MURAL_FILTERS
                                gender={gender}
                                style={style}
                                changeFilters={changeFilters}
                                setFiltersOpen={setFiltersOpen}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='dashboard-content'>
                <div className='dashboard-gallery'>
                    <MURAL_GALLERY
                        language={language}
                        galleryData={loadedSharesCache}
                        setGalleryData={setLoadedSharesCache}
                        loadOlderShares={loadOlderShares}
                        loadingAllShares={loadingAllShares}
                        loadMoreShares={loadMoreShares}
                        noShares={noShares}
                        end={end}
                    />
                </div>
            </div>

        </section>
    );
}

export default Dashboard;