import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import { SharesProvider } from './context/SharesContext';

import AUTH_LOGIN from './hookers/auth/Hooker_Login'
import AUTH_REGISTER from './hookers/auth/Hooker_Register'
import AUTH_FORGOTPASSWORD from './hookers/auth/Hooker_ForgotPassword'

import PUBLIC_LANDING_PAGE from './hookers/public/Hooker_LandingPage'
import PUBLIC_MURAL from './hookers/public/Hooker_Mural'
import PUBLIC_SHARE from './hookers/public/Hooker_Share'
import PUBLIC_PROFILE from './hookers/public/Hooker_Profile'

import PRIVATE_MURAL from './hookers/private/Hooker_Mural'
import PRIVATE_NEWSHARE from './hookers/private/Hooker_NewShare'
import PRIVATE_EDITSHARE from './hookers/private/Hooker_EditShare'
import PRIVATE_SETTINGS from './hookers/private/Hooker_Settings'
import PRIVATE_WARDROBE from './hookers/private/Hooker_Wardrobe'
import PRIVATE_WARDROBE_ITEM from './hookers/private/Hooker_Wardrobe_Item'
import PRIVATE_AI from './hookers/private/Hooker_AI'
import PRIVATE_SUBSCRIPTION from './hookers/private/Hooker_Subscription'

import PAYMENT_CONFIRMATION from './hookers/payments/Hooker_PaymentConfirmation'

import PUBLIC_404 from './hookers/public/Hooker_404'

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <LanguageProvider>
        <SharesProvider>
          <Routes>
            {/* -------------- PUBLIC ROUTES -------------- */}
            <Route path="/" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/:language" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/:language/public/mural" element={<PUBLIC_MURAL />} />
            <Route path="/:language/:author/share/:share" element={<PUBLIC_SHARE />} />
            <Route path="/:author" element={<PUBLIC_PROFILE />} />
            <Route path="/:language/:author" element={<PUBLIC_PROFILE />} />

            {/* -------------- AUTH ROUTES -------------- */}
            <Route path="/:language/login" element={<AUTH_LOGIN />} />
            <Route path="/:language/register" element={<AUTH_REGISTER />} />
            <Route path="/:language/register/:plan" element={<AUTH_REGISTER />} />
            <Route path="/:language/:author/:referralcode" element={<AUTH_REGISTER />} />
            <Route path="/:language/forgot-password" element={<AUTH_FORGOTPASSWORD />} />

            {/* -------------- PRIVATE ROUTES -------------- */}
            <Route path="/:language/account/:author" element={<PRIVATE_MURAL />} />
            <Route path="/:language/account/:author/mural" element={isAuthenticated ? (<PRIVATE_MURAL />) : (<Navigate to="/en/public/mural" replace />)} />
            <Route path="/:language/account/:author/new-share" element={isAuthenticated ? (<PRIVATE_NEWSHARE />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/edit-share/:picture" element={isAuthenticated ? (<PRIVATE_EDITSHARE />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/settings" element={isAuthenticated ? (<PRIVATE_SETTINGS />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/wardrobe" element={isAuthenticated ? (<PRIVATE_WARDROBE />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/wardrobe/:item" element={isAuthenticated ? (<PRIVATE_WARDROBE_ITEM />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/ai/chat" element={isAuthenticated ? (<PRIVATE_AI />) : (<Navigate to="/en/login" replace />)} />
            <Route path="/:language/account/:author/subscription" element={isAuthenticated ? (<PRIVATE_SUBSCRIPTION />) : (<Navigate to="/en/login" replace />)} />

            {/* -------------- PAYMENT ROUTES -------------- */}
            <Route path="/:language/payment/confirmation" element={<PAYMENT_CONFIRMATION />} />

            {/* -------------- DEFAULT ROUTES -------------- */}
            <Route path="/404" element={<PUBLIC_404 />} />
            <Route path="/:language/404" element={<PUBLIC_404 />} />
            <Route path="*" element={<Navigate to="/404" />} />

          </Routes>
        </SharesProvider>
      </LanguageProvider>
    </Router >
  );
}

export default App;