import React, { useState, useEffect } from 'react';
import '../../../style/private/wardrobe/PreferencesForm.css';

function WardrobePreferencesForm({
    preferencesSavedJSON,
    setPreferencesCompleted,
    setPreferencesSavedJSON,
    setMenuOptionsOpen,
    setMenuOpen,
    translations
}) {
    const [personalStyleOptionsChoices, setPersonalStyleOptionsChoices] = useState([]);
    const [personalClothingOptionsChoices, setPersonalClothingOptionsChoices] = useState([]);
    const [personalColorOptionsChoices, setPersonalColorOptionsChoices] = useState([]);
    const [personalFootwearOptionsChoices, setPersonalFootwearOptionsChoices] = useState([]);

    const personalStyleOptionsList = [
        { value: 'casual', label: translations.wardrobe_preferences_stylePreferences_casual },
        { value: 'formal', label: translations.wardrobe_preferences_stylePreferences_formal },
        { value: 'sporty', label: translations.wardrobe_preferences_stylePreferences_sporty },
        { value: 'elegant', label: translations.wardrobe_preferences_stylePreferences_elegant },
    ];
    
    const personalClothingOptionsList = [
        { value: 'jackets', label: translations.wardrobe_preferences_stylePreferences_jackets },
        { value: 'sweatshirts', label: translations.wardrobe_preferences_stylePreferences_sweatshirts },
        { value: 'tshirts', label: translations.wardrobe_preferences_stylePreferences_tshirts },
        { value: 'dresses', label: translations.wardrobe_preferences_stylePreferences_dress },
        { value: 'suits', label: translations.wardrobe_preferences_stylePreferences_suits },
        { value: 'skirts', label: translations.wardrobe_preferences_stylePreferences_skirt },
        { value: 'jeans', label: translations.wardrobe_preferences_stylePreferences_jeans },
        { value: 'pants', label: translations.wardrobe_preferences_stylePreferences_pants },
        { value: 'shorts', label: translations.wardrobe_preferences_stylePreferences_shorts },
        { value: 'leggings', label: translations.wardrobe_preferences_stylePreferences_leggins },
        { value: 'tracksuits', label: translations.wardrobe_preferences_stylePreferences_sportyClothes }
    ];
    
    const personalColorOptionsList = [
        { value: 'dark', label: translations.wardrobe_preferences_stylePreferences_darkColors },
        { value: 'medium', label: translations.wardrobe_preferences_stylePreferences_mediumColors },
        { value: 'light', label: translations.wardrobe_preferences_stylePreferences_lightColors },
        { value: 'neutrals', label: translations.wardrobe_preferences_stylePreferences_neutros },
        { value: 'bright', label: translations.wardrobe_preferences_stylePreferences_vibrantColors },
        { value: 'pastel', label: translations.wardrobe_preferences_stylePreferences_pastelColor },
    ];
    
    const personalFootwearOptionsList = [
        { value: 'sneakers', label: translations.wardrobe_preferences_stylePreferences_tenis },
        { value: 'heels', label: translations.wardrobe_preferences_stylePreferences_highHeel },
        { value: 'loafers', label: translations.wardrobe_preferences_stylePreferences_mocassins },
        { value: 'boots', label: translations.wardrobe_preferences_stylePreferences_boots },
        { value: 'sandals', label: translations.wardrobe_preferences_stylePreferences_sandals },
        { value: 'flats', label: translations.wardrobe_preferences_stylePreferences_rasos },
        { value: 'slippers', label: translations.wardrobe_preferences_stylePreferences_flipflos },
    ];

    useEffect(() => {
        if (preferencesSavedJSON) {
            setPersonalStyleOptionsChoices(preferencesSavedJSON.personalStyleDescription || []);
            setPersonalClothingOptionsChoices(preferencesSavedJSON.confortableClothesForUser || []);
            setPersonalColorOptionsChoices(preferencesSavedJSON.colorPalette || []);
            setPersonalFootwearOptionsChoices(preferencesSavedJSON.confortableFootwearForUser || []);
        }
    }, [preferencesSavedJSON]);

    function savePreferencesIntoJSON() {
        const newUserOptions = {
            personalStyleDescription: personalStyleOptionsChoices,
            confortableClothesForUser: personalClothingOptionsChoices,
            colorPalette: personalColorOptionsChoices,
            confortableFootwearForUser: personalFootwearOptionsChoices,
        };

        const hasPreferences =
            personalStyleOptionsChoices.length > 0 ||
            personalClothingOptionsChoices.length > 0 ||
            personalColorOptionsChoices.length > 0 ||
            personalFootwearOptionsChoices.length > 0;

        setPreferencesCompleted(hasPreferences);
        setPreferencesSavedJSON(newUserOptions);
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    const personalStyleOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setPersonalStyleOptionsChoices((prevChoices) => [...prevChoices, value]);
        } else {
            setPersonalStyleOptionsChoices((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const personalClothingOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setPersonalClothingOptionsChoices((prevChoices) => [...prevChoices, value]);
        } else {
            setPersonalClothingOptionsChoices((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const personalColorOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setPersonalColorOptionsChoices((prevChoices) => [...prevChoices, value]);
        } else {
            setPersonalColorOptionsChoices((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    const personalFootwearOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setPersonalFootwearOptionsChoices((prevChoices) => [...prevChoices, value]);
        } else {
            setPersonalFootwearOptionsChoices((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    function closeMenuWithoutSave() {
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    return (
        <div className='wardrobe-preferences-options-menu'>
            <div className='wardrobe-preferences-options-menu-title'>
                <div className='wardrobe-preferences-options-menu-title-content'>
                    <h3>{translations.wardrobe_preferences_stylePreferences_title}</h3>
                </div>
                <div className='wardrobe-preferences-options-menu-title-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeMenuWithoutSave}>
                        <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                </div>
            </div>
            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_stylePreferences_question1}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {personalStyleOptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-style'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={personalStyleOptions}
                                checked={personalStyleOptionsChoices.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_stylePreferences_question2}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {personalClothingOptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-clothes'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={personalClothingOptions}
                                checked={personalClothingOptionsChoices.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_stylePreferences_question3}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {personalColorOptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-colors'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={personalColorOptions}
                                checked={personalColorOptionsChoices.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_stylePreferences_question4}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {personalFootwearOptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-footwear'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={personalFootwearOptions}
                                checked={personalFootwearOptionsChoices.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>
            <div className='wardrobe-preferences-options-menu-save'>
                <button onClick={savePreferencesIntoJSON} className='wardrobe-preferences-options-menu-save-button'>
                    {translations.wardrobe_preferences_stylePreferences_save}
                </button>
            </div>
        </div>
    );
}

export default WardrobePreferencesForm;
