import { useState, useEffect, useRef } from 'react';
import '../../../style/private/settings/profile.css';

function PROFILE({ language, localStoredUsername, localStoredSession, localStoredName, localStoredSurname, localStoredEmail, localStoredBirthday, localStoredCountry, localStoredGender, translations, MainContext }) {

    const [loadingUpdating, setLoadingUpating] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState('');
    const [errorUpdate, setErrorUpdate] = useState('');

    const [name, setName] = useState(localStoredName);
    const [surname, setSurname] = useState(localStoredSurname);
    const [gender, setGender] = useState(localStoredGender);
    const [birthday, setBirthday] = useState(localStoredBirthday);
    const [country, setCountry] = useState(localStoredCountry);

    const [nameError, setNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [birthdayError, setBirthdayError] = useState('');
    const [countryError, setCountryError] = useState('');

    const [countryOptions, setCountryOptions] = useState([]);
    const previousValueRef = useRef('');

    const [loadingPassword, setLoadingPassword] = useState(false);
    const [successPassword, setSuccessPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCountryList();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const getCountryList = () => {
        const data = new URLSearchParams({
            language: language
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setCountryOptions(data.countries);
            })
            .catch(error => {

            });
    };

    const formatDate = (value) => {
        // Remove non-digit characters
        let cleaned = value.replace(/\D/g, '');

        // Automatically insert dashes as the user types
        if (cleaned.length >= 3 && cleaned.length <= 4) {
            cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2);
        } else if (cleaned.length >= 5) {
            cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2, 4) + '-' + cleaned.slice(4);
        }

        return cleaned.slice(0, 10); // Limit to DD-MM-YYYY format
    };

    const handleChangeBirthday = (e) => {
        setBirthdayError(false);
        const inputValue = e.target.value;

        const formattedDate = formatDate(inputValue);
        const [day, month, year] = formattedDate.split('-').map(Number);

        const isFourDigitYear = year && year.toString().length === 4;

        setBirthday(formattedDate);
        previousValueRef.current = formattedDate;

        const isValidDate =
            (day >= 1 && day <= 31) &&
            (month >= 1 && month <= 12) &&
            isFourDigitYear;

        if (!isValidDate) {
        }
    };

    function updatePersonalInformation(e) {
        e.preventDefault();

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const [day, month, year] = birthday.split('-').map(Number);

        const age = currentYear - year;

        if (age < 18 || age >= 100 || !day || !month || !year) {
            setBirthdayError(true);

            if (name === '') {
                setNameError(true);
            }

            if (surname === '') {
                setSurnameError(true);
            }

            if (gender === '') {
                setGenderError(true);
            }

            if (country === '') {
                setCountryError(true)
            }
            return;
        } else if (name === '' || surname === '' || gender === '' || country === '') {
            if (name === '') {
                setNameError(true);
            }

            if (surname === '') {
                setSurnameError(true);
            }

            if (gender === '') {
                setGenderError(true);
            }

            if (country === '') {
                setCountryError(true)
            }
            return;
        }

        setErrorUpdate('');
        setSuccessUpdate('');
        updateUserInformation();

    };

    const updateUserInformation = () => {
        setLoadingUpating(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/information`;

        const formData = new URLSearchParams({
            name: name,
            surname: surname,
            gender: gender,
            birthday: birthday,
            country: country
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoadingUpating(false);
                    setErrorUpdate(translations.private_profile_personalInformation_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                localStorage.setItem('name', name);
                localStorage.setItem('surname', surname);
                localStorage.setItem('gender', gender);
                localStorage.setItem('birthday', birthday);
                localStorage.setItem('country', country);
                setLoadingUpating(false);
                setSuccessUpdate(translations.private_profile_personalInformation_informationUpdateWithSuccess);
            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkPasswordStrength();
        checkRepeatPasswordStrength();
    }, [password, repeatPassword]);
    /* eslint-disable react-hooks/exhaustive-deps */

    function checkPasswordStrength() {
        const password = document.getElementById('password-input').value;
        const bars = [
            document.getElementById('register-step2-bar1'),
            document.getElementById('register-step2-bar2'),
            document.getElementById('register-step2-bar3'),
            document.getElementById('register-step2-bar4')
        ];
        const message = document.getElementById('register-step2-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = '#B29361';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.fontWeight = '400';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';
        message.style.fontFamily = 'Merriweather", serif'
        message.style.fontWeight = '400'
        message.style.fontStyle = 'normal'

        let strength = 0;
        if (password.length > 5) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[@$!%*?&#]/.test(password)) strength++;

        if (password.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#62A29A';
                    bars[1].style.backgroundColor = '#62A29A';
                    message.textContent = translations.register_step2_passwordOK;
                    message.style.color = '#62A29A';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = '#32745C';
                    bars[1].style.backgroundColor = '#32745C';
                    bars[2].style.backgroundColor = '#32745C';
                    message.textContent = translations.register_step2_passwordGood;
                    message.style.color = '#32745C';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = '#10443E';
                    bars[1].style.backgroundColor = '#10443E';
                    bars[2].style.backgroundColor = '#10443E';
                    bars[3].style.backgroundColor = '#10443E';
                    message.textContent = translations.register_step2_passwordStrong;
                    message.style.color = '#10443E';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.register_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontFamily = 'Merriweather", serif'
            message.style.fontWeight = '400'
            message.style.fontStyle = 'normal'
        }
    }

    function checkRepeatPasswordStrength() {
        const repeatPassword = document.getElementById('repeat-password-input').value;
        const bars = [
            document.getElementById('register-step2-repeat-bar1'),
            document.getElementById('register-step2-repeat-bar2'),
            document.getElementById('register-step2-repeat-bar3'),
            document.getElementById('register-step2-repeat-bar4')
        ];
        const message = document.getElementById('register-step2-repeat-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = '#B29361';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';
        message.style.fontFamily = 'Merriweather", serif'
        message.style.fontWeight = '400'
        message.style.fontStyle = 'normal'

        let strength = 0;
        if (repeatPassword.length > 5) strength++;
        if (/[A-Z]/.test(repeatPassword)) strength++;
        if (/[0-9]/.test(repeatPassword)) strength++;
        if (/[@$!%*?&#]/.test(repeatPassword)) strength++;

        if (repeatPassword.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#62A29A';
                    bars[1].style.backgroundColor = '#62A29A';
                    message.textContent = translations.register_step2_passwordOK;
                    message.style.color = '#62A29A';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = '#32745C';
                    bars[1].style.backgroundColor = '#32745C';
                    bars[2].style.backgroundColor = '#32745C';
                    message.textContent = translations.register_step2_passwordGood;
                    message.style.color = '#32745C';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = '#10443E';
                    bars[1].style.backgroundColor = '#10443E';
                    bars[2].style.backgroundColor = '#10443E';
                    bars[3].style.backgroundColor = '#10443E';
                    message.textContent = translations.register_step2_passwordStrong;
                    message.style.color = '#10443E';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.register_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontFamily = 'Merriweather", serif'
            message.style.fontWeight = '400'
            message.style.fontStyle = 'normal'
        }
    }

    const updatePassword = (e) => {
        e.preventDefault();

        if(oldPassword ==='' || password === '' || repeatPassword === '') {
            return;
        }

        setSuccessPassword('');
        setErrorPassword('');
        setLoadingPassword(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/password`;

        const formData = new URLSearchParams({
            oldPassword: oldPassword,
            newPassword: password,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoadingPassword(false);
                    setErrorPassword(translations.private_profile_changePassword_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setErrorPassword('');
                    setLoadingPassword(false);
                    setSuccessPassword(translations.private_profile_changePassword_successChange);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    };

    return (
        <section className='myAccount-settings-profile-wrapper'>
            <div className='myAccount-settings-profile-wrapper-content'>

                <div className='myAccount-settings-profile-container'>
                    <div className='myAccount-settings-profile-container-title'>
                        <h3>{translations.private_settingsAccount_title}</h3>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step2_email}</span>
                            <div className="myAccount-settings-profile-container-option-tooltip">
                                <div className="myAccount-settings-profile-container-option-icon">i</div>
                                <div className="myAccount-settings-profile-container-option-tooltiptext">{translations.private_settingsAccount_changeEmailTooltip}</div>
                            </div>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input type='text' value={localStoredEmail} disabled />
                        </div>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step1_name}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder={translations.register_step1_name}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError('');
                                }}
                            />
                        </div>

                        {nameError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{nameError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step1_surname}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder={translations.register_step1_surname}
                                value={surname}
                                onChange={(e) => {
                                    setSurname(e.target.value);
                                    setSurnameError('');
                                }}
                            />
                        </div>
                        {surnameError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{surnameError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step1_selectGender}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <select
                                value={gender}
                                onChange={(e) => {
                                    setGender(e.target.value);
                                    setGenderError('');
                                }}>
                                <option value='' disabled selected>{translations.register_step1_selectGender}</option>
                                <option value='man'>{translations.register_step1_genderMan}</option>
                                <option value='woman'>{translations.register_step1_genderWoman}</option>
                            </select>
                        </div>
                        {genderError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{genderError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step1_birthday}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='text'
                                placeholder='DD-MM-YYYY'
                                value={birthday}
                                onChange={handleChangeBirthday}
                                pattern='(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[0-2])-(?:19|20)[0-9]{2}'
                            />
                        </div>
                        {birthdayError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{birthdayError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.register_step1_selectCountry}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                    setCountryError('');
                                }}>
                                <option value='' disabled selected>{translations.register_step1_selectCountry}</option>
                                {countryOptions.map((nationality, index) => (
                                    <option key={index} value={nationality.value}>
                                        {nationality.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {countryError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{countryError}</p>
                            </div>
                        )}
                    </div>

                    <button disabled={loadingUpdating} onClick={updatePersonalInformation}>
                        {loadingUpdating ? (
                            <span className="loading-dots">
                                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            translations.private_profile_personalInformation_change
                        )}
                    </button>

                    {successUpdate !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-success'>
                            <p>{successUpdate}</p>
                        </div>
                    )}

                    {errorUpdate !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-error'>
                            <p>{errorUpdate}</p>
                        </div>
                    )}

                </div>

                <div className='myAccount-settings-profile-container'>
                    <div className='myAccount-settings-profile-container-title'>
                        <h3>{translations.private_profile_changePassword_title}</h3>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.private_profile_changePassword_oldPassword}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                        <input
                                type='password'
                                placeholder={translations.register_step2_repeatPassword}
                                value={oldPassword}
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.private_profile_changePassword_newPassword}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='password'
                                id='password-input'
                                placeholder={translations.register_step2_password}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError('');
                                }}
                            />
                        </div>
                        <div className='register-step2-password-strength-bars'>
                            <div className='register-step2-strength-bar' id='register-step2-bar1'></div>
                            <div className='register-step2-strength-bar' id='register-step2-bar2'></div>
                            <div className='register-step2-strength-bar' id='register-step2-bar3'></div>
                            <div className='register-step2-strength-bar' id='register-step2-bar4'></div>
                        </div>
                        <div id='register-step2-password-strength-message' className='register-step2-password-strength-message' />

                        {passwordError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{passwordError}</p>
                            </div>
                        )}
                    </div>

                    <div className='myAccount-settings-profile-container-option'>
                        <div className='myAccount-settings-profile-container-option-title'>
                            <span>{translations.private_profile_changePassword_repeatNewPassword}</span>
                        </div>
                        <div className='myAccount-settings-profile-container-option-container'>
                            <input
                                type='password'
                                id='repeat-password-input'
                                placeholder={translations.register_step2_repeatPassword}
                                value={repeatPassword}
                                onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                    setRepeatPasswordError('');
                                }}
                            />
                        </div>
                        <div className='register-step2-password-strength-bars'>
                            <div className='register-step2-strength-bar' id='register-step2-repeat-bar1'></div>
                            <div className='register-step2-strength-bar' id='register-step2-repeat-bar2'></div>
                            <div className='register-step2-strength-bar' id='register-step2-repeat-bar3'></div>
                            <div className='register-step2-strength-bar' id='register-step2-repeat-bar4'></div>
                        </div>
                        <div id='register-step2-repeat-password-strength-message' className='register-step2-password-strength-message' />

                        {repeatPasswordError !== '' && (
                            <div className='myAccount-settings-profile-container-option-container-error'>
                                <p>{repeatPasswordError}</p>
                            </div>
                        )}
                    </div>

                    <button disabled={loadingPassword} onClick={updatePassword}>
                        {loadingPassword ? (
                            <span className="loading-dots">
                                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            translations.private_profile_personalInformation_change
                        )}
                    </button>

                    {successPassword !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-success'>
                            <p>{successPassword}</p>
                        </div>
                    )}

                    {errorPassword !== '' && (
                        <div className='myAccount-settings-profile-container-option-container-error'>
                            <p>{errorPassword}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default PROFILE;
