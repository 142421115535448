import React, { useRef } from 'react';
import '../../../style/auth/register.css'

function Step3({
    email,
    plan,
    language,
    loading,
    setLoading,
    MainContext,
    setRegistrationStep,
    translations
}) {

    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Handle single character input
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        // Handle paste event for multiple characters
        if (value.length > 1) {
            const characters = value.split('');
            characters.forEach((char, i) => {
                if (inputRefs.current[i]) {
                    inputRefs.current[i].value = char; // Populate input fields
                }
            });

            // Move focus to the last filled input
            if (characters.length < 6) {
                inputRefs.current[characters.length].focus();
            }
        }
    };

    const handlePaste = (e, index) => {
        e.preventDefault(); // Prevent default paste behavior
        const pastedData = e.clipboardData.getData('text'); // Get pasted text
        if (pastedData.length <= 6) {
            pastedData.split('').forEach((char, i) => {
                if (inputRefs.current[i]) {
                    inputRefs.current[i].value = char; // Populate input fields
                }
            });

            // Move focus to the next available input
            const nextIndex = Math.min(pastedData.length, 5); // Don't go past the last input
            inputRefs.current[nextIndex].focus();
        }
    };

    const getFullToken = () => {
        return inputRefs.current.reduce((acc, input) => acc + (input ? input.value : ''), '');
    };

    const activateAccount = (e) => {
        e.preventDefault();

        const token = getFullToken();
        if (token.length < 6) {
            return;
        }

        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/activate-account`;

        const formData = new URLSearchParams({
            email: email,
            securityToken: token
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    prepareToCreateSession();
                }
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
            });
    };

    async function prepareToCreateSession() {
        if (plan === 'plus') {
            await createCheckoutSession('plus');
        } else if (plan === 'premium') {
            await createCheckoutSession('premium');
        } else {
            setLoading(false);
            return setRegistrationStep(4);
        }
    }

    const createCheckoutSession = async (plan) => {

        const baseURL = `${MainContext.apiURL}/api/v1/public/users/pay/subscription`;

        const formData = new URLSearchParams({
            email: email,
            page: 'new',
            language: language,
            plan: plan
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const currentTimestampUTC = new Date().toISOString();

                const paymentIntentObject = {
                    timestamp: currentTimestampUTC
                };

                localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
                localStorage.setItem('subscription-payment-intent-plan', plan);

                setLoading(false);

                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
            });
    };

    return (
        <div className='register-form-activate-account-wrapper'>
            <div className='register-form-activate-title'>
                <p>{translations.register_step3_message1}</p>
                <span>{translations.register_step3_message2}</span>
                <span>{translations.register_step3_message3}</span>
            </div>

            <div className='register-form-activate-code'>
                {[...Array(6)].map((_, index) => (
                    <input
                        key={index}
                        type='text'
                        maxLength='1'
                        onChange={(e) => handleChange(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        className='code-input'
                        ref={(el) => (inputRefs.current[index] = el)}
                    />
                ))}
            </div>
            <div className='register-form-options-components-button'>
                <button disabled={loading} onClick={activateAccount}>
                    {loading ? (
                        <span className="loading-dots">
                            {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                        </span>
                    ) : (
                        <>
                            {plan === 'premium' || plan === 'plus' ? (
                                translations.register_step3_activateAndPay
                            ) : (
                                translations.register_step3_activateAccount
                        )}
                        </>
                    )}
                </button>
            </div>
        </div>
    );
}

export default Step3;