import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import PreferencesForm from './wardrobe/PreferencesForm'
import WardrobeOptions from './wardrobe/Wardrobe'
import '../../style/private/wardrobe.css';

function Wardrobe() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [pageOpen, setPageOpen] = useState('');
    const { getSessionInfo } = useAuth();
    const { localStoredWardrobe } = getSessionInfo();
    const [wardrobe, setWardrobe] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        if (localStoredWardrobe) {
            setWardrobe(JSON.parse(localStoredWardrobe));
        } else {
            setWardrobe(null);
        }

        const subscriptionStored = localStorage.getItem('subscription');

        if (subscriptionStored === 'premium') {
            const wardrobeItem = localStorage.getItem('wardrobe-new-subscriber-wardrobe');

            if (wardrobeItem) {
                localStorage.removeItem('wardrobe-new-subscriber-wardrobe');
                setPageOpen('preferences-form');
            } else {
                setPageOpen('wardrobe');
            }
        } else {
            setPageOpen('wardrobe');
        }
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    return (
        <section className='warbrobe-content'>
            {pageOpen === 'preferences-form' && (
                <PreferencesForm
                    setPageOpen={setPageOpen}
                    wardrobe={wardrobe}
                    language={language}
                    translations={translations}
                />
            )}
            {pageOpen === 'wardrobe' && (
                <WardrobeOptions
                    setPageOpen={setPageOpen}
                    wardrobe={wardrobe}
                />
            )}
        </section >
    );
}

export default Wardrobe;
