import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation();

  const getLanguageFromPath = (pathname) => {
    localStorage.setItem('language', pathname.startsWith('/pt') ? 'pt' : pathname.startsWith('/en') ? 'en' : null);
    return pathname.startsWith('/pt') ? 'pt' : pathname.startsWith('/en') ? 'en' : null;
  };

  const [language, setLanguage] = useState(getLanguageFromPath(location.pathname));
  const [userCountry, setUserCountry] = useState(null);
  const [loading, setLoading] = useState(true);

  const browserLanguage = (navigator.language || navigator.userLanguage || '').toLowerCase();

  const fetchUserCountry = async () => {
    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ip = ipData.ip;

      const geoResponse = await fetch(`http://ip-api.com/json/${ip}`);
      const geoData = await geoResponse.json();

      if (geoData.countryCode === 'PT' && browserLanguage.startsWith('pt')) {
        localStorage.setItem('language', 'pt');
        setUserCountry('pt');
      } else {
        localStorage.setItem('language', 'en');
        setUserCountry('en');
      }
    } catch (error) {
      localStorage.setItem('language', 'en');
      setUserCountry('en');
    } finally {
      setLoading(false);
    }
  };

  const setLanguageBasedOnCountry = (country) => {
    let t_language;

    if(country === 'pt') {
      t_language = 'pt';
    } else {
      t_language = 'en'
    }
    localStorage.setItem('language', t_language);
    return t_language;
  };

  const toggleLanguage = (v_language) => {
    const currentPath = location.pathname.replace(/^\/(en|pt)/, '');

    let newPath;
    if(v_language === 'pt') {
      localStorage.setItem('language', 'pt');
      newPath = `/pt${currentPath}`
    } else {
      localStorage.setItem('language', 'en');
      newPath = `/en${currentPath}`
    }
    window.location.href = newPath;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (language === null) {
      fetchUserCountry();
    }
  }, [language]);
  /* eslint-disable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentLanguage = getLanguageFromPath(location.pathname);

    if (!currentLanguage && userCountry && !loading) {
      const newLanguage = setLanguageBasedOnCountry(userCountry);
      const currentPath = location.pathname.replace(/^\/(en|pt)/, '');
      const newPath = `/${newLanguage}${currentPath}`;
      window.location.href = newPath;
    }

    if (currentLanguage && currentLanguage !== language) {
      localStorage.setItem('language', currentLanguage);
      setLanguage(currentLanguage);
    }
  }, [location.pathname, userCountry, language, loading]);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
