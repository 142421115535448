import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../context/AuthContext';
import HOOKER_MENU_LANDINGPAGE from '../../pages/public/landingPage/MenuTop'
import MENUTOP from '../../pages/private/MenuTop';
import HOOKER_PROFILE from '../../pages/public/Profile'
import HOOKER_FOOTER from '../../pages/public/landingPage/MenuFooter'
import '../../style/public/common.css';

const HOOKERMURAL = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            <section className='common-public-wrapper'>
            <Helmet>
                <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                </script> {/* -------- MICROSOFT CLARITY -------- */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                </script> {/* -------- GOOGLE ANALYTICS -------- */}
            </Helmet>
            {isAuthenticated ? (
                    <MENUTOP />
                ) : (
                    <HOOKER_MENU_LANDINGPAGE />
                )}
                <HOOKER_PROFILE />
                <HOOKER_FOOTER />
            </section>
        </>
    );
};

export default HOOKERMURAL;
