import React from 'react';
import { Helmet } from 'react-helmet';
import MENUTOP from '../../pages/private/MenuTop';
import Mural from '../../pages/private/Mural'
import '../../style/private/common.css';

const HOOKERPRIVATEDASHBOARD = () => {

    return (
        <>
            <section className='private-common-wrapper'>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>
                <div className='private-common-topMenu'>
                    <MENUTOP />
                </div>
                <div className='private-common-content'>
                    <Mural />
                </div>
            </section>
        </>
    );
};

export default HOOKERPRIVATEDASHBOARD;
