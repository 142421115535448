import React, { useState, useEffect } from 'react';
import '../../../style/private/wardrobe/PreferencesForm.css';

function WardrobePreferencesForm({ 
    preferencesSavedJSON,
    setPreferencesCompleted, 
    setPreferencesSavedJSON, 
    setMenuOptionsOpen, 
    setMenuOpen,
    translations
}) {
    const [option1, setOption1] = useState([]);

    const option1OptionsList = [
        { value: 'very_low', label: translations.wardrobe_preferences_budgetPreferences_veryLow },
        { value: 'low', label: translations.wardrobe_preferences_budgetPreferences_low },
        { value: 'moderate', label: translations.wardrobe_preferences_budgetPreferences_moderate },
        { value: 'high', label: translations.wardrobe_preferences_budgetPreferences_high },
        { value: 'very_high', label: translations.wardrobe_preferences_budgetPreferences_veryHigh },
    ];

    useEffect(() => {
        if (preferencesSavedJSON) {
            setOption1(preferencesSavedJSON.budget || []);
        }
    }, [preferencesSavedJSON]);

    function savePreferencesIntoJSON() {
        const newUserOptions = {
            budget: option1
        };

        const hasPreferences =
            option1.length > 0;

        setPreferencesCompleted(hasPreferences);
        setPreferencesSavedJSON(newUserOptions);
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    const option1Options = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setOption1((prevChoices) => [...prevChoices, value]);
        } else {
            setOption1((prevChoices) =>
                prevChoices.filter((style) => style !== value)
            );
        }
    };

    function closeMenuWithoutSave(){
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    return (
        <div className='wardrobe-preferences-options-menu'>
            <div className='wardrobe-preferences-options-menu-title'>
                <div className='wardrobe-preferences-options-menu-title-content'>
                    <h3>{translations.wardrobe_preferences_budgetPreferences_title}</h3>
                </div>
                <div className='wardrobe-preferences-options-menu-title-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeMenuWithoutSave}>
                        <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                </div>
            </div>
            <div className='wardrobe-preferences-options-menu-options'>
            <p>{translations.wardrobe_preferences_budgetPreferences_question1}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option1OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-style'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={option1Options}
                                checked={option1.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-save'>
                <button onClick={savePreferencesIntoJSON} className='wardrobe-preferences-options-menu-save-button'>
                    {translations.wardrobe_preferences_budgetPreferences_save}
                </button>
            </div>
        </div>
    );
}

export default WardrobePreferencesForm;
