import React, { useState } from 'react';
import '../../../style/private/newshare_properties.css'

const translations1 = {
    en: {
        // Clothing
        jacket: 'Jacket',
        blazer: 'Blazer',
        vest: 'Vest',
        sweater: 'Sweater',
        blouse: 'Blouse',
        dress: 'Dress',
        polo: 'Polo',
        shirt: 'Shirt',
        tshirt: 'T-shirt',
        pants: 'Pants',
        jeans: 'Jeans',
        shorts: 'Shorts',
        skirt: 'Skirt',
        pijama: 'Pajama',
        swimwear: 'Swimwear',
        boxers: 'Boxers',
        bra: 'Bra',
        panties: 'Panties',
        socks: 'Socks',
        other: 'Other',

        // Accessories
        cap: 'Cap',
        hat: 'Hat',
        gloves: 'Gloves',
        scarf: 'Scarf',
        watch: 'Watch',
        belt: 'Belt',
        handbag: 'Handbag',
        necklace: 'Necklace',
        ring: 'Ring',
        earrings: 'Earrings',
        bag: 'Bag',
        bagpack: 'Backpack',
        tie: 'Tie',
        bowtie: 'Bowtie',
        bracelet: 'Bracelet',
        wallet: 'Wallet',
        glasses: 'Glasses',

        // Footwear
        shoes: 'Shoes',
        sneakers: 'Sneakers',
        sandals: 'Sandals',
        mocassins: 'Mocassins',
        boots: 'Boots',
        ankleboots: 'Ankle Boots',
        highheels: 'High Heels',
        elegantshoes: 'Elegant Shoes',
        flipflops: 'Flip-flops',
        slippers: 'Slippers'
    },

    pt: {
        // Clothing
        jacket: 'Casaco',
        blazer: 'Blazer',
        vest: 'Colete',
        sweater: 'Camisola',
        blouse: 'Blusa',
        dress: 'Vestido',
        polo: 'Polo',
        shirt: 'Camisa',
        tshirt: 'T-shirt',
        pants: 'Calças',
        jeans: 'Calças de Ganga',
        shorts: 'Calções',
        skirt: 'Saia',
        pijama: 'Pijama',
        swimwear: 'Fato de banho',
        boxers: 'Boxers',
        bra: 'Sutiã',
        panties: 'Cuecas',
        socks: 'Meias',
        other: 'Outro',

        // Accessories
        cap: 'Boné',
        hat: 'Chapéu',
        gloves: 'Luvas',
        scarf: 'Cachecol',
        watch: 'Relógio',
        belt: 'Cinto',
        handbag: 'Mala',
        necklace: 'Colar',
        ring: 'Anel',
        earrings: 'Brincos',
        bag: 'Saco',
        bagpack: 'Mochila',
        tie: 'Gravata',
        bowtie: 'Laço',
        bracelet: 'Pulseira',
        wallet: 'Carteira',
        glasses: 'Óculos',

        // Footwear
        shoes: 'Sapatos',
        sneakers: 'Ténis',
        sandals: 'Sandálias',
        mocassins: 'Mocassins',
        boots: 'Botas',
        ankleboots: 'Botins',
        highheels: 'Saltos Altos',
        elegantshoes: 'Sapatos Elegantes',
        flipflops: 'Chinelos',
        slippers: 'Pantufas'
    }
};

function PropertiesNewShare({ gender, setGender, style, setStyle, otherOptions, setOtherOptions, clothing, setClothing, accessories, setAccessories, footwear, setFootwear, language, translations, loading, isDisabledSaveButton, submitShare }) {
    const [addingNewClothingReference, setAddingNewClothingReference] = useState(false);
    const [addingNewAccessoriesReference, setAddingNewAccessoriesReference] = useState(false);
    const [addingNewFootwearReference, setAddingNewFootwearReference] = useState(false);

    const handleDeleteReference = (object, itemToDelete) => {
        if (object === 'clothing') {
            setClothing((prevClothing) => ({
                ...prevClothing,
                items: {
                    ...prevClothing.items,
                    [itemToDelete]: ''
                },
                count: prevClothing.count > 0 ? prevClothing.count - 1 : 0
            }));
        } else if (object === 'accessories') {
            setAccessories((prevAccessory) => ({
                ...prevAccessory,
                items: {
                    ...prevAccessory.items,
                    [itemToDelete]: ''
                },
                count: prevAccessory.count > 0 ? prevAccessory.count - 1 : 0
            }));
        } else if (object === 'footwear') {
            setFootwear((prevFootwear) => ({
                ...prevFootwear,
                items: {
                    ...prevFootwear.items,
                    [itemToDelete]: ''
                },
                count: prevFootwear.count > 0 ? prevFootwear.count - 1 : 0
            }));
        }
    };

    const [menuOpen, setMenuOpen] = useState(false);
    const [copyrights, setCopyrights] = useState(otherOptions?.copyrights || '');

    function changeStateOfMenu() {
        if (menuOpen) {
            document.body.style.overflow = '';
        } else {
            document.body.style.overflow = 'hidden';
            
            // Scroll to the top of the page when opening the menu
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        
        setMenuOpen(!menuOpen);
    }
    

    function saveOtherOptions() {
        setOtherOptions({
            copyrights: copyrights
        });
        setMenuOpen(false);
    }

    return (
        <section className='newshare-properties-wrapper'>
            <div className='newshare-properties-ai'>
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M10.9381 11.0774L3.41101 18.6045C2.863 19.1525 2.863 20.041 3.41101 20.589C3.95902 21.137 4.84752 21.137 5.39553 20.589L12.9226 13.0619M10.9381 11.0774L12.9226 13.0619M10.9381 11.0774L11.6823 10.3332M12.9226 13.0619L13.6668 12.3177M11.6823 10.3332L11.7248 10.2906C12.1124 9.90313 12.7406 9.90313 13.1281 10.2906L13.7094 10.8719C14.0969 11.2594 14.0969 11.8876 13.7094 12.2751L13.6668 12.3177M11.6823 10.3332L13.6668 12.3177" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M18.2377 3.16707C18.3416 2.94431 18.6584 2.94431 18.7623 3.16707L19.1541 4.00647C19.3266 4.37618 19.6238 4.67336 19.9935 4.84591L20.8329 5.23766C21.0557 5.34162 21.0557 5.65838 20.8329 5.76234L19.9935 6.15409C19.6238 6.32664 19.3266 6.62381 19.1541 6.99353L18.7623 7.83293C18.6584 8.05569 18.3416 8.05569 18.2377 7.83293L17.8459 6.99353C17.6734 6.62381 17.3762 6.32664 17.0065 6.15409L16.1671 5.76234C15.9443 5.65838 15.9443 5.34162 16.1671 5.23766L17.0065 4.84591C17.3762 4.67336 17.6734 4.37618 17.8459 4.00647L18.2377 3.16707Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M18.2377 14.1671C18.3416 13.9443 18.6584 13.9443 18.7623 14.1671L19.1541 15.0065C19.3266 15.3762 19.6238 15.6734 19.9935 15.8459L20.8329 16.2377C21.0557 16.3416 21.0557 16.6584 20.8329 16.7623L19.9935 17.1541C19.6238 17.3266 19.3266 17.6238 19.1541 17.9935L18.7623 18.8329C18.6584 19.0557 18.3416 19.0557 18.2377 18.8329L17.8459 17.9935C17.6734 17.6238 17.3762 17.3266 17.0065 17.1541L16.1671 16.7623C15.9443 16.6584 15.9443 16.3416 16.1671 16.2377L17.0065 15.8459C17.3762 15.6734 17.6734 15.3762 17.8459 15.0065L18.2377 14.1671Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M7.23766 3.16707C7.34162 2.94431 7.65838 2.94431 7.76234 3.16707L8.15409 4.00647C8.32664 4.37618 8.62381 4.67336 8.99353 4.84591L9.83293 5.23766C10.0557 5.34162 10.0557 5.65838 9.83293 5.76234L8.99353 6.15409C8.62381 6.32664 8.32664 6.62381 8.15409 6.99353L7.76234 7.83293C7.65838 8.05569 7.34162 8.05569 7.23766 7.83293L6.84591 6.99353C6.67336 6.62381 6.37618 6.32664 6.00647 6.15409L5.16707 5.76234C4.94431 5.65838 4.94431 5.34162 5.16707 5.23766L6.00647 4.84591C6.37618 4.67336 6.67336 4.37618 6.84591 4.00647L7.23766 3.16707Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                    </svg>
                    <p>{translations.private_newShare_propierties_analyzeWithAI}</p>

                    <div className='newshare-properties-ai-soon'>
                        <span>{translations.private_newShare_propierties_comingSoon}</span>
                    </div>
                </button>
                <div className='newshare-properties-ai-separator' />
            </div>

            <div className='newShare-properties-targets'>
                <div className='newShare-properties-targets-title'>
                    <h3>{translations.private_newShare_propierties_whoIsTargetAudience}</h3>
                </div>

                <div className='newShare-properties-targets-wrapper'>

                    <div className='newShare-properties-targets-option'>
                        <label>
                            {translations.editShare_proprierties_content_gender}
                            <div class="newShare-properties-targets-option-tooltip">
                                <div class="newShare-properties-targets-option-icon">i</div>
                                <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_content_genderTooltip}</div>
                            </div>
                        </label>
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}>
                            <option value='' disabled selected>{translations.editShare_proprierties_content_selectGender}</option>
                            <option value='man'>{translations.editShare_proprierties_content_genderMan}</option>
                            <option value='woman'>{translations.editShare_proprierties_content_genderWoman}</option>
                        </select>
                    </div>

                    <div className='newShare-properties-targets-option'>
                        <label>
                            {translations.editShare_proprierties_content_style}
                            <div class="newShare-properties-targets-option-tooltip">
                                <div class="newShare-properties-targets-option-icon">i</div>
                                <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_content_styleTooltip}</div>
                            </div>
                        </label>
                        <select
                            value={style}
                            onChange={(e) => setStyle(e.target.value)}>
                            <option value='' disabled selected>{translations.editShare_proprierties_content_selectStyle}</option>
                            <option value='casual'>{translations.editShare_proprierties_content_styleCasual}</option>
                            <option value='formal'>{translations.editShare_proprierties_content_styleFormal}</option>
                            <option value='sporty'>{translations.editShare_proprierties_content_styleSporty}</option>
                            <option value='elegant'>{translations.editShare_proprierties_content_styleElegant}</option>
                            <option value='vintage'>{translations.editShare_proprierties_content_styleVintage}</option>
                        </select>
                    </div>

                    <div className='newShare-properties-targets-option'>
                        <label>
                            {translations.editShare_proprierties_content_otherOptions}
                            <div class="newShare-properties-targets-option-tooltip">
                                <div class="newShare-properties-targets-option-icon">i</div>
                                <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_content_otherOptionsTooltip}</div>
                            </div>
                        </label>
                        <button onClick={changeStateOfMenu}>{translations.editShare_proprierties_content_otherOptions}</button>
                    </div>

                </div>
            </div>

            <div className='newShare-properties-references'>
                <div className='newShare-properties-references-title'>
                    <h3>{translations.private_newShare_propierties_referencesTitle}</h3>
                    <div class="newShare-properties-targets-option-tooltip">
                        <div class="newShare-properties-targets-option-icon">i</div>
                        <div class="newShare-properties-targets-option-tooltiptext">{translations.private_newShare_propierties_provideUsWithURL}</div>
                    </div>
                </div>

                <div className='newShare-properties-references-wrapper'>
                    <div className='newShare-properties-references-subtitle'>
                        <h4>{translations.public_share_piecesClothes}</h4>
                    </div>

                    <div className='newshare-references-subreferences-content-grid'>
                        {Object.entries(clothing.items)
                            .filter(([key, value]) => value !== '')
                            .map(([item, value], index) => (
                                <a
                                    href={value.startsWith('http') ? value : `https://${value}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <button>
                                        <img src={`/references/icon-${item}.png`} alt={item} />
                                        <span>{translations1[language][item]}</span>
                                        <div className='newshare-reference-subreference-delete' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeleteReference('clothing', item); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M15 9L9 14.9996M15 15L9 9.00039" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                                            </svg>
                                        </div>
                                    </button>
                                </a>
                            ))}
                    </div>
                    <div className='newshare-references-subreferences-content-newreference'>
                        {addingNewClothingReference ? (
                            <NewShareButtonNewReference
                                category={clothing}
                                setCategory={setClothing}
                                closeModal={setAddingNewClothingReference}
                                translations1={translations1}
                                language={language}
                                translations={translations}
                            />

                        ) : (
                            <div className='newreference-container'>
                                <div className='newreference-container-addnew' onClick={() => setAddingNewClothingReference(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
                                    </svg>
                                    <span>{translations.editShare_proprierties_addPieceOfClothes}</span>
                                </div>
                            </div>
                        )}

                    </div>


                </div>

                <div className='newShare-properties-references-wrapper'>
                    <div className='newShare-properties-references-subtitle'>
                        <h4>{translations.public_share_piecesAccessories}</h4>
                    </div>

                    <div className='newshare-references-subreferences-content-grid'>
                        {Object.entries(accessories.items)
                            .filter(([key, value]) => value !== '')
                            .map(([item, value], index) => (
                                <a
                                    href={value.startsWith('http') ? value : `https://${value}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <button>
                                        <img src={`/references/icon-${item}.png`} alt={item} />
                                        <span>{translations1[language][item]}</span>
                                        <div className='newshare-reference-subreference-delete' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeleteReference('accessories', item); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M15 9L9 14.9996M15 15L9 9.00039" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                                            </svg>
                                        </div>
                                    </button>
                                </a>
                            ))}
                    </div>
                    <div className='newshare-references-subreferences-content-newreference'>
                        {addingNewAccessoriesReference ? (
                            <NewShareButtonNewReference
                                category={accessories}
                                setCategory={setAccessories}
                                closeModal={setAddingNewAccessoriesReference}
                                translations1={translations1}
                                language={language}
                                translations={translations}
                            />

                        ) : (
                            <div className='newreference-container'>
                                <div className='newreference-container-addnew' onClick={() => setAddingNewAccessoriesReference(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
                                    </svg>
                                    <span>{translations.editShare_proprierties_addAccessories}</span>
                                </div>
                            </div>
                        )}

                    </div>


                </div>

                <div className='newShare-properties-references-wrapper'>
                    <div className='newShare-properties-references-subtitle'>
                        <h4>{translations.public_share_piecesFootwear}</h4>
                    </div>

                    <div className='newshare-references-subreferences-content-grid'>
                        {Object.entries(footwear.items)
                            .filter(([key, value]) => value !== '')
                            .map(([item, value], index) => (
                                <a
                                    href={value.startsWith('http') ? value : `https://${value}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <button>
                                        <img src={`/references/icon-${item}.png`} alt={item} />
                                        <span>{translations1[language][item]}</span>
                                        <div className='newshare-reference-subreference-delete' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeleteReference('footwear', item); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M15 9L9 14.9996M15 15L9 9.00039" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                                            </svg>
                                        </div>
                                    </button>
                                </a>
                            ))}
                    </div>

                    <div className='newshare-references-subreferences-content-newreference'>
                        {addingNewFootwearReference ? (
                            <NewShareButtonNewReference
                                category={footwear}
                                setCategory={setFootwear}
                                closeModal={setAddingNewFootwearReference}
                                translations1={translations1}
                                language={language}
                                translations={translations}
                            />

                        ) : (
                            <div className='newreference-container'>
                                <div className='newreference-container-addnew' onClick={() => setAddingNewFootwearReference(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
                                    </svg>
                                    <span>{translations.editShare_proprierties_addFootwear}</span>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <div className='newshare-references-save'>
                {loading ? (
                    <button disabled>{translations.editShare_proprierties_holdOn}</button>
                ) : (
                    <button onClick={submitShare} disabled={!isDisabledSaveButton}>{translations.editShare_proprierties_publish}</button>
                )}
            </div>


            {menuOpen && (
                <div className='newshare-properties-newoptions-wrapper-background'>
                    <div className='newshare-properties-newoptions-wrapper'>
                        <div className='newshare-properties-newoptions-title'>
                            <h2>{translations.editShare_proprierties_content_otherOptions}</h2>
                            <div className='newshare-properties-newoptions-close'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={changeStateOfMenu}>
                                    <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='newshare-properties-newoptions-container'>
                            <div className='newshare-properties-list-newoptions-input'>
                                <label>
                                    {translations.editShare_proprierties_content_authorCopyrights}
                                    <div class="newShare-properties-targets-option-tooltip">
                                        <div class="newShare-properties-targets-option-icon">i</div>
                                        <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_content_authorCopyrightsTooltip}</div>
                                    </div>
                                </label>
                                <input type='text' placeholder={translations.editShare_proprierties_content_authorCopyrights} value={copyrights} onChange={(e) => setCopyrights(e.target.value)} />
                            </div>
                        </div>
                        <div className='newshare-properties-list-newoptions-save'>
                            <button onClick={saveOtherOptions}>{translations.editShare_proprierties_content_save}</button>
                        </div>
                    </div>
                </div>
            )}

        </section >
    );
}

export default PropertiesNewShare;

function NewShareButtonNewReference({ category, setCategory, closeModal, translations1, language, translations }) {
    const [selectedItem, setSelectedItem] = useState('');
    const [urlValue, setUrlValue] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);

    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-_]+\.[a-zA-Z]{2,})(\/.*)?$/;

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setSelectedItem(value);
        setUrlValue(category.items[value]);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setUrlValue(value);
        setIsValidUrl(urlPattern.test(value));
    };

    const handleSave = () => {
        setCategory((prevCategory) => ({
            ...prevCategory,
            items: {
                ...prevCategory.items,
                [selectedItem]: urlValue
            },
            count: prevCategory.items[selectedItem] ? prevCategory.count : prevCategory.count + 1
        }));
        closeModal(false);
    };

    return (
        <div className='newreference-container'>
            <div className='newreference-selection-option'>
                <div className='newshare-properties-list-input'>
                    <label>
                        {translations.editShare_proprierties_reference}
                        <div class="newShare-properties-targets-option-tooltip">
                            <div class="newShare-properties-targets-option-icon">i</div>
                            <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_defineTheType}</div>
                        </div>
                    </label>
                    <select value={selectedItem} onChange={handleSelectChange}>
                        <option value='' disabled>{translations.editShare_proprierties_selectThePiece}</option>
                        {Object.keys(category.items).map((key) => (
                            <option key={key} value={key}>
                                {translations1[language][key]}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='newreference-url-option'>
                <div className='newreference-selection-input'>
                    <label>
                        {translations.editShare_proprierties_referenceURL}
                        <div class="newShare-properties-targets-option-tooltip">
                            <div class="newShare-properties-targets-option-icon">i</div>
                            <div class="newShare-properties-targets-option-tooltiptext">{translations.editShare_proprierties_tooltip}</div>
                        </div>
                    </label>
                    <input
                        type='text'
                        value={urlValue}
                        onChange={handleInputChange}
                        placeholder={translations.editShare_proprierties_URLForThePiece}
                        className='newshare-properties-list-input-input'
                        required
                    />
                </div>
            </div>

            <div className='newreference-container-close' onClick={() => closeModal(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15 9L9 14.9996M15 15L9 9.00039" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                </svg>
            </div>

            <div className='newreference-container-save'>
                <button onClick={handleSave} disabled={!isValidUrl || !selectedItem}>
                    {translations.editShare_proprierties_save}
                </button>
            </div>
        </div>
    );
}