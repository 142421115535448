import React, { useState, useContext } from "react";
import MainContext from '../../../context/MainContext';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/private/wardrobe/Wardrobe.css';

function WardrobeOptions({ setPageOpen, wardrobe }) {
    const { language } = useContext(LanguageContext);
    const { getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession, localStoredSubscription } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [wardrobes] = useState(wardrobe ? wardrobe : {});
    const [generatingSession, setGeneratingSession] = useState(false);

    const pendingItems = wardrobes && Object.values(wardrobes).filter(
        (item) => item.state === 'Pending' || item.state === 'Pendente'
    );

    // eslint-disable-next-line
    const nonPendingItems = wardrobes && Object.values(wardrobes).filter(
        (item) => item.state !== 'Pending' && item.state !== 'Pendente'
    );

    const createCheckoutSession = async () => {
        setGeneratingSession(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/pay/subscription`;

        const formData = new URLSearchParams({
            email: localStoredEmail,
            username: localStoredUsername,
            page: 'wardrobe',
            language: language,
            plan: 'premium'
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setGeneratingSession(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setGeneratingSession(false);

                const currentTimestampUTC = new Date().toISOString();

                const paymentIntentObject = {
                    timestamp: currentTimestampUTC
                };

                localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
                localStorage.setItem('subscription-payment-intent-plan', 'premium');

                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setGeneratingSession(false);
            });
    };

    return (
        <section className='wardrobe-list-content'>
            <div className='wardrobe-list-profile-wrapper' onClick={() => setPageOpen('preferences-form')}>
                <div className='wardrobe-list-profile-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C7.97679 14.053 10.8425 13.6575 13.5 14.2952" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M16.7596 16.378C15.6796 16.378 15.2171 17.1576 15.0971 17.6373C14.9771 18.117 14.9771 19.856 15.0491 20.5755C15.2891 21.475 15.8891 21.8468 16.4771 21.9667C17.0171 22.0147 19.2971 21.9967 19.9571 21.9967C20.9171 22.0147 21.6371 21.6549 21.9371 20.5755C21.9971 20.2157 22.0571 18.2369 21.9071 17.6373C21.5891 16.6778 20.866 16.378 20.266 16.378M16.7596 16.378H20.266M16.7596 16.378C16.7596 16.378 16.7582 15.5516 16.7596 15.1173C16.7609 14.7204 16.726 14.3378 16.9156 13.9876C17.626 12.5748 19.666 12.7187 20.17 14.1579C20.2573 14.3948 20.2626 14.7704 20.26 15.1173C20.2567 15.5605 20.266 16.378 20.266 16.378" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </div>
                <div className='wardrobe-list-profile-description'>
                    <h2>{translations.wardrobe_mainTitle}</h2>
                </div>
                <div className='wardrobe-list-profile-arrow'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>

            <div className="wardrobe-grid-title">
                <div className="wardrobe-grid-title-content">
                    <h2>{translations.wardrobe_subtitle}</h2>
                </div>
            </div>


            <div className='wardrobe-grid-container-wrapper'>
                <div className='wardrobe-grid-container'>

                    {localStoredSubscription === 'premium' ? (
                        <>
                            {/* Display pending items in progress */}
                            <div className='wardrobe-box-week-container-progress'>
                                <>
                                    {wardrobe !== null ? (
                                        <>
                                            {pendingItems.length > 0 ? (
                                                pendingItems.map((item, index) => (
                                                    <div key={index} className='wardrobe-box-week-container-progress-description'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                            <path d="M12 22C6.47715 22 2.00004 17.5228 2.00004 12C2.00004 6.47715 6.47719 2 12 2C16.4777 2 20.2257 4.94289 21.5 9H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M12 8V12L14 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M21.9551 13C21.9848 12.6709 22 12.3373 22 12M15 22C15.3416 21.8876 15.6753 21.7564 16 21.6078M20.7906 17C20.9835 16.6284 21.1555 16.2433 21.305 15.8462M18.1925 20.2292C18.5369 19.9441 18.8631 19.6358 19.1688 19.3065" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>{translations.wardrobe_inProgress}</span>
                                                        <p>{translations.wardrobe_notifyWhenReady}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className='wardrobe-box-week-container-progress-description'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                        <path d="M12 22C6.47715 22 2.00004 17.5228 2.00004 12C2.00004 6.47715 6.47719 2 12 2C16.4777 2 20.2257 4.94289 21.5 9H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12 8V12L14 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M21.9551 13C21.9848 12.6709 22 12.3373 22 12M15 22C15.3416 21.8876 15.6753 21.7564 16 21.6078M20.7906 17C20.9835 16.6284 21.1555 16.2433 21.305 15.8462M18.1925 20.2292C18.5369 19.9441 18.8631 19.6358 19.1688 19.3065" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <span>{translations.wardrobe_inProgress}</span>
                                                    <p>{translations.wardrobe_notifyWhenReady}</p>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className='wardrobe-box-week-container-progress-description'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M17.2014 2H6.79876C5.341 2 4.06202 2.9847 4.0036 4.40355C3.93009 6.18879 5.18564 7.37422 6.50435 8.4871C8.32861 10.0266 9.24075 10.7964 9.33642 11.7708C9.35139 11.9233 9.35139 12.0767 9.33642 12.2292C9.24075 13.2036 8.32862 13.9734 6.50435 15.5129C5.14932 16.6564 3.9263 17.7195 4.0036 19.5964C4.06202 21.0153 5.341 22 6.79876 22L17.2014 22C18.6591 22 19.9381 21.0153 19.9965 19.5964C20.043 18.4668 19.6244 17.342 18.7352 16.56C18.3298 16.2034 17.9089 15.8615 17.4958 15.5129C15.6715 13.9734 14.7594 13.2036 14.6637 12.2292C14.6487 12.0767 14.6487 11.9233 14.6637 11.7708C14.7594 10.7964 15.6715 10.0266 17.4958 8.4871C18.8366 7.35558 20.0729 6.25809 19.9965 4.40355C19.9381 2.9847 18.6591 2 17.2014 2Z" stroke="currentColor" stroke-width="1.5" />
                                                <path d="M9 21.6381C9 21.1962 9 20.9752 9.0876 20.7821C9.10151 20.7514 9.11699 20.7214 9.13399 20.6923C9.24101 20.509 9.42211 20.3796 9.78432 20.1208C10.7905 19.4021 11.2935 19.0427 11.8652 19.0045C11.955 18.9985 12.045 18.9985 12.1348 19.0045C12.7065 19.0427 13.2095 19.4021 14.2157 20.1208C14.5779 20.3796 14.759 20.509 14.866 20.6923C14.883 20.7214 14.8985 20.7514 14.9124 20.7821C15 20.9752 15 21.1962 15 21.6381V22H9V21.6381Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <span>{translations.wardrobe_tellUsWhatYouLike}</span>
                                            <p>{translations.wardrobe_personalizeWardrobe}</p>
                                        </div>
                                    )}
                                </>

                            </div>
                        </>
                    ) : (
                        <>
                            {generatingSession ? (
                                <>
                                    <div className='wardrobe-box-week-container-payment'>
                                        <div className='wardrobe-box-week-container-payment-description'>
                                            <span class="wardrobe-box-week-container-payment-loader"></span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='wardrobe-box-week-container-payment' onClick={createCheckoutSession}>
                                    <div className='wardrobe-box-week-container-payment-description'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M3.51819 10.3058C3.13013 9.23176 2.9361 8.69476 3.01884 8.35065C3.10933 7.97427 3.377 7.68084 3.71913 7.58296C4.03193 7.49346 4.51853 7.70973 5.49173 8.14227C6.35253 8.52486 6.78293 8.71615 7.18732 8.70551C7.63257 8.69379 8.06088 8.51524 8.4016 8.19931C8.71105 7.91237 8.91861 7.45513 9.33373 6.54064L10.2486 4.52525C11.0128 2.84175 11.3949 2 12 2C12.6051 2 12.9872 2.84175 13.7514 4.52525L14.6663 6.54064C15.0814 7.45513 15.289 7.91237 15.5984 8.19931C15.9391 8.51524 16.3674 8.69379 16.8127 8.70551C17.2171 8.71615 17.6475 8.52486 18.5083 8.14227C19.4815 7.70973 19.9681 7.49346 20.2809 7.58296C20.623 7.68084 20.8907 7.97427 20.9812 8.35065C21.0639 8.69476 20.8699 9.23176 20.4818 10.3057L18.8138 14.9222C18.1002 16.897 17.7435 17.8844 16.9968 18.4422C16.2502 19 15.2854 19 13.3558 19H10.6442C8.71459 19 7.74977 19 7.00315 18.4422C6.25654 17.8844 5.89977 16.897 5.18622 14.9222L3.51819 10.3058Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M12 14H12.009" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7 22H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                        </svg>
                                        <span>{translations.wardrobe_premiumPlan}</span>
                                        <p>{translations.wardrobe_subscribeToContinue}</p>
                                    </div>
                                </div>
                            )}

                        </>
                    )}

                    {/* Display non-pending items 
                    {nonPendingItems.length > 0 && (
                        <div className='wardrobe-grid-container'>
                            {nonPendingItems.map((item, index) => (
                                <a key={index} href={`#`} className='wardrobe-box-week-container'>
                                    <div className='wardrobe-box-week-container-description'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M4 11V15C4 18.2998 4 19.9497 5.02513 20.9749C6.05025 22 7.70017 22 11 22H13C16.2998 22 17.9497 22 18.9749 20.9749C20 19.9497 20 18.2998 20 15V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3 9C3 8.25231 3 7.87846 3.20096 7.6C3.33261 7.41758 3.52197 7.26609 3.75 7.16077C4.09808 7 4.56538 7 5.5 7H18.5C19.4346 7 19.9019 7 20.25 7.16077C20.478 7.26609 20.6674 7.41758 20.799 7.6C21 7.87846 21 8.25231 21 9C21 9.74769 21 10.1215 20.799 10.4C20.6674 10.5824 20.478 10.7339 20.25 10.8392C19.9019 11 19.4346 11 18.5 11H5.5C4.56538 11 4.09808 11 3.75 10.8392C3.52197 10.7339 3.33261 10.5824 3.20096 10.4C3 10.1215 3 9.74769 3 9Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M6 3.78571C6 2.79949 6.79949 2 7.78571 2H8.14286C10.2731 2 12 3.7269 12 5.85714V7H9.21429C7.43908 7 6 5.56091 6 3.78571Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M18 3.78571C18 2.79949 17.2005 2 16.2143 2H15.8571C13.7269 2 12 3.7269 12 5.85714V7H14.7857C16.5609 7 18 5.56091 18 3.78571Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M12 11L12 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>{item.date}</span>
                                    </div>
                                </a>
                            ))}
                        </div>
                    )}
                    */}
                </div>
            </div>
        </section>
    );
}

export default WardrobeOptions;
