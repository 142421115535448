import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import MainContext from '../../context/MainContext'
import { LanguageContext } from '../../context/LanguageContext';
import LOADINGSHARE from '../../pages/public/GalleryLoader';
import HOOKER_MENU_LANDINGPAGE from '../../pages/public/landingPage/MenuTop'
import MENUTOP from '../../pages/private/MenuTop';
import HOOKER_SHARE from '../../pages/public/Share'
import HOOKER_FOOTER from '../../pages/public/landingPage/MenuFooter'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/common.css';

const HOOKERSHARE = () => {
    const { isAuthenticated, getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { author, share } = useParams();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const [loadingData, setLoadingData] = useState(true);
    const [loadingShare, setLoadingShare] = useState(false);

    const [like, setLike] = useState(false);
    const [save, setSave] = useState(false);

    const [shareData, setShareData] = useState({
        share: {
            author: '',
            profilePicture: '',
            picture: '',
            extension: '',
            gender: '',
            style: '',
            copyrights: '',
            views: '',
            options: ''
        },
        references: {
            clothes: '',
            accessories: '',
            footwear: '',
        },
        liked: '',
        saved: '',
        timestamp: ''
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (author === undefined || share === undefined) {
            return;
        }

        const shareCache = localStorage.getItem(`share_${share}`);
        const parsedShares = shareCache ? JSON.parse(shareCache) : null;

        const now = Date.now();

        if (parsedShares && parsedShares.share && parsedShares.references) {
            const cacheTimestamp = parsedShares.timestamp;

            const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
            if (now - cacheTimestamp > twoDaysInMilliseconds) {
                if (isAuthenticated) {
                    loadPrivateShare();
                } else {
                    loadPublicShare();
                }
            } else {
                setShareData(parsedShares);
                setLike(parsedShares.liked);
                setSave(parsedShares.saved);
                setLoadingData(false);
                setLoadingShare(false);
            }
        } else {
            if (isAuthenticated) {
                loadPrivateShare();
            } else {
                loadPublicShare();
            }
        }

    }, [author, share]);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function loadPublicShare() {
        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/fetch/share`;

        const queryParams = new URLSearchParams({
            author: author,
            share: share,
            language: language
        });

        await fetch(`${baseURL}?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setShareData(data.share);
                localStorage.setItem(`share_${share}`, JSON.stringify(data.share));

                setLoadingData(false);
                setLoadingShare(false);
            })
            .catch(error => {
                console.error('Failed to load shares:', error);
                setLoadingData(false);
            });

    }

    async function loadPrivateShare() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/share`;

        const queryParams = new URLSearchParams({
            author: author,
            share: share,
            language: language
        });

        await fetch(`${baseURL}?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setShareData(data.share);
                localStorage.setItem(`share_${share}`, JSON.stringify(data.share));

                if (data.share.liked === true) {
                    setLike(true);
                } else {
                    setLike(false);
                }

                if (data.share.saved === true) {
                    setSave(true);
                } else {
                    setSave(false);
                }

                setLoadingData(false);
                setLoadingShare(false);
            })
            .catch(error => {
                console.error('Failed to load shares:', error);
                setLoadingData(false);
            });

    }

    const shareImageUrl = `https://photos.matchthelook.com/clothingfashion/${shareData.share.picture}.${shareData.share.extension}`;

    const dynamicTitle = `${shareData.share.author}'s ${shareData.share.style} ${translations.seo_share_dynamicTitle_outfit} - ${shareData.share.gender} ${translations.seo_share_dynamicTitle_FashionOn} Match the Look`;
    const dynamicDescription = `${translations.seo_share_dynamicDescription_discover} ${shareData.share.style} ${translations.seo_share_dynamicDescription_fashionSharedBy} ${shareData.share.author}. ${translations.seo_share_dynamicDescription_includesRecommendations}`;

    return (
        <>
            <section className='common-public-wrapper'>
                <Helmet>
                    {/* General SEO Tags */}
                    <title>{dynamicTitle}</title>
                    <meta name="description" content={dynamicDescription} />
                    <meta name="keywords" content={`outfit, style, ${shareData.share.style}, ${shareData.share.gender}, ${shareData.references.clothes}, ${shareData.references.accessories}, ${shareData.references.footwear}, fashion, fashion inspiration, outfit inspiration, AI fashion advisor, personal stylist, fashion SaaS, wardrobe inspiration, clothing recommendations, moda, estilo, inspiração de looks, consultoria de moda com IA, estilista pessoal, inspiração de guarda-roupa, recomendações de roupas, buy clothes, outfit ideas, fashion tips, style improvement, clothing brands, where to buy outfits, inspiration for dressing well, shopping fashion online, affordable fashion, trendy clothing, shop the look, fashion advice, best fashion trends, seasonal outfits, outfit matching, AI style suggestions, personal shopper online, latest fashion trends, fashion influencers, fashion community, style boards, personalized styling, shop outfits online, unique fashion finds, dress better tips, comprar roupa, ideias de looks, dicas de moda, melhorar o estilo, marcas de roupa, onde comprar roupa, inspiração para vestir bem, compras de moda online, moda acessível, roupas da moda, inspiração de looks, melhores tendências de moda, looks sazonais, combinação de outfits, sugestões de estilo IA, personal shopper online, últimas tendências de moda, influenciadores de moda, comunidade de moda, painéis de estilo, estilo personalizado, comprar outfits online, moda única, dicas para vestir melhor`} />

                    {/* Open Graph Tags */}
                    <meta property="og:title" content={dynamicTitle} />
                    <meta property="og:description" content={dynamicDescription} />
                    <meta property="og:image" content={shareImageUrl} />
                    <meta property="og:image:alt" content={`${translations.seo_share_imageAlt_imageOf} ${shareData.share.style} ${translations.seo_share_imageAlt_outfit}`} />
                    <meta property="og:url" content={`https://matchthelook.com/${language}/${author}/share/${shareData.share.picture}`} />

                    {/* Twitter Card Tags */}
                    <meta name="twitter:card" content={shareImageUrl} />
                    <meta name="twitter:title" content={dynamicTitle} />
                    <meta name="twitter:description" content={dynamicDescription} />
                    <meta name="twitter:image" content={shareImageUrl} />
                    <meta name="twitter:image:alt" content={`${translations.seo_share_imageAlt_imageOf} ${shareData.share.style} ${translations.seo_share_imageAlt_outfit}`} />

                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>
                {isAuthenticated ? (
                    <MENUTOP />
                ) : (
                    <HOOKER_MENU_LANDINGPAGE />
                )}
                {loadingShare ? (
                    <LOADINGSHARE />
                ) : (
                    <HOOKER_SHARE
                        shareData={shareData}
                        language={language}
                        loading={loadingData}
                        like={like}
                        setLike={setLike}
                        save={save}
                        setSave={setSave}
                    />
                )}
                <HOOKER_FOOTER />
            </section>
        </>
    );
};

export default HOOKERSHARE;
