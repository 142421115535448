import { useState, useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import MainContext from '../../../context/MainContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Pricing.css';

function SECTIONPRICING() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredEmail, localStoredUsername, localStoredSession, localStoredSubscription } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [creatingPaymentSession, setCreatingPaymentSession] = useState(false);

  async function prepareToCreateSession(plan) {
    setCreatingPaymentSession(true);
    await createCheckoutSession(plan);
  }

  const createCheckoutSession = async (plan) => {

    const baseURL = `${MainContext.apiURL}/api/v1/private/users/pay/subscription`;

    const formData = new URLSearchParams({
      email: localStoredEmail,
      username: localStoredUsername,
      page: 'none',
      language: language,
      plan: plan
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'email': localStoredEmail,
        'username': localStoredUsername,
        'session': localStoredSession
      },
      credentials: 'include',
      body: formData.toString(),
    })
      .then(response => {
        if (response.status === 400 && isAuthenticated) {

          if (plan === 'plus') {
            window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`
          } else if (plan === 'premium') {
            window.location.href = `/${language}/account/${localStoredUsername}/wardrobe`
          } else {
            window.location.href = `/${language}/account/${localStoredUsername}/mural`
          }
        } else if (!response.ok) {
          setCreatingPaymentSession(false);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          return response.json();
        }
      })
      .then(data => {
        const currentTimestampUTC = new Date().toISOString();

        const paymentIntentObject = {
          timestamp: currentTimestampUTC
        };

        localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
        localStorage.setItem('subscription-payment-intent-plan', plan);

        setCreatingPaymentSession(false);

        window.location.href = data.session;
      })
      .catch(error => {
        console.error('Error activating account:', error);
        setCreatingPaymentSession(false);
      });
  };

  return (
    <div className="landingPage-pricing-wrapper">
      <div className='landingPage-pricing-title'>
        <h2>{translations.landingPage_pricing_title}</h2>
      </div>

      <div className='landingPage-pricing-container-box'>

        <div className='landingPage-pricing-box'>
          <div className='landingPage-pricing-box-title'>
            <h4>{translations.landingPage_pricing_freeTitle}</h4>
            <p>-€/{translations.landingPage_pricing_month}</p>
          </div>
          <div className='landingPage-pricing-box-advantages'>
            <p>{translations.landingPage_pricing_imageSharing}</p>
            <p>{translations.landingPage_pricing_limitedRewardPoints}</p>
            <p>{translations.landingPage_pricing_accessToAI}</p>
          </div>
          <div className='landingPage-pricing-box-cta'>
            {isAuthenticated ? (
              <a href={`/${language}/account/${localStoredUsername}/mural`}><button className='landingPage-pricing-box-cta-free'>{translations.landingPage_pricing_freeButtonCTA}</button></a>
            ) : (
              <a href={`/${language}/register`}><button className='landingPage-pricing-box-cta-free'>{translations.landingPage_pricing_freeButtonCTA}</button></a>
            )}
            <p>{translations.landingPage_pricing_noLoyalty}</p>
          </div>
        </div>

        <div className='landingPage-pricing-box'>
          <div className='landingPage-pricing-box-title'>
            <h4>{translations.landingPage_pricing_plusTitle}</h4>
            <p>9.99€/{translations.landingPage_pricing_month}</p>
          </div>
          <div className='landingPage-pricing-box-advantages'>
            <p>{translations.landingPage_pricing_imageSharing}</p>
            <p>{translations.landingPage_pricing_exclusiveRewardPoints}</p>
            <p>{translations.landingPage_pricing_accessToAI}</p>
            <p>{translations.landingPage_pricing_accessToGenerateAI}</p>
          </div>
          <div className='landingPage-pricing-box-cta'>
            {isAuthenticated && (localStoredSubscription === 'plus' || localStoredSubscription === 'premium') ? (
              <a href={`/${language}/account/${localStoredUsername}/ai/chat`}><button>{translations.landingPage_pricing_upgradePlusButton}</button></a>
            ) : (
              <>
                {isAuthenticated ? (
                  <button onClick={() => prepareToCreateSession('plus')}>{translations.landingPage_pricing_upgradePlusButton}</button>
                ) : (
                  <a href={`/${language}/register/plus`}><button>{translations.landingPage_pricing_upgradePlusButton}</button></a>
                )}
              </>
            )}
            <p>{translations.landingPage_pricing_noLoyalty}</p>
          </div>
        </div>

        <div className='landingPage-pricing-box'>
          <div className='landingPage-pricing-box-title'>
            <h4>{translations.landingPage_pricing_premiumTitle}</h4>
            <p>25.99€/{translations.landingPage_pricing_month}</p>
          </div>
          <div className='landingPage-pricing-box-advantages'>
            <p>{translations.landingPage_pricing_imageSharing}</p>
            <p>{translations.landingPage_pricing_exclusiveRewardPoints}</p>
            <p>{translations.landingPage_pricing_accessToAI}</p>
            <p>{translations.landingPage_pricing_accessToGenerateAI}</p>
            <p>{translations.landingPage_pricing_exclusivePromoCodes}</p>
            <p>{translations.landingPage_pricing_personalStylish}</p>
          </div>
          <div className='landingPage-pricing-box-cta'>
            {isAuthenticated && localStoredSubscription === 'premium' ? (
              <a href={`/${language}/account/${localStoredUsername}/wardrobe`}><button>{translations.landingPage_pricing_upgradePremiumButton}</button></a>
            ) : (
              <>
                {isAuthenticated ? (
                  <button onClick={() => prepareToCreateSession('premium')}>{translations.landingPage_pricing_upgradePremiumButton}</button>
                ) : (
                  <a href={`/${language}/register/premium`}><button>{translations.landingPage_pricing_upgradePremiumButton}</button></a>
                )}
              </>
            )}
            <p>{translations.landingPage_pricing_noLoyalty}</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default SECTIONPRICING;
