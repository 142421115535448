import React from 'react';
import '../../../style/private/editShare/editshare_image.css'

function EditShareImage({ image, extension, MainContext }) {
    const loading = false

    return (
        <div className='editShare-picture-container'>
            <div className='editShare-picture-container-content'>
                {loading ? (
                    <div className='editShare-information-references-loader-container'>
                        <span className="editShare-information-references-loader"></span>
                    </div>
                ) : (
                    <img src={`${MainContext.storageClothesURL}${image}${extension}`} alt='' />
                )}
            </div>
        </div>
    );
};

export default EditShareImage;