import { useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Hero.css';

function SECTIONHERO() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <div className="landingPage-hero-wrapper">
      <div className='landingPage-hero-title'>
        <h1>{translations.landingPage_hero_title01}</h1>
        <h1>{translations.landingPage_hero_title02}</h1>
      </div>
      <div className='landingPage-hero-subtitle'>
        <h2>{translations.landingPage_hero_description}</h2>
      </div>
      <div className='landingPage-hero-cta'>
        <p>{translations.landingPage_hero_ctaDescription}</p>
        {!isAuthenticated ? (
          <a href={`/${language}/public/mural`}><button>{translations.landingPage_hero_ctaButton}</button></a>
        ) : (
          <a href={`/${language}/account/${localStoredUsername}/mural`}><button>{translations.landingPage_hero_ctaButton}</button></a>
        )}
      </div>
      <div className='landingPage-hero-image'>
        {language === 'pt' ? (
          <img src='/landingPage/hero_demo_pt.png' alt='' />
        ) : (
          <img src='/landingPage/hero_demo_en.png' alt='' />
        )}
      </div>
    </div>
  );
}

export default SECTIONHERO;
