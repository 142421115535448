import React, { useState, useEffect } from 'react';
import '../../../style/private/wardrobe/PreferencesForm.css';

function WardrobePreferencesForm({ 
    preferencesSavedJSON,
    setPreferencesCompleted, 
    setPreferencesSavedJSON, 
    setMenuOptionsOpen, 
    setMenuOpen,
    translations
}) {
    const [option1, setOption1] = useState([]);
    const [option2, setOption2] = useState([]);
    const [option3, setOption3] = useState([]);
    const [option4, setOption4] = useState([]);
    const [option5, setOption5] = useState([]);

    const option1OptionsList = [
        { value: 'work', label: translations.wardrobe_preferences_userPreferences_work },
        { value: 'casual_outings', label: translations.wardrobe_preferences_userPreferences_casualNight },
        { value: 'formal_events', label: translations.wardrobe_preferences_userPreferences_formalEvents },
        { value: 'travel', label: translations.wardrobe_preferences_userPreferences_travel },
        { value: 'fitness', label: translations.wardrobe_preferences_userPreferences_fitness },
        { value: 'everyday_wear', label: translations.wardrobe_preferences_userPreferences_dailyUse },
    ];
    
    const option2OptionsList = [
        { value: 'frequently', label: translations.wardrobe_preferences_userPreferences_frequently },
        { value: 'occasionally', label: translations.wardrobe_preferences_userPreferences_occasionaly },
        { value: 'rarely', label: translations.wardrobe_preferences_userPreferences_almostNever },
    ];
    
    const option3OptionsList = [
        { value: 'casual', label: translations.wardrobe_preferences_userPreferences_casual },
        { value: 'business_casual', label: translations.wardrobe_preferences_userPreferences_businessCasual },
        { value: 'professional', label: translations.wardrobe_preferences_userPreferences_professional },
        { value: 'remote_relaxed', label: translations.wardrobe_preferences_userPreferences_remoteRelaxed },
    ];
    
    const option4OptionsList = [
        { value: 'dress_up', label: translations.wardrobe_preferences_userPreferences_dressMoreFormal },
        { value: 'keep_it_casual', label: translations.wardrobe_preferences_userPreferences_useCasual },
        { value: 'somewhere_in_between', label: translations.wardrobe_preferences_userPreferences_somethingInMiddle },
    ];
    
    const option5OptionsList = [
        { value: 'warm', label: translations.wardrobe_preferences_userPreferences_warm },
        { value: 'cold', label: translations.wardrobe_preferences_userPreferences_cold },
        { value: 'temperate', label: translations.wardrobe_preferences_userPreferences_average },
        { value: 'varies', label: translations.wardrobe_preferences_userPreferences_several },
    ];

    useEffect(() => {
        if (preferencesSavedJSON) {
            setOption1(preferencesSavedJSON.clothesNeededFor || []);
            setOption2(preferencesSavedJSON.countOnFormalEvents || []);
            setOption3(preferencesSavedJSON.idealBusinessClothes || []);
            setOption4(preferencesSavedJSON.relaxedOrFormalOnWeekend || []);
            setOption5(preferencesSavedJSON.weatherOnUserLocation || []);
        }
    }, [preferencesSavedJSON]);

    const handleOptionChange = (setOption) => (event) => {
        const { value, checked } = event.target;
        setOption((prevChoices) => 
            checked ? [...prevChoices, value] : prevChoices.filter((item) => item !== value)
        );
    };

    function savePreferencesIntoJSON() {
        const newUserOptions = {
            clothesNeededFor: option1,
            countOnFormalEvents: option2,
            idealBusinessClothes: option3,
            relaxedOrFormalOnWeekend: option4,
            weatherOnUserLocation: option5,
        };

        const hasPreferences = Object.values(newUserOptions).some(options => options.length > 0);

        setPreferencesCompleted(hasPreferences);
        setPreferencesSavedJSON(newUserOptions);
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    function closeMenuWithoutSave() {
        setMenuOptionsOpen('');
        setMenuOpen(false);
    }

    return (
        <div className='wardrobe-preferences-options-menu'>
            <div className='wardrobe-preferences-options-menu-title'>
                <div className='wardrobe-preferences-options-menu-title-content'>
                    <h3>{translations.wardrobe_preferences_userPreferences_title}</h3>
                </div>
                <div className='wardrobe-preferences-options-menu-title-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeMenuWithoutSave}>
                        <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                </div>
            </div>
            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_userPreferences_question1}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option1OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-style'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={handleOptionChange(setOption1)}
                                checked={option1.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_userPreferences_question2}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option2OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-clothes'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={handleOptionChange(setOption2)}
                                checked={option2.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_userPreferences_question3}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option3OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-colors'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={handleOptionChange(setOption3)}
                                checked={option3.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_userPreferences_question4}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option4OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-footwear'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={handleOptionChange(setOption4)}
                                checked={option4.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-options'>
                <p>{translations.wardrobe_preferences_userPreferences_question5}</p>
                <div className='wardrobe-preferences-options-menu-options-checkbox-checkbox-group'>
                    {option5OptionsList.map((option) => (
                        <label key={option.value} className='wardrobe-preferences-options-menu-options-checkbox-checkbox'>
                            <input
                                type='checkbox'
                                name='clothing-climate'
                                value={option.value}
                                className='wardrobe-preferences-options-menu-options-checkbox-input'
                                onChange={handleOptionChange(setOption5)}
                                checked={option5.includes(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </div>

            <div className='wardrobe-preferences-options-menu-save'>
                <button onClick={savePreferencesIntoJSON} className='wardrobe-preferences-options-menu-save-button'>
                    {translations.wardrobe_preferences_userPreferences_save}
                </button>
            </div>
        </div>
    );
}

export default WardrobePreferencesForm;
