import { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import Menu from './settings/MenuSettings';
import Profile from './settings/Profile';
import Account from './settings/Account';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/settings.css';

function Settings() {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredName, localStoredSurname, localStoredEmail, localStoredUsername, localStoredGender, localStoredBirthday, localStoredCountry, localStoredReferralCode, localStoredSocials, localStoredSession, localStoredProfilePicture } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [menuActive, setMenuActive] = useState('profile');

    return (
        <section className='myAccount-settings-wrapper'>
            <div className='myAccount-settings-menu'>
                <Menu
                    menuActive={menuActive}
                    setMenuActive={setMenuActive}
                    localStoredProfilePicture={localStoredProfilePicture}
                    translations={translations}
                />
            </div>
            <div className='myAccount-settings-content'>
                {menuActive === 'profile' && (
                    <Profile
                        language={language}
                        localStoredUsername={localStoredUsername}
                        translations={translations}
                        localStoredProfilePicture={localStoredProfilePicture}
                        localStoredSocials={localStoredSocials}
                        localStoredEmail={localStoredEmail}
                        localStoredSession={localStoredSession}
                        localStoredReferralCode={localStoredReferralCode}
                        MainContext={MainContext}
                    />
                )}

                {menuActive === 'account' && (
                    <Account
                        language={language}
                        localStoredUsername={localStoredUsername}
                        localStoredSession={localStoredSession}
                        localStoredName={localStoredName}
                        localStoredSurname={localStoredSurname}
                        localStoredEmail={localStoredEmail}
                        localStoredGender={localStoredGender}
                        localStoredBirthday={localStoredBirthday}
                        localStoredCountry={localStoredCountry}
                        translations={translations}
                        MainContext={MainContext}
                    />
                )}

            </div>
        </section >
    );
}

export default Settings;
