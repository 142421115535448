import React, { useState } from 'react';
import MainContext from '../context/MainContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/common/gallery-image.css';

function GalleryLoader({ language, shareImage, shareExtension, authorUsername, authorPicture }) {
    const [isLoading, setIsLoading] = useState(true);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    
    return (
        <section className='gallery-image-component-wrapper'>
            <article className="gallery-image-component-">
                <section className="gallery-image-component-__hero">
                    <header className="gallery-image-component-__hero-header">
                        <div className="gallery-image-component-__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
                                <path d="M8.9835 1.99998C6.17689 2.06393 4.53758 2.33109 3.41752 3.44727C2.43723 4.42416 2.10954 5.79742 2 7.99998M15.0165 1.99998C17.8231 2.06393 19.4624 2.33109 20.5825 3.44727C21.5628 4.42416 21.8905 5.79742 22 7.99998M15.0165 22C17.8231 21.9361 19.4624 21.6689 20.5825 20.5527C21.5628 19.5758 21.8905 18.2026 22 16M8.9835 22C6.17689 21.9361 4.53758 21.6689 3.41752 20.5527C2.43723 19.5758 2.10954 18.2026 2 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 15L17 17M16 11.5C16 9.01468 13.9853 6.99998 11.5 6.99998C9.01469 6.99998 7 9.01468 7 11.5C7 13.9853 9.01469 16 11.5 16C13.9853 16 16 13.9853 16 11.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </header>
                    {isLoading && (
                        <div className='gallery-image-component-loader-container'>
                            <span className="gallery-image-component-loader"></span>
                        </div>
                    )}
                    <a href={`/${language}/${authorUsername}/share/${shareImage}`}>
                        <img
                            src={`${MainContext.storageClothesURL}${shareImage}${shareExtension}`}
                            alt={`@${authorUsername} collection outfit on elegant style`}
                            onLoad={() => setIsLoading(false)}
                            style={{ display: isLoading ? 'none' : 'block' }}
                        />
                    </a>
                </section>
                <footer className="gallery-image-component-__footer">
                    <a href={`/${language}/${authorUsername}`}>
                        <div className="gallery-image-component-__job-summary">
                            <div className="gallery-image-component-__job-icon">
                                {authorPicture ? (
                                    <img src={`${MainContext.profilePictureURL}${authorPicture}`} alt={`User @${authorUsername} Public Profile`} />
                                ) : (
                                    <img src='/common/icon-default-picture.png' alt={`User @${authorUsername} Default Public Profile`} />
                                )}
                            </div>
                            <div className="gallery-image-component-__job">
                                {authorUsername ? (
                                    <p className="gallery-image-component-__job-title">@{authorUsername}</p>
                                ) : (
                                    <p className="gallery-image-component-__job-title"></p>
                                )}
                            </div>
                        </div>
                    </a>
                    <a href={`/${language}/${authorUsername}/share/${shareImage}`}>
                        <button className="gallery-image-component-__btn">{translations.public_mural_gallery_open}</button>
                    </a>
                </footer>
            </article>
        </section>
    );
}

export default GalleryLoader;
