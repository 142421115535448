import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MainContext from '../../context/MainContext'
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import IMAGE from './editShare/Image'
import PROPERTIES from './newShare/Properties'
import FULLWIDTHLOADER from '../public/FullWidthLoader';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/newshare.css'

function EditShare() {
    const { author, picture } = useParams();
    const shareCache = localStorage.getItem(`share_${picture}`);
    const { getSessionInfo, logout } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (author !== localStoredUsername) {
            window.location.href = `/${language}/404`;
        } else if (!shareCache || Object.keys(shareCache).length === 0) {
            window.location.href = `/${language}/404`;
        } else {
            const parsedShares = shareCache ? JSON.parse(shareCache) : null;
            setImage(parsedShares.share.picture);
            setExtension(parsedShares.share.extension);
            if (parsedShares.share.gender === 'Man' || parsedShares.share.gender === 'Masculino' || parsedShares.share.gender === 'Homem') {
                setGender('man')
            } else {
                setGender('woman');
            }
            if (parsedShares.share.style === 'Casual') {
                setStyle('casual');
            } else if (parsedShares.share.style === 'Formal') {
                setStyle('formal');
            } else if (parsedShares.share.style === 'Sporty' || parsedShares.share.style === 'Desportivo') {
                setStyle('sporty');
            } else if (parsedShares.share.style === 'Vintage') {
                setStyle('vintage');
            } else if (parsedShares.share.style === 'Elegant' || parsedShares.share.style === 'Elegante') {
                setStyle('elegant');
            }
            setOtherOptions(parsedShares.share.options);
            setClothing(parsedShares.references.clothes);
            setAccessories(parsedShares.references.accessories);
            setFootwear(parsedShares.references.footwear);
        }
    }, [author, picture]);
    /* eslint-disable react-hooks/exhaustive-deps */

    // eslint-disable-next-line
    const [image, setImage] = useState('');
    const [extension, setExtension] = useState('');
    const imageRef = useRef(null);
    const [gender, setGender] = useState('');
    const [style, setStyle] = useState('');
    const [otherOptions, setOtherOptions] = useState('');

    const [clothing, setClothing] = useState({
        count: 0,
        items: {
            jacket: '',
            blazer: '',
            vest: '',
            sweater: '',
            blouse: '',
            dress: '',
            polo: '',
            shirt: '',
            tshirt: '',
            pants: '',
            jeans: '',
            shorts: '',
            skirt: '',
            pijama: '',
            swimwear: '',
            boxers: '',
            bra: '',
            panties: '',
            socks: '',
            other: ''
        }
    });

    const [accessories, setAccessories] = useState({
        count: 0,
        items: {
            cap: '',
            hat: '',
            gloves: '',
            scarf: '',
            watch: '',
            belt: '',
            handbag: '',
            necklace: '',
            ring: '',
            earrings: '',
            bag: '',
            bagpack: '',
            tie: '',
            bowtie: '',
            bracelet: '',
            wallet: '',
            glasses: '',
            other: ''
        }
    });

    const [footwear, setFootwear] = useState({
        count: 0,
        items: {
            shoes: '',
            sneakers: '',
            sandals: '',
            mocassins: '',
            boots: '',
            ankleboots: '',
            highheels: '',
            elegantshoes: '',
            flipflops: '',
            slippers: '',
            other: ''
        }
    });

    const isDisabledSaveButton = imageRef && gender && style && (clothing.count > 0 || accessories.count > 0 || footwear.count > 0);
    const [uploading, setUploading] = useState(false);

    const updateShare = () => {
        setUploading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/share`;

        const parsedShares = shareCache ? JSON.parse(shareCache) : null;

        const formData = new URLSearchParams({
            picture: image,
            gender: gender,
            style: style,
            clothes: JSON.stringify(clothing),
            accessories: JSON.stringify(accessories),
            footwear: JSON.stringify(footwear),
            options: JSON.stringify(otherOptions),
            parsedShares: JSON.stringify(parsedShares),
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setUploading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                localStorage.removeItem(`share_${picture}`);
                setUploading(false);
                window.location.href = `/${language}/${author}/share/${picture}`;
            })
            .catch(error => {
                console.error('Error updating share:', error);
                setUploading(false);
            });
    };

    return (
        <section className='newshare-wrapper'>
            <div className='newshare-images-wrapper'>
                <IMAGE
                    image={image}
                    extension={extension}
                    MainContext={MainContext}
                />
            </div>
            <div className='newshare-references-wrapper'>
                <PROPERTIES
                    gender={gender}
                    setGender={setGender}
                    style={style}
                    setStyle={setStyle}
                    otherOptions={otherOptions}
                    setOtherOptions={setOtherOptions}
                    clothing={clothing}
                    setClothing={setClothing}
                    accessories={accessories}
                    setAccessories={setAccessories}
                    footwear={footwear}
                    setFootwear={setFootwear}
                    language={language}
                    translations={translations}
                    loading={uploading}
                    isDisabledSaveButton={isDisabledSaveButton}
                    submitShare={updateShare}
                />
            </div>
            {uploading && (
                <FULLWIDTHLOADER loading={true} />
            )}
        </section>
    );
}

export default EditShare;