import { useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_HowItWorks.css';

function SECTIONHOWITWORKS() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <div className="landingPage-howitworks-wrapper">
      <div className='landingPage-howitworks-content-wrapper'>
        <div className='landingPage-howitworks-content'>
          <h2>{translations.landingPage_howItWorks_title}</h2>
          <p>{translations.landingPage_howItWorks_description01} </p>
          <br /> <br />
          <p><b>{translations.landingPage_howItWorks_description02} </b>
          </p> <br />
          <p> {translations.landingPage_howItWorks_description03} <b>{translations.landingPage_howItWorks_description04}</b> {translations.landingPage_howItWorks_description05} </p>
          <div className='landingPage-howitworks-cta'>
            {!isAuthenticated ? (
              <a href={`/${language}/public/mural`}><button>{translations.landingPage_howItWorks_ctaButton}</button></a>
            ) : (
              <a href={`/${language}/account/${localStoredUsername}/mural`}><button>{translations.landingPage_howItWorks_ctaButton}</button></a>
            )}
          </div>
        </div>
        <div className='landingPage-howitworks-image'>
          {language === 'pt' ? (
            <img src='/landingPage/howitworks_demo_pt.png' alt='' />
          ) : (
            <img src='/landingPage/howitworks_demo_en.png' alt='' />
          )}
        </div>
      </div>
    </div>
  );
}

export default SECTIONHOWITWORKS;
