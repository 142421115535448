import React, { useState, useContext } from 'react';
import MainContext from '../../../context/MainContext'
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import Step2 from './Step2';
import Step3 from './Step3';
import '../../../style/auth/register.css'

function Register() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);
    const [registrationStep, setRegistrationStep] = useState(1);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');

    const [OTPCode, setOTPCode] = useState('');

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    function validateToCreateAccount(e) {
        e.preventDefault();

        if (!validateEmail(email)) {
            return setEmailError(true);
        }

        sendEmailWithSecurityCode();
    }

    const sendEmailWithSecurityCode = () => {
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/verify-email`;
        const data = new URLSearchParams({
            email: email,
            language: language
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setRegistrationStep(2);
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                setLoading(false);
            });
    };

    return (
        <section className='register-wrapper'>
            <div className='register-description-wrapper'>
                <div className='register-description-main-title'>
                    <a href={`/${language}/`}><h1>Match the Look</h1></a>
                </div>
            </div>
            <div className='register-form-wrapper'>
                <div className='register-form-container'>
                    {registrationStep < 4 ? (
                        <div className='register-form-title'>
                            <h2>{translations.forgotPassword_title}</h2>
                            <h3>{translations.forgotPassword_subtitle}</h3>
                        </div>
                    ) : (
                        <div className='register-form-title-final'>
                            <h2>{translations.forgotPassword_congratsPasswordChange}</h2>
                            <h3>{translations.forgotPassword_youCanSignInNow}</h3>
                        </div>
                    )}


                    {registrationStep === 1 && (
                        <form className='register-form-options'>
                            <div className={emailError ? 'register-form-options-components-error' : 'register-form-options-components'}>
                                <label>{translations.forgotPassword_email}</label>
                                <input
                                    type='email'
                                    placeholder={translations.forgotPassword_email}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(false);
                                    }}
                                />
                            </div>

                            <div className='register-form-options-components-button'>
                                <button disabled={loading} onClick={validateToCreateAccount}>
                                    {loading ? (
                                        <span className="loading-dots">
                                            {translations.forgotPassword_holdOn}<span>.</span><span>.</span><span>.</span>
                                        </span>
                                    ) : (
                                        translations.forgotPassword_sendEmail
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {registrationStep === 2 && (
                        <Step3
                            email={email}
                            loading={loading}
                            setLoading={setLoading}
                            MainContext={MainContext}
                            setRegistrationStep={setRegistrationStep}
                            setOTPCode={setOTPCode}
                            translations={translations}
                        />
                    )}

                    {registrationStep === 3 && (
                        <Step2
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            repeatPassword={repeatPassword}
                            setRepeatPassword={setRepeatPassword}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            passwordError={passwordError}
                            setPasswordError={setPasswordError}
                            repeatPasswordError={repeatPasswordError}
                            setRepeatPasswordError={setRepeatPasswordError}
                            loading={loading}
                            setLoading={setLoading}
                            setRegistrationStep={setRegistrationStep}
                            language={language}
                            MainContext={MainContext}
                            OTPCode={OTPCode}
                            translations={translations}
                        />
                    )}



                    {registrationStep === 4 && (
                        <div className='register-form-options-components-button-final'>
                            <div className='register-form-options-components-button'>
                                <button
                                    disabled={loading}
                                    onClick={() => {
                                        if (!loading) {
                                            window.location.href = `/${language}/login`;
                                        }
                                    }}
                                >
                                    {loading ? (
                                        <span className="loading-dots">
                                            {translations.forgotPassword_holdOn}<span>.</span><span>.</span><span>.</span>
                                        </span>
                                    ) : (
                                        translations.forgotPassword_signIn
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    {registrationStep === 1 && (
                        <div className='register-form-register-cta'>
                            <p>{translations.forgotPassword_alreadyHaveAccount} <a href={`/${language}/login`}>{translations.forgotPassword_signInNow}</a></p>
                        </div>
                    )}
                </div>
            </div>
        </section >
    );
}

export default Register;