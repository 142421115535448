import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import GalleryLoader from './GalleryLoader';
import LoadingShares from '../public/GalleryLoader';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/profile.css';

function Profile() {
    const { author } = useParams();
    const { isAuthenticated, getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredUsername } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [userProfile, setUserProfile] = useState([]);

    const [generalLoading, setGeneralLoading] = useState(true);
    const [loadingShares, setLoadingShares] = useState(false);
    const [loadingMoreShares, setLoadingMoreShares] = useState(false);

    const profileImageUrl = ``;
    const firstShareImageUrl = ``;

    // Construct dynamic title and description
    const dynamicTitle = ``;
    const dynamicDescription = ``;

    // Construct dynamic keywords
    const dynamicKeywords = ``;


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setGeneralLoading(true);
        const savedShares = localStorage.getItem(`user-profile_${author}`);
        const profileSharesEnd = localStorage.getItem(`user-profile_${author}_end`) === 'true';

        if (savedShares) {
            // If shares exist in local storage, use them
            setUserProfile(JSON.parse(savedShares));
            setLoadingShares(false);
            setGeneralLoading(false);

            // Check the profileSharesEnd value and set loadingMoreShares accordingly
            if (profileSharesEnd) {
                setLoadingMoreShares(true);
            } else {
                setLoadingMoreShares(false);
            }
        } else {
            // Otherwise, load from API
            setLoadingShares(true);
            loadUserShares();
        }
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function loadUserShares() {
        const baseURL = `${MainContext.apiURL}/api/v1/public/users/profile/shares`;

        const data = new URLSearchParams({
            author: author,
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                if(response.status === 404) {
                    if(isAuthenticated) {
                        window.location.href = `/${language}/account/${localStoredUsername}/mural`;
                    } else {
                        window.location.href = `/${language}/public/mural`;
                    }
                } else if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setUserProfile(data.result);

                profileImageUrl = `https://photos.matchthelook.com/profilepictures/${data.result.user.profilePicture}`;
                const profileImageUrl = ``;
                firstShareImageUrl = userProfile.shares[0]
                    ? `https://photos.matchthelook.com/clothingfashion/${userProfile.shares[0].picture}.${userProfile.shares[0].extension}`
                    : '';

                // Construct dynamic title and description
                dynamicTitle = `${userProfile.user.name} ${userProfile.user.surname} - ${translations.seo_userProfile_title} Match the Look`;
                dynamicDescription = `${translations.seo_userProfile_discoverStyle} ${userProfile.user.name} ${userProfile.user.surname}. ${translations.seo_userProfile_discoverStyle_last}`;

                // Construct dynamic keywords
                dynamicKeywords = `fashion, style, outfit inspiration, ${userProfile.user.name}, ${userProfile.user.surname}, fashion shares, user profile, fashion creator, Instagram, TikTok, Facebook, YouTube, personalized styling, outfit ideas, clothing brands, wardrobe inspiration, clothing recommendations, moda, estilo, inspiração de looks, consultoria de moda com IA, estilista pessoal, inspiração de guarda-roupa, recomendações de roupas, buy clothes, outfit ideas, fashion tips, style improvement, clothing brands, where to buy outfits, inspiration for dressing well, shopping fashion online, affordable fashion, trendy clothing, shop the look, fashion advice, best fashion trends, seasonal outfits, outfit matching, AI style suggestions, personal shopper online, latest fashion trends, fashion influencers, fashion community, style boards, personalized styling, shop outfits online, unique fashion finds, dress better tips, comprar roupa, ideias de looks, dicas de moda, melhorar o estilo, marcas de roupa, onde comprar roupa, inspiração para vestir bem, compras de moda online, moda acessível, roupas da moda, inspiração de looks, melhores tendências de moda, looks sazonais, combinação de outfits, sugestões de estilo IA, personal shopper online, últimas tendências de moda, influenciadores de moda, comunidade de moda, painéis de estilo, estilo personalizado, comprar outfits online, moda única, dicas para vestir melhor`;

                // Save shares to local storage
                localStorage.setItem(`user-profile_${author}`, JSON.stringify(data.result));


                if (data.length === 35) {
                    setLoadingMoreShares(true);
                    localStorage.setItem(`user-profile_${author}_end`, JSON.stringify(data.end));
                }
                setLoadingShares(false);
                setGeneralLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoadingMoreShares(false);
                setLoadingShares(false);
                setGeneralLoading(false);
            });
    };

    return (
        <section className='public-profile-wrapper'>
            <div className='public-public-profile-wrapper'>
                {generalLoading ? (
                    <GalleryLoader />
                ) : (
                    <>
                        <div className='public-profile-wrapper-menu'>
                            <Helmet>
                                {/* General SEO Tags */}
                                <title>{dynamicTitle}</title>
                                <meta name="description" content={dynamicDescription} />
                                <meta name="keywords" content={dynamicKeywords} />

                                {/* Open Graph Tags */}
                                <meta property="og:title" content={dynamicTitle} />
                                <meta property="og:description" content={dynamicDescription} />
                                {/* Select first share image or profile image */}
                                <meta property="og:image" content={firstShareImageUrl || profileImageUrl} />
                                <meta property="og:image:alt" content={`${translations.seo_userProfile_imageAlt_profileImageOf} ${userProfile.user.name} ${userProfile.user.surname}`} />
                                <meta property="og:url" content={`https://matchthelook.com/${language}/${userProfile.user.name}`} />

                                {/* Open Graph for multiple images (optional, if you want to showcase more images) */}
                                {userProfile.shares && userProfile.shares.length > 0 && userProfile.shares.slice(0, 4).map((share, index) => (
                                    <meta key={`og-image-${index}`} property="og:image" content={`https://photos.matchthelook.com/clothingfashion/${share.picture}${share.extension}`} />
                                ))}

                                {/* Twitter Card Tags */}
                                <meta name="twitter:card" content="/logo.png" />
                                <meta name="twitter:title" content={dynamicTitle} />
                                <meta name="twitter:description" content={dynamicDescription} />
                                {/* Select first share image or profile image */}
                                <meta name="twitter:image" content={firstShareImageUrl || profileImageUrl} />
                                <meta name="twitter:image:alt" content={`${translations.seo_userProfile_imageAlt_profileImageOf} ${userProfile.user.name} ${userProfile.user.surname}`} />

                                {/* Twitter Card for multiple images (optional) */}
                                {userProfile.shares && userProfile.shares.length > 0 && userProfile.shares.slice(0, 4).map((share, index) => (
                                    <meta key={`twitter-image-${index}`} name="twitter:image" content={`https://photos.matchthelook.com/clothingfashion/${share.picture}${share.extension}`} />
                                ))}
                            </Helmet>
                            {userProfile.user.profilePicture ? (
                                <img src={`${MainContext.profilePictureURL}${userProfile.user.profilePicture}`} alt='User Profile' />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            )}
                            <h2>{userProfile.user.name} {userProfile.user.surname}</h2>
                            <h3>@{author}</h3>

                            <div className='public-profile-wrapper-menu-options-socials'>
                                {/* Facebook */}
                                {userProfile.user.socials?.facebook && (
                                    <a href={userProfile.user.socials.facebook.startsWith('http') ? userProfile.user.socials.facebook : `https://${userProfile.user.socials.facebook}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M16.9265 8.02637H13.9816C12.9378 8.02637 12.0894 8.86847 12.0817 9.91229L11.9964 21.4268M10.082 14.0017H14.8847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                )}

                                {/* Instagram */}
                                {userProfile.user.socials?.instagram && (
                                    <a href={userProfile.user.socials.instagram.startsWith('http') ? userProfile.user.socials.instagram : `https://${userProfile.user.socials.instagram}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                )}

                                {/* Tiktok */}
                                {userProfile.user.socials?.tiktok && (
                                    <a href={userProfile.user.socials.tiktok.startsWith('http') ? userProfile.user.socials.tiktok : `https://${userProfile.user.socials.tiktok}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                            <path d="M9.5 22C12.8137 22 15.5 19.3137 15.5 16V8.24537C16.5006 9.04749 17.6981 9.61412 19.0085 9.86122C19.3589 9.92728 19.5341 9.96032 19.7502 9.90446C20.007 9.83809 20.2923 9.6016 20.4051 9.36157C20.5 9.15952 20.5 8.93968 20.5 8.5C20.5 8.04137 20.5 7.81205 20.4499 7.65983C20.3671 7.4079 20.2952 7.31049 20.079 7.15694C19.9483 7.06416 19.6395 6.96876 19.022 6.77796C17.4492 6.29199 16.208 5.05079 15.722 3.47798C15.5312 2.86045 15.4358 2.55169 15.3431 2.42104C15.1895 2.20479 15.0921 2.13294 14.8402 2.05007C14.6879 2 14.4586 2 14 2C13.5341 2 13.3011 2 13.1173 2.07612C12.8723 2.17761 12.6776 2.37229 12.5761 2.61732C12.5 2.80109 12.5 3.03406 12.5 3.5V16C12.5 17.6569 11.1569 19 9.5 19C7.84315 19 6.5 17.6569 6.5 16C6.5 14.8644 7.13101 13.8761 8.06154 13.3667C8.75264 12.9884 9.0982 12.7992 9.19494 12.7057C9.38565 12.5214 9.39434 12.5068 9.46444 12.251C9.5 12.1212 9.5 11.9141 9.5 11.5C9.5 11.0747 9.5 10.8621 9.39825 10.6541C9.28169 10.4159 8.96391 10.1689 8.70429 10.1147C8.47765 10.0674 8.32349 10.1067 8.01518 10.1851C5.41964 10.8459 3.5 13.1988 3.5 16C3.5 19.3137 6.18629 22 9.5 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                )}

                                {/* Linkedin */}
                                {userProfile.user.socials?.linkedin && (
                                    <a href={userProfile.user.socials.linkedin.startsWith('http') ? userProfile.user.socials.linkedin : `https://${userProfile.user.socials.linkedin}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                            <path d="M4.5 9.5H4C3.05719 9.5 2.58579 9.5 2.29289 9.79289C2 10.0858 2 10.5572 2 11.5V20C2 20.9428 2 21.4142 2.29289 21.7071C2.58579 22 3.05719 22 4 22H4.5C5.44281 22 5.91421 22 6.20711 21.7071C6.5 21.4142 6.5 20.9428 6.5 20V11.5C6.5 10.5572 6.5 10.0858 6.20711 9.79289C5.91421 9.5 5.44281 9.5 4.5 9.5Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M6.5 4.25C6.5 5.49264 5.49264 6.5 4.25 6.5C3.00736 6.5 2 5.49264 2 4.25C2 3.00736 3.00736 2 4.25 2C5.49264 2 6.5 3.00736 6.5 4.25Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M12.326 9.5H11.5C10.5572 9.5 10.0858 9.5 9.79289 9.79289C9.5 10.0858 9.5 10.5572 9.5 11.5V20C9.5 20.9428 9.5 21.4142 9.79289 21.7071C10.0858 22 10.5572 22 11.5 22H12C12.9428 22 13.4142 22 13.7071 21.7071C14 21.4142 14 20.9428 14 20L14.0001 16.5001C14.0001 14.8433 14.5281 13.5001 16.0879 13.5001C16.8677 13.5001 17.5 14.1717 17.5 15.0001V19.5001C17.5 20.4429 17.5 20.9143 17.7929 21.2072C18.0857 21.5001 18.5572 21.5001 19.5 21.5001H19.9987C20.9413 21.5001 21.4126 21.5001 21.7055 21.2073C21.9984 20.9145 21.9985 20.4432 21.9987 19.5006L22.0001 14.0002C22.0001 11.515 19.6364 9.50024 17.2968 9.50024C15.9649 9.50024 14.7767 10.1531 14.0001 11.174C14 10.5439 14 10.2289 13.8632 9.995C13.7765 9.84686 13.6531 9.72353 13.505 9.63687C13.2711 9.5 12.9561 9.5 12.326 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                )}

                                {/* Youtube */}
                                {userProfile.user.socials?.youtube && (
                                    <a href={userProfile.user.socials.youtube.startsWith('http') ? userProfile.user.socials.youtube : `https://${userProfile.user.socials.youtube}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                                            <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                )}

                                {/* Other */}
                                {userProfile.user.socials?.other && (
                                    <a href={userProfile.user.socials.other.startsWith('http') ? userProfile.user.socials.other : `https://${userProfile.user.socials.other}`} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                        </svg>
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className='public-profile-wrapper-content'>
                            {loadingShares ? (
                                <LoadingShares />
                            ) : (
                                <>
                                    {userProfile.shares === 0 || userProfile.shares === '0' ? (
                                        <div className='public-profile-wrapper-content-noshares'>
                                            <span>{translations.public_profile_noShares}</span>
                                        </div>
                                    ) : (
                                        <UserShares
                                            author={author}
                                            imagesArray={userProfile.shares}
                                            loadingMoreShares={loadingMoreShares}
                                            language={language}
                                            translations={translations}
                                        />
                                    )}

                                </>

                            )}
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}

export default Profile;

function UserShares({ author, imagesArray, loadingMoreShares, language, translations }) {
    return (
        <div className='public-profile-wrapper-content-container'>
            <div className='public-profile-wrapper-content-grid'>
                {imagesArray.map((image, index) => (
                    <div key={index} className='public-profile-wrapper-content-grid-item'>
                        <a href={`/${language}/${author}/share/${image.picture}`}>
                            <img
                                src={`${MainContext.storageClothesURL}${image.picture}${image.extension}`}
                                alt={`${index + 1}`}
                                className='public-profile-wrapper-content-grid-image'
                            />
                        </a>
                    </div>
                ))}
            </div>
            {loadingMoreShares && (
                <div className='public-profile-wrapper-content-loadMore'>
                    <button disabled={loadingMoreShares}>
                        {loadingMoreShares ? (
                            <span className="public-profile-wrapper-content-loadMore-loading-dots">
                                {translations.public_profile_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            translations.public_profile_loadMore
                        )}
                    </button>
                </div>
            )}

        </div>
    );
}