import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import MainContext from '../../context/MainContext';
import FullWidthLoader from './FullWidthLoader';
import ConfirmDeleteShare from './DeleteShareConfirmation';
import LoginNowPrompt from '../auth/login/LoginNowPrompt';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/share.css'

const translationsLocal = {
    en: {
        // Clothing
        jacket: 'Jacket',
        blazer: 'Blazer',
        vest: 'Vest',
        sweater: 'Sweater',
        blouse: 'Blouse',
        dress: 'Dress',
        polo: 'Polo',
        shirt: 'Shirt',
        tshirt: 'T-shirt',
        pants: 'Pants',
        jeans: 'Jeans',
        shorts: 'Shorts',
        skirt: 'Skirt',
        pijama: 'Pajama',
        swimwear: 'Swimwear',
        boxers: 'Boxers',
        bra: 'Bra',
        panties: 'Panties',
        socks: 'Socks',
        other: 'Other',

        // Accessories
        cap: 'Cap',
        hat: 'Hat',
        gloves: 'Gloves',
        scarf: 'Scarf',
        watch: 'Watch',
        belt: 'Belt',
        handbag: 'Handbag',
        necklace: 'Necklace',
        ring: 'Ring',
        earrings: 'Earrings',
        bag: 'Bag',
        bagpack: 'Backpack',
        tie: 'Tie',
        bowtie: 'Bowtie',
        bracelet: 'Bracelet',
        wallet: 'Wallet',
        glasses: 'Glasses',

        // Footwear
        shoes: 'Shoes',
        sneakers: 'Sneakers',
        sandals: 'Sandals',
        mocassins: 'Mocassins',
        boots: 'Boots',
        ankleboots: 'Ankle Boots',
        highheels: 'High Heels',
        elegantshoes: 'Elegant Shoes',
        flipflops: 'Flip-flops',
        slippers: 'Slippers'
    },

    pt: {
        // Clothing
        jacket: 'Casaco',
        blazer: 'Blazer',
        vest: 'Colete',
        sweater: 'Camisola',
        blouse: 'Blusa',
        dress: 'Vestido',
        polo: 'Polo',
        shirt: 'Camisa',
        tshirt: 'T-shirt',
        pants: 'Calças',
        jeans: 'Calças de Ganga',
        shorts: 'Calções',
        skirt: 'Saia',
        pijama: 'Pijama',
        swimwear: 'Fato de banho',
        boxers: 'Boxers',
        bra: 'Sutiã',
        panties: 'Cuecas',
        socks: 'Meias',
        other: 'Outro',

        // Accessories
        cap: 'Boné',
        hat: 'Chapéu',
        gloves: 'Luvas',
        scarf: 'Cachecol',
        watch: 'Relógio',
        belt: 'Cinto',
        handbag: 'Mala',
        necklace: 'Colar',
        ring: 'Anel',
        earrings: 'Brincos',
        bag: 'Saco',
        bagpack: 'Mochila',
        tie: 'Gravata',
        bowtie: 'Laço',
        bracelet: 'Pulseira',
        wallet: 'Carteira',
        glasses: 'Óculos',

        // Footwear
        shoes: 'Sapatos',
        sneakers: 'Ténis',
        sandals: 'Sandálias',
        mocassins: 'Mocassins',
        boots: 'Botas',
        ankleboots: 'Botins',
        highheels: 'Saltos Altos',
        elegantshoes: 'Sapatos Elegantes',
        flipflops: 'Chinelos',
        slippers: 'Pantufas'
    }
};

function Share({ shareData, language, loading, like, setLike, save, setSave }) {
    const { isAuthenticated, getSessionInfo } = useAuth();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();

    const [noAuthInteraction, setNoAuthInteraction] = useState(false);

    const [deletingShare, setDeletingShare] = useState(false);

    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [confirmDeleteShare, setConfirmDeleteShare] = useState(false);
    const wrapperRef = useRef(null);

    const getRedirectUrl = () => {
        const referrer = document.referrer;
        const currentPath = window.location.pathname;

        const language = currentPath.split('/')[1];
        const author = currentPath.split('/')[2];

        if (!isAuthenticated && referrer.includes('/mural')) {
            return `/${language}/public/mural`;
        } else if (!isAuthenticated) {
            return `/${language}/${author}`;
        }

        if (currentPath.includes('/new-share')) {
            return `/${language}/account/${author}/new-share`;
        } else if (referrer.includes('/mural')) {
            return `/${language}/account/${author}/mural`;
        } else if (referrer.includes('/account/')) {
            return `/${language}/account/${author}`;
        } else if (referrer.includes('/dashboard')) {
            return `/${language}/account/${author}/mural`;
        } else if (referrer.includes('/wardrobe')) {
            return new URL(referrer).pathname;
        } else {
            return `/${language}/public/mural`;
        }
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSettingsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (settingsMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [settingsMenuOpen]);

    const createEditShareUrl = () => {
        const { picture } = shareData.share;
        return `/${language}/account/${localStoredUsername}/edit-share/${picture}`;
    };

    const confirmToDeleteShare = () => {
        setConfirmDeleteShare(true);
    };

    const deleteShare = () => {
        setConfirmDeleteShare(false);
        setDeletingShare(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/delete/share`;

        const formData = new URLSearchParams({
            picture: shareData.share.picture,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setDeletingShare(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const profileShares = JSON.parse(localStorage.getItem('profile-shares'));

                if (profileShares) {
                    const updatedShares = profileShares.filter(entry => entry.picture !== shareData.share.picture);

                    localStorage.setItem('profile-shares', JSON.stringify(updatedShares));
                }

                setDeletingShare(false);

                window.location.href = `/${language}/account/${localStoredUsername}`;
            })
            .catch(error => {
                console.error('Error updating share:', error);
                setDeletingShare(false);
            });
    }

    const likeShare = (action) => {
        if (!isAuthenticated) {
            return setNoAuthInteraction(true);
        }

        setLike(action);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/like/share`;
        const pictureId = shareData.share.picture;
        const storageKey = `share_${pictureId}`;

        const formData = new URLSearchParams({
            picture: pictureId,
            action: action
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const storedItem = localStorage.getItem(storageKey);
                if (storedItem) {
                    const itemData = JSON.parse(storedItem);
                    itemData.liked = action;
                    localStorage.setItem(storageKey, JSON.stringify(itemData));
                }
            })
            .catch(error => {
                console.error('Error updating share:', error);
            });
    };

    const saveShare = (action) => {
        if (!isAuthenticated) {
            return setNoAuthInteraction(true);
        }

        setSave(action);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/save/share`;
        const pictureId = shareData.share.picture;
        const storageKey = `share_${pictureId}`;

        const formData = new URLSearchParams({
            picture: pictureId,
            action: action
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const storedItem = localStorage.getItem(storageKey);
                if (storedItem) {
                    const itemData = JSON.parse(storedItem);
                    itemData.saved = action;
                    localStorage.setItem(storageKey, JSON.stringify(itemData));
                }
            })
            .catch(error => {
                console.error('Error updating share save:', error);
            });
    };

    return (
        <section className='share-wrapper'>
            <div className='share-picture-container'>
                <div className='share-picture-container-content'>
                    {loading ? (
                        <div className='share-information-references-loader-container'>
                            <span className="share-information-references-loader"></span>
                        </div>
                    ) : (
                        <img src={`${MainContext.storageClothesURL}${shareData.share.picture}${shareData.share.extension}`} alt='' />
                    )}
                </div>
            </div>
            <div className='share-information-container'>
                <div className='share-information-return'>
                    <a href={getRedirectUrl()} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </a>
                </div>


                <div className={loading ? ('share-information-references-wrapper-loading') : ('share-information-references-wrapper')}>
                    <div className='share-information-references-title'>
                        <div className='share-information-references-title-title'>
                            <h2>{translations.public_share_referencesFromLook}</h2>
                        </div>
                        {isAuthenticated && localStoredUsername === shareData.share.author && (
                            <div className='share-information-references-title-options' onClick={() => setSettingsMenuOpen(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M11.9959 12H12.0049" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9842 16H11.9932" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9998 8H12.0088" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="2" />
                                </svg>

                                {settingsMenuOpen && (
                                    <div ref={wrapperRef} className='share-information-references-options-background-wrapper'>
                                        <div className='share-information-references-options-background'>
                                            {localStoredUsername === shareData.share.author && (
                                                <>
                                                    <div className='share-information-references-options-modal'>
                                                        <a href={createEditShareUrl()}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path d="M14.0737 3.88545C14.8189 3.07808 15.1915 2.6744 15.5874 2.43893C16.5427 1.87076 17.7191 1.85309 18.6904 2.39232C19.0929 2.6158 19.4769 3.00812 20.245 3.79276C21.0131 4.5774 21.3972 4.96972 21.6159 5.38093C22.1438 6.37312 22.1265 7.57479 21.5703 8.5507C21.3398 8.95516 20.9446 9.33578 20.1543 10.097L10.7506 19.1543C9.25288 20.5969 8.504 21.3182 7.56806 21.6837C6.63212 22.0493 5.6032 22.0224 3.54536 21.9686L3.26538 21.9613C2.63891 21.9449 2.32567 21.9367 2.14359 21.73C1.9615 21.5234 1.98636 21.2043 2.03608 20.5662L2.06308 20.2197C2.20301 18.4235 2.27297 17.5255 2.62371 16.7182C2.97444 15.9109 3.57944 15.2555 4.78943 13.9445L14.0737 3.88545Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                                <path d="M13 4L20 11" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                                <path d="M14 22L22 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span>{translations.public_share_editShare}</span>
                                                        </a>
                                                    </div>
                                                    <div className='share-information-references-options-modal'>
                                                        <a onClick={confirmToDeleteShare} href={confirmToDeleteShare}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                            </svg>
                                                            <span>{translations.public_share_deleteShare}</span>
                                                        </a>
                                                    </div>
                                                </>
                                            )}
                                            {/* 
                                            <div className='share-information-references-options-modal'>
                                                <a href={`/${language}/report-problem`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                                    </svg>

                                                    <span>Reportar um Problema</span>
                                                </a>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                    </div>

                    {shareData.references.clothes?.count > 0 && !loading && (
                        <div className='share-information-references-subreferences'>
                            <div className='share-information-references-subreferences-title'>
                                <h3>{translations.public_share_piecesClothes}</h3>
                            </div>
                            <div className='share-information-references-subreferences-content'>
                                <div className='share-information-references-subreferences-content-grid'>
                                    {Object.entries(shareData.references.clothes.items).map(([name, link], index) => (
                                        link !== '' && (
                                            <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                                <button>
                                                    <img src={`/references/icon-${name}.png`} alt={name} />
                                                    <span>{translationsLocal[language][name]}</span>
                                                </button>
                                            </a>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {shareData.references.accessories?.count > 0 && !loading && (
                        <div className='share-information-references-subreferences'>
                            <div className='share-information-references-subreferences-title'>
                                <h3>{translations.public_share_piecesAccessories}</h3>
                            </div>
                            <div className='share-information-references-subreferences-content'>
                                <div className='share-information-references-subreferences-content-grid'>
                                    {Object.entries(shareData.references.accessories.items).map(([name, link], index) => (
                                        link !== '' && (
                                            <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                                <button>
                                                    <img src={`/references/icon-${name}.png`} alt={name} />
                                                    <span>{translationsLocal[language][name]}</span>
                                                </button>
                                            </a>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {shareData.references.footwear?.count > 0 && !loading && (
                        <div className='share-information-references-subreferences'>
                            <div className='share-information-references-subreferences-title'>
                                <h3>{translations.public_share_piecesFootwear}</h3>
                            </div>
                            <div className='share-information-references-subreferences-content'>
                                <div className='share-information-references-subreferences-content-grid'>
                                    {Object.entries(shareData.references.footwear.items).map(([name, link], index) => (
                                        link !== '' && (
                                            <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                                <button>
                                                    <img src={`/references/icon-${name}.png`} alt={name} />
                                                    <span>{translationsLocal[language][name]}</span>
                                                </button>
                                            </a>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {loading ? (
                    <>
                        <div className='share-information-references-loader-ref'>
                            <span class="share-information-references-loader-ref-loader"></span>
                        </div>
                        <div className='share-information-properties-container-loader'>
                            <div className='share-information-properties-loader-reflex'></div>
                        </div>
                    </>

                ) : (
                    <div className='share-information-properties-wrapper'>
                        <div className='share-information-properties-information'>
                            <div className='share-information-properties-container'>
                                <div className='share-information-properties-container-picture'>
                                    {shareData.share.profilePicture !== null ? (
                                        <img src={`${MainContext.profilePictureURL}${shareData.share.profilePicture}`} alt={`Author ${shareData.share.author} profile ${shareData.share.profilePicture}`} />
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                        </svg>
                                    )}
                                </div>
                            </div>
                            <div className='share-information-properties-container'>
                                <h2>{translations.public_share_author}</h2>
                                <span>
                                    @{shareData.share.author.length > 11
                                        ? `${shareData.share.author.slice(0, 11)}...`
                                        : shareData.share.author}
                                </span>
                            </div>

                            <div className='share-information-properties-container'>
                                <h2>{translations.public_share_gender}</h2>
                                <span>{shareData.share.gender}</span>
                            </div>
                            <div className='share-information-properties-container'>
                                <h2>{translations.public_share_style}</h2>
                                <span>{shareData.share.style}</span>
                            </div>
                        </div>
                        <div className='share-information-properties-container-actions'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => likeShare(false)} className='share-information-properties-container-actions-likeafter'>
                                    <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => likeShare(true)} className='share-information-properties-container-actions-likebefore'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                                </svg>
                            )}
                            {save ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => saveShare(false)} className='share-information-properties-container-actions-saveafter'>
                                    <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clip-rule="evenodd" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={() => saveShare(true)} className='share-information-properties-container-actions-savebefore'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                                </svg>

                            )}
                            <a href={`/${language}/${shareData.share.author}`}>
                                <button>
                                    <span>{translations.public_share_openProfile}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </a>

                        </div>
                    </div>
                )}

                {deletingShare && (
                    <FullWidthLoader
                        loading={true}
                    />
                )}

                {noAuthInteraction && (
                    <LoginNowPrompt
                        language={language}
                        setLoginNowPrompt={setNoAuthInteraction}
                    />
                )}

                {confirmDeleteShare && (
                    <ConfirmDeleteShare
                        deleteShare={deleteShare}
                        confirmDeleteShare={confirmDeleteShare}
                        setConfirmDeleteShare={setConfirmDeleteShare}
                    />
                )}
            </div >
        </section >
    );
}

export default Share;