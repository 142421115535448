import { useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Stylish.css';

function SECTIONSTYLISH() {
  const { language } = useContext(LanguageContext);
  const { getSessionInfo, isAuthenticated } = useAuth();
  const { localStoredUsername } = getSessionInfo();
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <div className="landingPage-stylish-wrapper">
      <div className='landingPage-stylish-content-wrapper'>
        <div className='landingPage-stylish-content'>
          <h2>{translations.landingPage_stylish_title01}<br /> {translations.landingPage_stylish_title02}</h2>
          <p>{translations.landingPage_stylish_description01}</p>
          <p>{translations.landingPage_stylish_description02} <b>{translations.landingPage_stylish_description03}</b> {translations.landingPage_stylish_description04}</p>
          <p>{translations.landingPage_stylish_description05} <b>{translations.landingPage_stylish_description06}</b> {translations.landingPage_stylish_description07}</p>
        </div>
        <div className='landingPage-stylish-image'>
          <img src='/landingPage/stylish_demo.png' alt='' />
        </div>
      </div>
      <div className='landingPage-stylish-cta'>
        <p>{translations.landingPage_stylish_ctaDescription}</p>
        {!isAuthenticated ? (
          <a href={`/${language}/register/premium`}><button>{translations.landingPage_stylish_ctaButton}</button></a>
        ) : (
          <a href={`/${language}/account/${localStoredUsername}/wardrobe`}><button>{translations.landingPage_stylish_ctaButton}</button></a>
        )}

      </div>
    </div>
  );
}

export default SECTIONSTYLISH;
