import React, { useEffect } from 'react';
import '../../../style/auth/register.css'

function Step2({
    name, surname, gender, birthday, country,
    email, setEmail,
    password, setPassword,
    repeatPassword, setRepeatPassword,
    emailError, setEmailError,
    passwordError, setPasswordError,
    repeatPasswordError, setRepeatPasswordError,
    loading,
    setLoading,
    setRegistrationStep,
    language,
    MainContext,
    translations
}) {

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    function validateToCreateAccount(e) {
        e.preventDefault();

        if (!validateEmail(email)) {
            setEmailError(translations.register_step2_notValidEmail);
            return;
        }

        if (password === '' || repeatPassword === '') {
            setPasswordError(translations.register_step2_passwordNotEmpty);
            setRepeatPasswordError(translations.register_step2_passwordNotEmpty);
            return;
        } else if (password !== repeatPassword) {
            setPasswordError(translations.register_step2_passwordDontMatch);
            setRepeatPasswordError(translations.register_step2_passwordDontMatch);
            return;
        }

        setEmailError('');
        setPasswordError('');
        setRepeatPasswordError('');

        verifyEmailAddress();

    }

    const verifyEmailAddress = () => {
        setLoading(true);
        const data = new URLSearchParams({
            email: email.toLowerCase(),
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/verify-email?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    setEmailError('');
                    setPasswordError('');
                    setRepeatPasswordError('');
                    registerUser();
                    return response.json();
                } else {
                    setLoading(false);
                    setEmailError(translations.register_step2_emailAlreadyHaveAccount);
                    setPasswordError('');
                    setRepeatPasswordError('');
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {

            })
            .catch(error => {
            });
    };

    const registerUser = () => {
        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/create-account`;

        const formData = new URLSearchParams({
            email: email.toLowerCase(),
            password: password,
            name: name,
            surname: surname,
            gender: gender,
            country: country,
            birthday: birthday,
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setRegistrationStep(3);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error creating account:', error);
                setLoading(false);
            });
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkPasswordStrength();
        checkRepeatPasswordStrength();
    }, [password, repeatPassword]);
    /* eslint-disable react-hooks/exhaustive-deps */

    function checkPasswordStrength() {
        const password = document.getElementById('password-input').value;
        const bars = [
            document.getElementById('register-step2-bar1'),
            document.getElementById('register-step2-bar2'),
            document.getElementById('register-step2-bar3'),
            document.getElementById('register-step2-bar4')
        ];
        const message = document.getElementById('register-step2-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = '#B29361';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.fontWeight = '400';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';
        message.style.fontFamily = 'Merriweather", serif'
        message.style.fontWeight = '400'
        message.style.fontStyle = 'normal'

        let strength = 0;
        if (password.length > 5) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[@$!%*?&#]/.test(password)) strength++;

        if (password.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#62A29A';
                    bars[1].style.backgroundColor = '#62A29A';
                    message.textContent = translations.register_step2_passwordOK;
                    message.style.color = '#62A29A';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = '#32745C';
                    bars[1].style.backgroundColor = '#32745C';
                    bars[2].style.backgroundColor = '#32745C';
                    message.textContent = translations.register_step2_passwordGood;
                    message.style.color = '#32745C';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = '#10443E';
                    bars[1].style.backgroundColor = '#10443E';
                    bars[2].style.backgroundColor = '#10443E';
                    bars[3].style.backgroundColor = '#10443E';
                    message.textContent = translations.register_step2_passwordStrong;
                    message.style.color = '#10443E';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.register_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontFamily = 'Merriweather", serif'
            message.style.fontWeight = '400'
            message.style.fontStyle = 'normal'
        }
    }

    function checkRepeatPasswordStrength() {
        const repeatPassword = document.getElementById('repeat-password-input').value;
        const bars = [
            document.getElementById('register-step2-repeat-bar1'),
            document.getElementById('register-step2-repeat-bar2'),
            document.getElementById('register-step2-repeat-bar3'),
            document.getElementById('register-step2-repeat-bar4')
        ];
        const message = document.getElementById('register-step2-repeat-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = '#B29361';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';
        message.style.fontFamily = 'Merriweather", serif'
        message.style.fontWeight = '400'
        message.style.fontStyle = 'normal'

        let strength = 0;
        if (repeatPassword.length > 5) strength++;
        if (/[A-Z]/.test(repeatPassword)) strength++;
        if (/[0-9]/.test(repeatPassword)) strength++;
        if (/[@$!%*?&#]/.test(repeatPassword)) strength++;

        if (repeatPassword.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.register_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#62A29A';
                    bars[1].style.backgroundColor = '#62A29A';
                    message.textContent = translations.register_step2_passwordOK;
                    message.style.color = '#62A29A';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = '#32745C';
                    bars[1].style.backgroundColor = '#32745C';
                    bars[2].style.backgroundColor = '#32745C';
                    message.textContent = translations.register_step2_passwordGood;
                    message.style.color = '#32745C';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = '#10443E';
                    bars[1].style.backgroundColor = '#10443E';
                    bars[2].style.backgroundColor = '#10443E';
                    bars[3].style.backgroundColor = '#10443E';
                    message.textContent = translations.register_step2_passwordStrong;
                    message.style.color = '#10443E';
                    message.style.fontWeight = '900';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.register_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontFamily = 'Merriweather", serif'
            message.style.fontWeight = '400'
            message.style.fontStyle = 'normal'
        }
    }

    return (
        <form className='register-form-options'>
            <div className={emailError ? 'register-form-options-components-error' : 'register-form-options-components'}>
                <label>{translations.register_step2_email}</label>
                <input
                    type='email'
                    placeholder={translations.register_step2_email}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError('');
                    }}
                />
                {emailError && (
                    <div className='register-form-options-components-error'>
                        <p>{emailError}</p>
                    </div>
                )}
            </div>

            <div className={passwordError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                <label>{translations.register_step2_password}</label>
                <input
                    type='password'
                    id='password-input'
                    placeholder={translations.register_step2_password}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                    }}
                />
                <div className='register-step2-password-strength-bars'>
                    <div className='register-step2-strength-bar' id='register-step2-bar1'></div>
                    <div className='register-step2-strength-bar' id='register-step2-bar2'></div>
                    <div className='register-step2-strength-bar' id='register-step2-bar3'></div>
                    <div className='register-step2-strength-bar' id='register-step2-bar4'></div>
                </div>
                <div id='register-step2-password-strength-message' className='register-step2-password-strength-message' />
                {passwordError && (
                    <div className='register-form-options-components-error'>
                        <p>{passwordError}</p>
                    </div>
                )}
            </div>
            <div className={repeatPasswordError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                <label>{translations.register_step2_repeatPassword}</label>
                <input
                    type='password'
                    id='repeat-password-input'
                    placeholder={translations.register_step2_repeatPassword}
                    value={repeatPassword}
                    onChange={(e) => {
                        setRepeatPassword(e.target.value);
                        setRepeatPasswordError('');
                    }}
                />
                <div className='register-step2-password-strength-bars'>
                    <div className='register-step2-strength-bar' id='register-step2-repeat-bar1'></div>
                    <div className='register-step2-strength-bar' id='register-step2-repeat-bar2'></div>
                    <div className='register-step2-strength-bar' id='register-step2-repeat-bar3'></div>
                    <div className='register-step2-strength-bar' id='register-step2-repeat-bar4'></div>
                </div>
                <div id='register-step2-repeat-password-strength-message' className='register-step2-password-strength-message' />
                {repeatPasswordError && (
                    <div className='register-form-options-components-error'>
                        <p>{repeatPasswordError}</p>
                    </div>
                )}
            </div>

            <div className='register-form-options-components-button'>
                <button disabled={loading} onClick={validateToCreateAccount}>
                    {loading ? (
                        <span className="loading-dots">
                            {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                        </span>
                    ) : (
                        translations.register_step2_createAccount
                    )}
                </button>
            </div>
        </form>
    );
}

export default Step2;