import React, { useState, useEffect, useRef } from 'react';
import '../../../style/auth/register.css'

function Step1({
    name, setName,
    surname, setSurname,
    gender, setGender,
    birthday, setBirthday,
    country, setCountry,
    nameError, setNameError,
    surnameError, setSurnameError,
    genderError, setGenderError,
    birthdayError, setBirthdayError,
    countryError, setCountryError,
    referralcode,
    setRegistrationStep,
    language,
    loading,
    MainContext,
    translations
}) {
    const [countryOptions, setCountryOptions] = useState([]);
    const previousValueRef = useRef('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCountryList();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const getCountryList = () => {
        const data = new URLSearchParams({
            language: language
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setCountryOptions(data.countries);
            })
            .catch(error => {

            });
    };

    const formatDate = (value) => {
        // Remove non-digit characters
        let cleaned = value.replace(/\D/g, '');
        
        // Automatically insert dashes as the user types
        if (cleaned.length >= 3 && cleaned.length <= 4) {
            cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2);
        } else if (cleaned.length >= 5) {
            cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2, 4) + '-' + cleaned.slice(4);
        }
    
        return cleaned.slice(0, 10); // Limit to DD-MM-YYYY format
    };
    
    const handleChangeBirthday = (e) => {
        setBirthdayError(false);
        const inputValue = e.target.value;
    
        const formattedDate = formatDate(inputValue);
        const [day, month, year] = formattedDate.split('-').map(Number);
    
        const isFourDigitYear = year && year.toString().length === 4;
    
        setBirthday(formattedDate);
        previousValueRef.current = formattedDate;
    
        const isValidDate =
            (day >= 1 && day <= 31) &&
            (month >= 1 && month <= 12) &&
            isFourDigitYear;
    
        if (!isValidDate) {
        }
    };    

    function validateBeforeMovingToStep2(e) {
        e.preventDefault();

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const [day, month, year] = birthday.split('-').map(Number);

        const age = currentYear - year;

        if (age < 18 || age >= 100 || !day || !month || !year) {
            setBirthdayError(true);

            if (name === '') {
                setNameError(true);
            }

            if (surname === '') {
                setSurnameError(true);
            }

            if (gender === '') {
                setGenderError(true);
            }

            if (country === '') {
                setCountryError(true)
            }
            return;
        } else if (name === '' || surname === '' || gender === '' || country === '') {
            if (name === '') {
                setNameError(true);
            }

            if (surname === '') {
                setSurnameError(true);
            }

            if (gender === '') {
                setGenderError(true);
            }

            if (country === '') {
                setCountryError(true)
            }
            return;
        }

        setRegistrationStep(2);
    };

    return (

        <form className='register-form-options'>
            <div className='register-form-options-components-divider'>
                <div className={nameError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                    <label>{translations.register_step1_name}</label>
                    <input
                        type='text'
                        placeholder={translations.register_step1_name}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setNameError(false);
                        }}
                    />
                </div>
                <div className={surnameError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                    <label>{translations.register_step1_surname}</label>
                    <input
                        type='text'
                        placeholder={translations.register_step1_surname}
                        value={surname}
                        onChange={(e) => {
                            setSurname(e.target.value);
                            setSurnameError(false);
                        }}
                    />
                </div>
            </div>
            <div className='register-form-options-components-divider'>
                <div className={genderError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                    <label>{translations.register_step1_gender}</label>
                    <select
                        value={gender}
                        onChange={(e) => {
                            setGender(e.target.value);
                            setGenderError(false);
                        }}>
                        <option value='' disabled selected>{translations.register_step1_selectGender}</option>
                        <option value='man'>{translations.register_step1_genderMan}</option>
                        <option value='woman'>{translations.register_step1_genderWoman}</option>
                    </select>
                </div>
                <div className={birthdayError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                    <label>{translations.register_step1_birthday}</label>
                    <input
                        type='text'
                        placeholder='DD-MM-YYYY'
                        value={birthday}
                        onChange={handleChangeBirthday}
                        pattern='(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[0-2])-(?:19|20)[0-9]{2}'
                    />
                </div>
            </div>

            <div className='register-form-options-components-divider'>
                <div className={genderError ? ('register-form-options-components-error') : ('register-form-options-components')}>
                    <label>{translations.register_step1_country}</label>
                    <select
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value);
                            setCountryError(false);
                        }}>
                        <option value='' disabled selected>{translations.register_step1_selectCountry}</option>
                        {countryOptions.map((nationality, index) => (
                            <option key={index} value={nationality.value}>
                                {nationality.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='register-form-options-components'>
                    {referralcode && (
                        <>
                            <label>{translations.register_step1_referralCode}</label>
                            <input
                                type='text'
                                placeholder={translations.register_step1_referralCode}
                                value={referralcode}
                                disabled
                                readOnly
                            />
                        </>
                    )}
                </div>

            </div>
            <div className='register-form-options-components-button'>
                <button disabled={loading} onClick={validateBeforeMovingToStep2}>
                    {loading ? (
                        <span className="loading-dots">
                            {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                        </span>
                    ) : (
                        translations.register_step1_continue
                    )}
                </button>
            </div>
        </form>
    );
}

export default Step1;