import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Element, scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import MENUTOP from '../../pages/public/landingPage/MenuTop'
import SECTIONHERO from '../../pages/public/landingPage/Section_Hero'
import SECTIONHOWITWORKS from '../../pages/public/landingPage/Section_HowItWorks'
import SECTIONAI from '../../pages/public/landingPage/Section_AI'
import SECTIONSTYLISH from '../../pages/public/landingPage/Section_Stylish'
import SECTIONPRICING from '../../pages/public/landingPage/Section_Pricing'
import SECTIONFAQ from '../../pages/public/landingPage/Section_FAQ'
import SECTIONCTA from '../../pages/public/landingPage/Section_CTA'
import SECTIONCONTACTS from '../../pages/public/landingPage/Section_Contacts'
import MENUFOOTER from '../../pages/public/landingPage/MenuFooter'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/common.css';

const HOOKERLANDINGPAGE = () => {
    const location = useLocation();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [backToTopVisible, setBackToTopVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setBackToTopVisible(true);
        } else {
            setBackToTopVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (location.hash) {
            const section = location.hash.replace('#', '');
            scroller.scrollTo(section, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, [location]);

    return (
        <>
            <section className='common-public-wrapper'>
                <Helmet>
                    <title>{translations.seo_landingPage_title}</title>
                    <meta name="description" content={translations.seo_landingPage_description} />
                    <meta name="keywords" content="fashion, style, outfit inspiration, AI fashion advisor, personal stylist, fashion SaaS, wardrobe inspiration, clothing recommendations, moda, estilo, inspiração de looks, consultoria de moda com IA, estilista pessoal, inspiração de guarda-roupa, recomendações de roupas, buy clothes, outfit ideas, fashion tips, style improvement, clothing brands, where to buy outfits, inspiration for dressing well, shopping fashion online, affordable fashion, trendy clothing, shop the look, fashion advice, best fashion trends, seasonal outfits, outfit matching, AI style suggestions, personal shopper online, latest fashion trends, fashion influencers, fashion community, style boards, personalized styling, shop outfits online, unique fashion finds, dress better tips, comprar roupa, ideias de looks, dicas de moda, melhorar o estilo, marcas de roupa, onde comprar roupa, inspiração para vestir bem, compras de moda online, moda acessível, roupas da moda, inspiração de looks, melhores tendências de moda, looks sazonais, combinação de outfits, sugestões de estilo IA, personal shopper online, últimas tendências de moda, influenciadores de moda, comunidade de moda, painéis de estilo, estilo personalizado, comprar outfits online, moda única, dicas para vestir melhor" />
                    
                    {/* Open Graph tags for social sharing */}
                    <meta property="og:title" content={translations.seo_landingPage_orgTitle} />
                    <meta property="og:description" content={translations.seo_landingPage_orgDescription} />
                    <meta property="og:image" content="[URL_TO_HERO_IMAGE]" />
                    <meta property="og:url" content="https://matchthelook.com" />
                    <meta property="og:type" content="website" />

                    {/* Twitter Card metadata */}
                    <meta name="twitter:card" content="/logo.png" />
                    <meta name="twitter:title" content={translations.seo_landingPage_twitterTitle} />
                    <meta name="twitter:description" content={translations.seo_landingPage_twitterDescription} />
                    <meta name="twitter:image" content="/logo.png" />

                    {/* Language settings for bilingual audience */}
                    <link rel="alternate" href="https://matchthelook.com" hreflang="en" />
                    <link rel="alternate" href="https://matchthelook.com/pt" hreflang="pt" />
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>
                <MENUTOP />
                <Element name="home" className='common-public-element'><SECTIONHERO /></Element>
                <Element name="how-it-works" className='common-public-element'><SECTIONHOWITWORKS /></Element>
                <Element name="ai" className='common-public-element'><SECTIONAI /></Element>
                <Element name="stylish-program" className='common-public-element'><SECTIONSTYLISH /></Element>
                <Element name="pricing" className='common-public-element'><SECTIONPRICING /></Element>
                <Element name="faq" className='common-public-element'><SECTIONFAQ /></Element>
                <Element name="start-now" className='common-public-element'><SECTIONCTA /></Element>
                <Element name="contacts" className='common-public-element'><SECTIONCONTACTS /></Element>
                <MENUFOOTER />

                {backToTopVisible && (
                    <div className='landingpage-public-backtotop-container'>
                        <button className="landingpage-public-backtotop-button" onClick={scrollToTop}>
                            <svg className="landingpage-public-backtotop-svgIcon" viewBox="0 0 384 512">
                                <path
                                    d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                                ></path>
                            </svg>
                            <span className="landingpage-public-backtotop-text">{translations.landingPage_hooker_backToTop}</span>
                        </button>
                    </div>
                )}

            </section>
        </>
    );
};

export default HOOKERLANDINGPAGE;
