import React, { useState, useContext, useRef, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import AINOACCESS from '../../pages/private/AI/AI_NoAccess'
import '../../style/private/ai.css';
import '../../style/private/ai/ai_images.css';

function AI() {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const { localStoredGender, localStoredEmail, localStoredUsername, localStoredCountry, localStoredSession, localStoredBirthday, localStoredSubscription } = getSessionInfo();

    const textareaRef = useRef(null);

    const [promptSubmited, setPromptSubmited] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [typingMessage, setTypingMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [conversationID, setConversationID] = useState('');
    const [lastAIResponse, setLastAIResponse] = useState('');
    const messagesEndRef = useRef(null);
    const [exchangeNumber, setExchangeNumber] = useState(0);

    const [imageLoading, setImageLoading] = useState(false);
    const [generatedImages, setGeneratedImages] = useState([]);

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);

        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleSubmit = () => {
        // After submitting, reset the height
        const textarea = document.querySelector('.input-ai-wrapper textarea');
        textarea.style.height = 'auto'; // Reset height before submit

        setPromptSubmited(true);
        setIsTyping(true);
        setExchangeNumber(exchangeNumber + 1);

        if (prompt.trim()) {
            setLoading(true);
            setMessages(prevMessages => [...prevMessages, { type: 'user', text: prompt }]);
            setPrompt('');

            const baseURL = `${MainContext.apiURL}/api/v1/private/users/chat/ai`;
            const formData = new URLSearchParams({
                conversation: conversationID,
                country: localStoredCountry,
                gender: localStoredGender,
                messages: JSON.stringify(messages),
                prompt: prompt,
            });

            fetch(baseURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession
                },
                credentials: 'include',
                body: formData.toString(),
            })
                .then(response => {
                    if (!response.ok) {
                        setLoading(false);
                        simulateTyping(translations.private_ai_interaction_error);
                        setPromptSubmited(false);
                        return Promise.reject({ status: response.status, statusText: response.statusText });
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    setLoading(false);

                    if (data.response !== lastAIResponse) {
                        setLastAIResponse(data.response);
                        simulateTyping(data.response);
                    }

                    if (!conversationID) {
                        setConversationID(data.conversation);
                    }

                    setPromptSubmited(false);
                })
                .catch(error => {
                    setPromptSubmited(false);
                });
        }
    };

    const simulateTyping = (text) => {
        let index = 0;
        setTypingMessage(''); // Reset typing message
        setMessages(prevMessages => [...prevMessages, { type: 'ai', text: '' }]); // Add new message

        // Scroll to the bottom
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        const typeInterval = setInterval(() => {
            if (index < text.length) {
                // Update typing message correctly
                setTypingMessage(prev => prev + text.charAt(index));
                index++;
            } else {
                clearInterval(typeInterval);
                setIsTyping(false);

                // Once typing is done, set the final message
                setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1] = { type: 'ai', text: text }; // Update the last message with the full text
                    return newMessages;
                });
            }

            // Scroll to the bottom
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1);
    };

    const formatText = (text) => {
        const formattedText = text
            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
            .replace(/### (.*?)\n/g, '<h3>$1</h3>\n')
            .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');
        return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    useEffect(() => {
        if (typingMessage && messages.length > 0) {
            const lastMessageIndex = messages.length - 1;
            setMessages(prevMessages => {
                if (prevMessages[lastMessageIndex].text !== typingMessage) {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[lastMessageIndex].text = typingMessage;
                    return updatedMessages;
                }
                return prevMessages;
            });
        }
    }, [typingMessage]);

    useEffect(() => {
        const aiMessages = messages.filter(message => message.type === 'ai');
        if (aiMessages.length > 0) {
            setLastAIResponse(aiMessages[aiMessages.length - 1].text);
        }
    }, [messages]);

    useEffect(() => {
        // Check if the ref is set before adding the event listener
        if (textareaRef.current) {
            const textarea = textareaRef.current;

            const resizeTextarea = () => {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            };

            textarea.addEventListener('input', resizeTextarea);

            // Cleanup event listener on component unmount
            return () => {
                textarea.removeEventListener('input', resizeTextarea);
            };
        }
    }, []);

    const generatesImage = (index) => {
        if (localStoredSubscription === 'free') {
            window.location.href = `/${language}/account/${localStoredUsername}/subscription`;
            return;
        }

        setImageLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/generate-image/ai`;

        const formData = new URLSearchParams({
            country: localStoredCountry,
            gender: localStoredGender,
            prompt: prompt,
            response: lastAIResponse,
            conversationID: conversationID,
            birthday: localStoredBirthday,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setImageLoading(false);
                    simulateTyping(translations.private_ai_interaction_error);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setImageLoading(false);

                const image = `${data.image}${data.extension}`;

                setGeneratedImages((prevImages) => {
                    const newImages = [...prevImages];
                    newImages[index] = image;
                    return newImages;
                });
            })
            .catch(error => {
                setImageLoading(false);
            });
    };



    return (
        <section className={messages.length === 0 ? ('page-ai-wrapper') : ('page-ai-wrapper-after')}>

            <div className='page-ai-content'>
                <h2>{translations.private_ai_title}</h2>

                {messages.length === 0 && (
                    <>
                        <div className='page-ai-prompt'>
                            <div className="input-ai-wrapper">
                                <textarea placeholder={translations.private_ai_inputPlaceholder} value={prompt} onChange={handlePromptChange} disabled={promptSubmited} onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) { e.preventDefault(); handleSubmit(); } }} />
                                <button className="input-ai-icon-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                        <path d="M16 13.5C16 13.5 13.054 10.5 12 10.5C10.9459 10.5 8 13.5 8 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className='page-ai-content-buttons'>
                            <button disabled>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M16.0001 16.5L20 20.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18 11.5C18 15.366 14.866 18.5 11 18.5C7.13401 18.5 4 15.366 4 11.5C4 7.63404 7.13401 4.50003 11 4.50003" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.5 3.50003L15.7579 4.19706C16.0961 5.11105 16.2652 5.56805 16.5986 5.90142C16.932 6.2348 17.389 6.4039 18.303 6.74211L19 7.00003L18.303 7.25795C17.389 7.59616 16.932 7.76527 16.5986 8.09864C16.2652 8.43201 16.0961 8.88901 15.7579 9.803L15.5 10.5L15.2421 9.803C14.9039 8.88901 14.7348 8.43201 14.4014 8.09864C14.068 7.76527 13.611 7.59616 12.697 7.25795L12 7.00003L12.697 6.74211C13.611 6.4039 14.068 6.2348 14.4014 5.90142C14.7348 5.56805 14.9039 5.11105 15.2421 4.19706L15.5 3.50003Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {translations.private_ai_uploadAndAnalyze}

                                <div className='page-ai-content-buttons-soon'>
                                    <span>{translations.private_ai_comingSoon}</span>
                                </div>
                            </button>
                        </div>
                    </>
                )}

                {messages.length > 0 && (
                    <>
                        <div className='page-ai-interaction-wrapper'>
                            <div className='page-ai-interaction-messages'>
                                {messages.map((message, index) => (
                                    <>
                                        <div key={index} className={`message-bubble ${message.type === 'user' ? 'user-message' : 'ai-message'}`}>
                                            {message.type === 'ai' ? formatText(message.text) : <span>{message.text}</span>}
                                        </div>

                                        {message.type === 'ai' && generatedImages[index] && (
                                            <div className='page-ai-interaction-message-picture-generated'>
                                                <img src={`${MainContext.generatedImages}${generatedImages[index]}`} alt='Generated with Artificial Intelligence' />
                                            </div>
                                        )}

                                        {/* Generate Image button, only for AI messages */}
                                        {message.type === 'ai' && !loading && !imageLoading && !isTyping && !generatedImages[index] &&
                                            index === messages.map((m, i) => m.type === 'ai' ? i : null).filter(i => i !== null).pop() && (
                                                <div className='page-ai-interaction-message-button-generate-image'>
                                                    {localStoredSubscription === 'free' ? (
                                                        <button onClick={() => generatesImage(index)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                                                <path d="M12.3077 18L10.847 13.4789C10.7552 13.1947 10.4734 13 10.1538 13C9.83425 13 9.55249 13.1947 9.46066 13.4789L8 18M15 13V18M8.53846 16.5H11.7692" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12104 13.2453 4 14.3624 4 15.5C4 16.6376 4.12104 17.7547 4.26781 18.8447Z" stroke="currentColor" stroke-width="1.5" />
                                                                <path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            {translations.private_ai_upgradeToGenerateImages}
                                                        </button>
                                                    ) : (
                                                        <button onClick={() => generatesImage(index)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                                                <circle cx="7" cy="8" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M20.9977 11C21 11.4701 21 11.9693 21 12.5C21 16.9783 21 19.2175 19.6088 20.6088C18.2175 22 15.9783 22 11.5 22C7.02166 22 4.78249 22 3.39124 20.6088C2 19.2175 2 16.9783 2 12.5C2 8.02166 2 5.78249 3.39124 4.39124C4.78249 3 7.02166 3 11.5 3C12.0307 3 12.5299 3 13 3.00231" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M18.5 2L18.7579 2.69703C19.0961 3.61102 19.2652 4.06802 19.5986 4.40139C19.932 4.73477 20.389 4.90387 21.303 5.24208L22 5.5L21.303 5.75792C20.389 6.09613 19.932 6.26524 19.5986 6.59861C19.2652 6.93198 19.0961 7.38898 18.7579 8.30297L18.5 9L18.2421 8.30297C17.9039 7.38898 17.7348 6.93198 17.4014 6.59861C17.068 6.26524 16.611 6.09613 15.697 5.75792L15 5.5L15.697 5.24208C16.611 4.90387 17.068 4.73477 17.4014 4.40139C17.7348 4.06802 17.9039 3.61102 18.2421 2.69703L18.5 2Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                                <path d="M4.5 21.5C8.87246 16.275 13.7741 9.38406 20.9975 14.0424" stroke="currentColor" stroke-width="1.5" />
                                                            </svg>
                                                            {translations.private_ai_generateImageOnThisLook}
                                                        </button>
                                                    )}

                                                </div>
                                            )}

                                    </>
                                ))}

                                {loading && (
                                    <>
                                        <div className='page-ai-interaction-loader-wrapper'>
                                            <span className="page-ai-interaction-loader01"></span>
                                        </div>
                                    </>
                                )}

                                {imageLoading && (
                                    <>
                                        <div className='page-ai-interaction-message-picture'>
                                            <span class="page-ai-interaction-message-picture-loader"></span>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className='page-ai-prompt'>
                                <div className="input-ai-wrapper">
                                    <textarea placeholder={translations.private_ai_inputPlaceholder} value={prompt} onChange={handlePromptChange} disabled={isTyping} onKeyDown={(e) => { if (e.key === 'Enter' && !e.shiftKey) { e.preventDefault(); handleSubmit(); } }} />
                                    <button className="input-ai-icon-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M16 13.5C16 13.5 13.054 10.5 12 10.5C10.9459 10.5 8 13.5 8 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </>
                )}

            </div>

        </section>
    );
}

export default AI;