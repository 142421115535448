import React, { useState, useContext} from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import '../../style/private/wardrobe_item.css';

const images = [
    { auhor: 'stylingmen1', image: "f5333a14-d657-4d7d-ab71-2af5c59fea81", src: 'https://photos.matchthelook.com/clothingfashion/f5333a14-d657-4d7d-ab71-2af5c59fea81.jpg', alt: '@stylingmen1 share on elegant style' },
    { auhor: 'stylingmen1', image: "f5333a14-d657-4d7d-ab71-2af5c59fea81", src: 'https://photos.matchthelook.com/clothingfashion/f5333a14-d657-4d7d-ab71-2af5c59fea81.jpg', alt: '@stylingmen1 share on elegant style' },
    { auhor: 'stylingmen1', image: "f5333a14-d657-4d7d-ab71-2af5c59fea81", src: 'https://photos.matchthelook.com/clothingfashion/f5333a14-d657-4d7d-ab71-2af5c59fea81.jpg', alt: '@stylingmen1 share on elegant style' },
];

function WardrobeItem() {
    const [loading, setLoading] = useState(images.map(() => true));
    const { language } = useContext(LanguageContext);
    const { getSessionInfo } = useAuth();
    const { localStoredUsername } = getSessionInfo();
    const date = '01-10-2024';
    const [rating, setRating] = useState('3');
    
    // eslint-disable-next-line
    const [ratingProvided, setRatingProvided] = useState(true);
    const [openRatingFeedback, setOpenRatingFeedback] = useState(false);
    const [ratingDescription, setRatingDescription] = useState('');

    const handleImageLoad = (index) => {
        setLoading((prevState) => {
            const updatedLoading = [...prevState];
            updatedLoading[index] = false;
            return updatedLoading;
        });
    };

    const handleRatingChange = (event) => {
        setRating(event.target.value);
        setOpenRatingFeedback(true);

        document.body.style.overflow = 'hidden';
    };

    const closeRatingFeedback = () => {
        setRating(null);
        setRatingDescription('');
        document.body.style.overflow = '';
        setOpenRatingFeedback(false);
    };

    return (
        <section className='wardrobeitem-content'>
            <div className='wardrobeitem-content-wrapper'>
                <div className='wardrobeitem-content-return'>
                    <a href={`/${language}/account/${localStoredUsername}/wardrobe`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M3.99982 11.9998L19.9998 11.9998" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.99963 17C8.99963 17 3.99968 13.3176 3.99966 12C3.99965 10.6824 8.99966 7 8.99966 7" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
                <div className='wardrobeitem-title'>
                    <div className='wardrobeitem-title-title'>
                        <h1>O teu guarda-roupa personalizado!</h1>
                        <p>Publicações sugeridas a {date}</p>
                    </div>
                    <div className='wardrobeitem-title-rating'>
                        {!ratingProvided ? (
                            <>
                                <span>Classifica estas sugestões</span>
                                <div className="wardrobeitem-title-rating-stars">
                                    <input value="5" name="rate" id="star5" type="radio" onChange={handleRatingChange} checked={rating === "5"} />
                                    <label title="Perfeito" htmlFor="star5"></label>
                                    <input value="4" name="rate" id="star4" type="radio" onChange={handleRatingChange} checked={rating === "4"} />
                                    <label title="Bom" htmlFor="star4"></label>
                                    <input value="3" name="rate" id="star3" type="radio" onChange={handleRatingChange} checked={rating === "3"} />
                                    <label title="Neutro" htmlFor="star3"></label>
                                    <input value="2" name="rate" id="star2" type="radio" onChange={handleRatingChange} checked={rating === "2"} />
                                    <label title="Mau" htmlFor="star2"></label>
                                    <input value="1" name="rate" id="star1" type="radio" onChange={handleRatingChange} checked={rating === "1"} />
                                    <label title="Detesto" htmlFor="star1"></label>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>A tua classificação destas sugestões</span>
                                <div className="wardrobeitem-title-rating-stars-disabled">
                                    <input value="5" name="rate" id="star5" type="radio" checked={rating === "5"} />
                                    <label title="Perfeito" htmlFor="star5"></label>
                                    <input value="4" name="rate" id="star4" type="radio" checked={rating === "4"} />
                                    <label title="Bom" htmlFor="star4"></label>
                                    <input value="3" name="rate" id="star3" type="radio" checked={rating === "3"} />
                                    <label title="Neutro" htmlFor="star3"></label>
                                    <input value="2" name="rate" id="star2" type="radio" checked={rating === "2"} />
                                    <label title="Mau" htmlFor="star2"></label>
                                    <input value="1" name="rate" id="star1" type="radio" checked={rating === "1"} />
                                    <label title="Detesto" htmlFor="star1"></label>
                                </div>
                            </>
                        )}

                    </div>
                </div>

                <div className='wardrobeitem-grid-container'>
                    {images.map((image, index) => (
                        <div key={index} className='wardrobeitem-grid-item'>
                            <a href={`/${language}/${images.author}/share/${images.image}`}>
                                {loading[index] && (
                                    <div className='wardrobeitem-grid-item-loading'>
                                        <div className="wardrobeitem-grid-loader"></div>
                                    </div>
                                )}
                                <img
                                    src={image.src}
                                    alt={image.alt}
                                    onLoad={() => handleImageLoad(index)}
                                    style={{ display: loading[index] ? 'none' : 'block' }}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {openRatingFeedback && (
                <div className='wardobeitem-feedback-wrapper-background'>
                    <div className='wardobeitem-feedback-wrapper'>
                        <div className='wardobeitem-feedback-title'>
                            <div className='wardobeitem-feedback-title-title'>
                                <h2>Ajuda-nos a melhorar!</h2>
                            </div>
                            <div className='wardobeitem-feedback-title-close'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeRatingFeedback}>
                                    <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='wardobeitem-feedback-rating'>
                            <h3>A tua avaliação das nossas sugestões</h3>
                            <div className="wardrobeitem-title-rating-stars">
                                <input value="5" name="rate" id="star5" type="radio" onChange={handleRatingChange} checked={rating === "5"} />
                                <label title="Perfeito" htmlFor="star5"></label>
                                <input value="4" name="rate" id="star4" type="radio" onChange={handleRatingChange} checked={rating === "4"} />
                                <label title="Bom" htmlFor="star4"></label>
                                <input value="3" name="rate" id="star3" type="radio" onChange={handleRatingChange} checked={rating === "3"} />
                                <label title="Neutro" htmlFor="star3"></label>
                                <input value="2" name="rate" id="star2" type="radio" onChange={handleRatingChange} checked={rating === "2"} />
                                <label title="Mau" htmlFor="star2"></label>
                                <input value="1" name="rate" id="star1" type="radio" onChange={handleRatingChange} checked={rating === "1"} />
                                <label title="Detesto" htmlFor="star1"></label>
                            </div>
                        </div>
                        <div className='wardobeitem-feedback-message'>
                            <h4>Feedback adicional</h4>
                            <textarea placeholder='Dicas ou sugestões para melhorarmos as nossas sugestões para ti' value={ratingDescription} onChange={(e) => setRatingDescription(e.target.value)} />
                        </div>
                        <div className='wardobeitem-feedback-send'>
                            <button>Submeter feedback</button>
                        </div>

                    </div>
                </div>
            )}
        </section>
    );
}

export default WardrobeItem;