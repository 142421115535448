import React from 'react';
import '../../../style/auth/register.css'

function Step4({
    email,
    language,
    loading,
    setLoading,
    MainContext,
    setRegistrationStep,
    translations
}) {

    const createCheckoutSession = async (plan) => {

        if(plan === 'free') {
            return setRegistrationStep(5);
        }

        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/public/users/pay/subscription`;

        const formData = new URLSearchParams({
            email: email,
            page: 'new',
            language: language,
            plan: plan
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const currentTimestampUTC = new Date().toISOString();

                const paymentIntentObject = {
                    timestamp: currentTimestampUTC
                };

                localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
                localStorage.setItem('subscription-payment-intent-plan', plan);

                setLoading(false);

                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
            });
    };

    return (
        <div className='register-form-activate-account-wrapper'>
            <div className='register-form-plans-tiles-wrapper'>
                <div className='register-form-plans-tile-free'>
                    <h2>{translations.register_step4_freePlan}</h2>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_imageSharing}</span>
                    <span className='register-form-plans-tile-span-disable'>{translations.register_step4_AIAccess}</span>
                    <span className='register-form-plans-tile-span-disable'>{translations.register_step4_personalizedLooks}</span>
                    <button disabled={loading} onClick={() => createCheckoutSession('free')}>
                        {loading ? (
                            <span className="loading-dots">
                                {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            <>
                                {translations.register_step4_continueFree}
                            </>
                        )}
                    </button>
                    <div className='register-form-plans-tile-selected'>
                        {translations.register_step4_selected}
                    </div>
                </div>
                <div className='register-form-plans-tile'>
                    <h2>{translations.register_step4_plusPlan} <label>- 9.99€/{translations.register_step4_month}</label></h2>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_imageSharing}</span>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_AIAccess}</span>
                    <span className='register-form-plans-tile-span-disable'>{translations.register_step4_personalizedLooks}</span>
                    <button disabled={loading} onClick={() => createCheckoutSession('plus')}>
                        {loading ? (
                            <span className="loading-dots">
                                {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            <>
                                {translations.register_step4_plusUpgrade}
                            </>
                        )}
                    </button>
                </div>
                <div className='register-form-plans-tile'>
                    <h2>{translations.register_step4_premiumPlan} <label>- 25.99€/{translations.register_step4_month}</label></h2>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_imageSharing}</span>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_AIAccess}</span>
                    <span className='register-form-plans-tile-span-active'>{translations.register_step4_personalizedLooks}</span>
                    <button disabled={loading} onClick={() => createCheckoutSession('premium')}>
                        {loading ? (
                            <span className="loading-dots">
                                {translations.register_holdOn}<span>.</span><span>.</span><span>.</span>
                            </span>
                        ) : (
                            <>
                                {translations.register_step4_premiumUpgrade}
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Step4;