import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_FAQ.css';

function SECTIONFAQ() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const faqs = [
    {
      title: translations.landingPage_pricing_question1,
      answer: translations.landingPage_pricing_question1_answer
    },
    {
      title: translations.landingPage_pricing_question2,
      answer: translations.landingPage_pricing_question2_answer
    },
    {
      title: translations.landingPage_pricing_question3,
      answer: translations.landingPage_pricing_question3_answer
    },
    {
      title: translations.landingPage_pricing_question4,
      answer: translations.landingPage_pricing_question4_answer
    },
    {
      title: translations.landingPage_pricing_question5,
      answer: translations.landingPage_pricing_question5_answer
    },
    {
      title: translations.landingPage_pricing_question6,
      answer: translations.landingPage_pricing_question6_answer
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <div className="landingPage-faq-wrapper">
      <div className='section-faq-title'>
        <h2>{translations.landingPage_pricing_faq_mainTitle}</h2>
      </div>

      <div className='section-faq-accordion'>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`accordion-item ${activeIndex === index ? 'open' : ''}`}
          >
            <div className='accordion-header' onClick={() => handleToggle(index)}>
              <div className='accordion-title'>
                {faq.title}
              </div>
              <div className='accordion-title-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                  <path d={activeIndex === index
                    ? "M17.9998 15C17.9998 15 13.5809 9.00001 11.9998 9C10.4187 8.99999 5.99985 15 5.99985 15"
                    : "M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"}
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className='accordion-content'>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SECTIONFAQ;
