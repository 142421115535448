import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/404.css';

const HOOKERSHARE = () => {
    const { language } = useContext(LanguageContext);
    const { getSessionInfo, isAuthenticated } = useAuth();
    const { localStoredUsername } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='common-public-wrapper'>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                </script> {/* -------- MICROSOFT CLARITY -------- */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                </script> {/* -------- GOOGLE ANALYTICS -------- */}
            </Helmet>
            <div className='page-404-wrapper'>
                <div className='page-404-404'>
                    <h1>404</h1>
                    <h2>{translations.page404_notFound}</h2>
                </div>
                <div className='page-404-return'>
                    <p>{translations.page404_weGonnaHelp}</p>
                    <p>{translations.page404_clickBelow}</p>
                </div>
                <div className='page-404-buttons'>
                    {isAuthenticated ? (
                        <a href={`/${language}/account/${localStoredUsername}/mural`}>
                            <button className='page-404-buttons-main'>{translations.page404_backToMural}</button>
                        </a>
                    ) : (
                        <a href={`/${language}/public/mural`}>
                            <button className='page-404-buttons-main'>{translations.page404_backToMural}</button>
                        </a>
                    )}
                    <a href={`/${language}/`}>
                        <button className='page-404-buttons-secondary'>{translations.page404_backHomepage}</button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HOOKERSHARE;
