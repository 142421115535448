import React, { useRef } from 'react';
import '../../../style/auth/register.css'

function Step3({
    email,
    loading,
    setLoading,
    MainContext,
    setRegistrationStep,
    setOTPCode,
    translations
}) {

    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Handle single character input
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        // Handle paste event for multiple characters
        if (value.length > 1) {
            const characters = value.split('');
            characters.forEach((char, i) => {
                if (inputRefs.current[i]) {
                    inputRefs.current[i].value = char; // Populate input fields
                }
            });

            // Move focus to the last filled input
            if (characters.length < 6) {
                inputRefs.current[characters.length].focus();
            }
        }
    };

    const handlePaste = (e, index) => {
        e.preventDefault(); // Prevent default paste behavior
        const pastedData = e.clipboardData.getData('text'); // Get pasted text
        if (pastedData.length <= 6) {
            pastedData.split('').forEach((char, i) => {
                if (inputRefs.current[i]) {
                    inputRefs.current[i].value = char; // Populate input fields
                }
            });

            // Move focus to the next available input
            const nextIndex = Math.min(pastedData.length, 5); // Don't go past the last input
            inputRefs.current[nextIndex].focus();
        }
    };

    const getFullToken = () => {
        return inputRefs.current.reduce((acc, input) => acc + (input ? input.value : ''), '');
    };

    const validateSecurityCode = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/validate-token`;
        const data = new URLSearchParams({
            email: email,
            securityToken: getFullToken()
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setOTPCode(getFullToken());
                    setRegistrationStep(3);
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                setLoading(false);
            });

    };

    return (
        <div className='register-form-activate-account-wrapper'>
            <div className='register-form-activate-title'>
                <p>{translations.forgotPassword_step3_message1}</p>
                <span>{translations.forgotPassword_step3_message2}</span>
                <span>{translations.forgotPassword_step3_message3}</span>
            </div>

            <div className='register-form-activate-code'>
                {[...Array(6)].map((_, index) => (
                    <input
                        key={index}
                        type='text'
                        maxLength='1'
                        onChange={(e) => handleChange(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        className='code-input'
                        ref={(el) => (inputRefs.current[index] = el)}
                    />
                ))}
            </div>
            <div className='register-form-options-components-button'>
                <button disabled={loading} onClick={validateSecurityCode}>
                    {loading ? (
                        <span className="loading-dots">
                            {translations.forgotPassword_holdOn}<span>.</span><span>.</span><span>.</span>
                        </span>
                    ) : (
                        translations.forgotPassword_step3_confirmIdentity
                    )}
                </button>
            </div>
        </div>
    );
}

export default Step3;